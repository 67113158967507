import React, { useState, useEffect, useCallback } from 'react';
import { Container, CssBaseline, Box } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import FullLadder from '../../components/SharedComponents/Ladder/FullLadder';
import AdjustableLadder from '../../components/SharedComponents/Ladder/AdjustableLadder';
import TrajectoryLadder from '../../components/SharedComponents/Ladder/TrajectoryLadder';
import LadderFilterBar from '../../components/SharedComponents/Ladder/LadderFilterBar';
import Header from '../../components/SharedComponents/Header/Header';
import BottomDetailsBar from '../../components/SharedComponents/Ladder/BottomDetailsBar';
import { useLadderData } from '../../hooks/useLadderData';
import allSeasons from '../../constants/allSeasons.json';
import ErrorMessage from '../../components/SharedComponents/ErrorMessage/ErrorMessage';
import LadderPageLoadingSkeleton from '../../components/MatchPageSharedComponents/LadderPageLoadingSkeleton';
import './LadderPage.css';
import Footer from '../../components/SharedComponents/Footer/Footer';

const seasons = allSeasons.yearsList;

function LadderPage() {
    const location = useLocation();
    const navigate = useNavigate();
    const [season, setSeason] = useState(() => {
        const searchParams = new URLSearchParams(location.search);
        const seasonFromUrl = searchParams.get('season');
        return seasonFromUrl && seasons.includes(Number(seasonFromUrl)) ? Number(seasonFromUrl) : seasons[0];
    });
    const [round, setRound] = useState(() => {
        const searchParams = new URLSearchParams(location.search);
        const roundFromUrl = searchParams.get('round');
        return roundFromUrl ? Number(roundFromUrl) : null;
    });
    const [viewMode, setViewMode] = useState('Standard');
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 1050);

    const { data: ladderData, isLoading, isError } = useLadderData(season);

    const handleSeasonChange = useCallback((newSeason) => {
        setSeason(newSeason);
    }, []);

    const handleRoundChange = useCallback((newRound) => {
        setRound(newRound);
    }, []);

    useEffect(() => {
        if (ladderData) {
            const availableRounds = Object.keys(ladderData).map(Number);
            if (round === null || !availableRounds.includes(round)) {
                setRound(Math.max(...availableRounds));
            }
        }
    }, [ladderData, round]);

    useEffect(() => {
        const newSearchParams = new URLSearchParams(location.search);
        newSearchParams.set('season', season);
        if (round !== null) {
            newSearchParams.set('round', round);
        }
        navigate(`?${newSearchParams.toString()}`, { replace: true });
    }, [season, round, navigate, location.search]);

    useEffect(() => {
        const checkScreenSize = () => {
            const smallScreen = window.innerWidth <= 1050;
            setIsSmallScreen(smallScreen);

            // Switch to Standard view mode if screen size is small
            if (smallScreen && viewMode === 'Trajectory') {
                setViewMode('Standard');
            }
        };

        checkScreenSize();
        window.addEventListener('resize', checkScreenSize);

        return () => window.removeEventListener('resize', checkScreenSize);
    }, [viewMode]);

    const renderLadder = () => {
        if (!ladderData || !ladderData[round]) return null;

        if (viewMode === 'Trajectory') {
            const trajectoryData = Object.keys(ladderData)
                .map(Number)
                .sort((a, b) => a - b)
                .reduce((acc, roundNumber) => {
                    ladderData[roundNumber].forEach(team => {
                        if (!acc[team.team]) {
                            acc[team.team] = { team: team.team, trajectory: [] };
                        }
                        acc[team.team].trajectory[roundNumber - 1] = team.position;
                    });
                    return acc;
                }, {});

            return (
                <TrajectoryLadder 
                    ladderData={Object.values(trajectoryData)} 
                    currentRound={round}
                />
            );
        }

        return isSmallScreen ? (
            <AdjustableLadder ladderData={ladderData[round]} />
        ) : (
            <FullLadder ladderData={ladderData[round]} />
        );
    };

    return (
        <div className="ladder-page-root">
            <div className="scrollable-content">
                <Container component="main" className='main-container'>
                    <CssBaseline />
                    <Header />
                    {isLoading ? (
                        <LadderPageLoadingSkeleton />
                    ) : isError ? (
                        <ErrorMessage />
                    ) : (
                        <Box className='ladder-page-content-container main-content-box'>
                            <Box className='full-ladder-box'>
                                <LadderFilterBar 
                                    currentYear={season} 
                                    currentRound={round} 
                                    seasonSetter={handleSeasonChange} 
                                    roundSetter={handleRoundChange}
                                    seasons={seasons}
                                    rounds={ladderData ? Object.keys(ladderData).map(Number) : []}
                                    viewMode={viewMode}
                                    setViewMode={setViewMode}
                                    isSmallScreen={isSmallScreen}
                                />
                                {renderLadder()}
                                {viewMode !== 'Trajectory' && <BottomDetailsBar />}
                            </Box>
                        </Box>
                    )}
                </Container>
                <Footer></Footer>
            </div>
        </div>
    );
}

export default LadderPage;