import React, { useState, useEffect } from "react";
import { useLocation, Link } from "react-router-dom";
import {
  AppBar,
  Box,
  Typography,
  InputBase,
  IconButton,
  Toolbar,
  Tabs,
  Tab,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import SettingsIcon from "@mui/icons-material/Settings";
import InfoIcon from '@mui/icons-material/Info';
import SportsFootballIcon from '@mui/icons-material/SportsFootball';
import ChatIcon from '@mui/icons-material/Chat';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import GroupsIcon from '@mui/icons-material/Groups';
import tryLineLogo from './../../../assets/tryLineLogo.svg';

import "./Header.css";

const Header = () => {
  const location = useLocation();
  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    const path = location.pathname;
    if (path === "/" || path.startsWith("/matches")) setActiveTab(0);
    else if (path.startsWith("/teams")) setActiveTab(1);
    else if (path.startsWith("/chat")) setActiveTab(2);
    else if (path.startsWith("/ladder")) setActiveTab(3);
    else if (path.startsWith("/about")) setActiveTab(4);
  }, [location]);

  return (
    <AppBar position="fixed" className="header-app-bar">
      <Toolbar className="header-toolbar">
        <div className="header-toolbar-content">
          <Typography variant="h4" component="div" className="header-title">
            <Link to="/" style={{ textDecoration: 'none', color: 'inherit' }}>
              <img 
                src={tryLineLogo} 
                alt="TRYLINE"  
                style={{ 
                  height: '30px',
                  width: 'auto',
                  marginTop: '5px',
                }}  
              />
            </Link>
          </Typography>
          <Box className="header-right-container">
            <Box className="header-search">
              <SearchIcon />
              <InputBase
                placeholder="Search"
                inputProps={{ "aria-label": "search" }}
                className="header-search-input"
              />
            </Box>
            <IconButton edge="end" className="header-settings">
              <SettingsIcon />
            </IconButton>
          </Box>
        </div>
      </Toolbar>
      <Box className="header-nav">
        <div className="header-nav-content">
          <Tabs
            value={activeTab}
            variant="scrollable"
            scrollButtons="false"
            textColor="inherit"
            indicatorColor="primary"
            className="header-tabs"
            TabIndicatorProps={{ style: { display: 'none' } }}
            style={{ height: '100%' }}
          >
            <Tab
              icon={<SportsFootballIcon className="header-tab-icon" />}
              label="Matches"
              iconPosition="start"
              component={Link}
              to="/"
              className="header-tab"
              id="header-tab-matches"
            />
            <Tab
              icon={<GroupsIcon className="header-tab-icon" />}
              label="Teams"
              iconPosition="start"
              component={Link}
              to="/teams"
              className="header-tab"
              id="header-tab-teams"
            />
            <Tab
              icon={<ChatIcon className="header-tab-icon" />}
              label="ChatBot"
              iconPosition="start"
              component={Link}
              to="/chat"
              className="header-tab"
              id="header-tab-chat"
            />
            <Tab
              icon={<LeaderboardIcon className="header-tab-icon" />}
              label="Ladder"
              iconPosition="start"
              component={Link}
              to="/ladder"
              className="header-tab"
              id="header-tab-ladder"
            />
            <Tab
              icon={<InfoIcon className="header-tab-icon" />}
              label="About"
              iconPosition="start"
              component={Link}
              to="/about"
              className="header-tab"
              id="header-tab-about"
            />
          </Tabs>
        </div>
      </Box>
    </AppBar>
  );
};

export default Header;
