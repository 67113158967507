import React, { useEffect } from "react"; // removed useState - Giveaway
import { Container, CssBaseline, Box } from "@mui/material";
import { useLocation } from 'react-router-dom';
import SmallLadder from "../../components/SharedComponents/Ladder/SmallLadder";
import Header from "../../components/SharedComponents/Header/Header";
import MatchesTable from "../../components/SharedComponents/MatchesTable/MatchesTable";
import EndorsedChannels from "../../components/LandingPageComponents/EndorsedChannels/EndorsedChannels";
import TrylineMedia from "../../components/LandingPageComponents/TrylineMedia/TrylineMedia";
import UpcomingMatchBanner from "../../components/LandingPageComponents/UpcomingMatchBanner/UpcomingMatchBanner";
import LandingPageMatchHighlights from "../../components/LandingPageComponents/LandingPageMatchHighlights/LandingPageMatchHighlights";
// import GiveawayModal from "../../components/LandingPageComponents/GiveawayModal/GiveawayModal";
import Footer from "../../components/SharedComponents/Footer/Footer";
import "./LandingPage.css";

function LandingPage() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const initialSeason = parseInt(searchParams.get('season')) || 2024;
  const initialRound = parseInt(searchParams.get('round')) || null;
  const initialTeam = searchParams.get('team') || '';

  // const [isGiveawayModalOpen, setIsGiveawayModalOpen] = useState(false);

  useEffect(() => {
    const lastVisit = localStorage.getItem('lastVisit');
    const currentTime = new Date().getTime();

    if (!lastVisit || (currentTime - parseInt(lastVisit)) > 24 * 60 * 60 * 1000) {
      // setIsGiveawayModalOpen(true);
      localStorage.setItem('lastVisit', currentTime.toString());
    }
  }, []);

  // const handleCloseGiveawayModal = () => {
  //   setIsGiveawayModalOpen(false);
  // };

  return (
    <div className="landing-page-root">
      <div className="scrollable-content">
      <Container component="main" className="main-container">
        <CssBaseline />
        <Header />
        <Box className="landing-page-container main-content-box">
          <Box className="landing-page-column1">
            <EndorsedChannels />
            <LandingPageMatchHighlights />
          </Box>
          <Box className="landing-page-column-wrapper">
            <Box className="landing-page-column2">
              <UpcomingMatchBanner />
              <MatchesTable 
                initialSeason={initialSeason} 
                initialRound={initialRound}
                initialTeam={initialTeam}
              />
            </Box>
            <Box className="landing-page-column3">
              <TrylineMedia/>
              <SmallLadder />
            </Box>
          </Box>
        </Box>
        {/* <GiveawayModal isOpen={isGiveawayModalOpen} handleClose={handleCloseGiveawayModal} /> */}
      </Container>
    <Footer />
      </div>
    </div>
  );
}

export default LandingPage;