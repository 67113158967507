import React, { useState } from 'react';
import { Box, Typography, Divider } from '@mui/material';
import PlayerModal from '../PlayerModal/PlayerModal';
import { usePostMatchDataContext } from '../../../hooks/PostMatchDataContext';
import { usePreMatchDataContext } from '../../../hooks/PreMatchDataContext';
import './ListViewLineup.css';

const getImagePath = (imageName) => {
  try {
    return require(`../../../assets/${imageName}.png`);
  } catch (error) {
    return require(`../../../assets/lineupPlayer.png`);
  }
};

const renderLineup = (team1Lineup = [], team2Lineup = [], handlePlayerClick, isPostMatch) => {
  return team1Lineup.map((player1, index) => {
    const player2 = team2Lineup[index];
    const player1Name = player1 ? `${player1.first_name} ${player1.last_name}` : '';
    const player2Name = player2 ? `${player2.first_name} ${player2.last_name}` : '';
    const isLastRow = index === team1Lineup.length - 1;

    return (
      <Box key={index}>
        <Box className="list-view-lineup-row">
          <Typography
            className={`list-view-lineup-player-name ${isPostMatch ? 'clickable' : ''}`}
            onClick={() => isPostMatch && handlePlayerClick(player1)}
          >
            {player1Name}
          </Typography>
          <img
            src={getImagePath(player1Name.replace(/\s/g, '').toLowerCase())}
            alt={player1Name}
            className={`list-view-lineup-player-image ${isPostMatch ? 'clickable' : ''}`}
            onClick={() => isPostMatch && handlePlayerClick(player1)}
          />
          <Box className="list-view-lineup-player-position-container">
            <Typography className="list-view-lineup-player-number">{player1.position_id}</Typography>
            <Typography className="list-view-lineup-player-position">{player1.position_name}</Typography>
          </Box>
          {player2 ? (
            <>
              <Box className="right-align-box">
                <img
                  src={getImagePath(player2Name.replace(/\s/g, '').toLowerCase())}
                  alt={player2Name}
                  className={`list-view-lineup-player-image ${isPostMatch ? 'clickable' : ''}`}
                  onClick={() => isPostMatch && handlePlayerClick(player2)}
                />
              </Box>
              <Typography
                className={`list-view-lineup-player-name right-align ${isPostMatch ? 'clickable' : ''}`}
                onClick={() => isPostMatch && handlePlayerClick(player2)}
              >
                {player2Name}
              </Typography>
            </>
          ) : (
            <Box className="list-view-lineup-player-placeholder" />
          )}
        </Box>
        {!isLastRow && <Divider className="list-view-lineup-divider" />}
      </Box>
    );
  });
};



const renderInjuries = (injuries = [], handlePlayerClick, isPostMatch, team1Id, team2Id) => {
  // Use team IDs from matchBasicInfo
  const team1Injuries = injuries.filter((player) => player.team_id === team1Id);
  const team2Injuries = injuries.filter((player) => player.team_id === team2Id);

  // Determine the maximum length to ensure all players are displayed
  const maxLength = Math.max(team1Injuries.length, team2Injuries.length);

  return Array.from({ length: maxLength }, (_, index) => {
    const player1 = team1Injuries[index]; // Player from team 1 or undefined
    const player2 = team2Injuries[index]; // Player from team 2 or undefined
    const player1Name = player1 ? `${player1.first_name} ${player1.last_name}` : '';
    const player2Name = player2 ? `${player2.first_name} ${player2.last_name}` : '';
    const injuryType1 = player1 ? player1.injury_type : '';
    const injuryType2 = player2 ? player2.injury_type : '';

    return (
      <Box key={index} className="lineup-new-injuries-row">
        {/* Team 1 Player Name and Injury */}
        <Box className="lineup-new-injuries-player-info">
          {player1 && (
            <>
              <Typography
                className={`lineup-new-injuries-player-name ${isPostMatch ? 'clickable' : ''}`}
                onClick={() => isPostMatch && handlePlayerClick(player1)}
              >
                {player1Name}
              </Typography>
              <Typography className="injuries-right-align lineup-new-injuries-type">{injuryType1}</Typography>
            </>
          )}
        </Box>

        {/* Team 1 Player Photo */}
        <Box className="lineup-new-injuries-photo-container">
          {player1 && (
            <img
              src={getImagePath(player1Name.replace(/\s/g, '').toLowerCase())}
              alt={player1Name}
              className={`lineup-new-injuries-player-image ${isPostMatch ? 'clickable' : ''}`}
              onClick={() => isPostMatch && handlePlayerClick(player1)}
            />
          )}
        </Box>

        {/* Team 2 Player Photo */}
        <Box className="lineup-new-injuries-photo-container">
          {player2 && (
            <img
              src={getImagePath(player2Name.replace(/\s/g, '').toLowerCase())}
              alt={player2Name}
              className={`lineup-new-injuries-player-image ${isPostMatch ? 'clickable' : ''}`}
              onClick={() => isPostMatch && handlePlayerClick(player2)}
            />
          )}
        </Box>

        {/* Team 2 Player Name and Injury */}
        <Box className="lineup-new-injuries-player-info">
          {player2 && (
            <>
              <Typography
                className={`injuries-left-align lineup-new-injuries-player-name  ${isPostMatch ? 'clickable' : ''}`}
                onClick={() => isPostMatch && handlePlayerClick(player2)}
              >
                {player2Name}
              </Typography>
              <Typography className="injuries-left-align lineup-new-injuries-type">{injuryType2}</Typography>
            </>
          )}
        </Box>
      </Box>
    );
  });
};


const ListViewLineup = ({ includeMainSections = true, isPostMatch }) => {
  const [selectedPlayer, setSelectedPlayer] = useState(null);

  const handlePlayerClick = (player) => {
    if (isPostMatch) {
      setSelectedPlayer(player);
    }
  };

  const handleClose = () => {
    setSelectedPlayer(null);
  };

  const postMatchData = usePostMatchDataContext();
  const preMatchData = usePreMatchDataContext();
  
  const data = isPostMatch ? postMatchData : preMatchData;

  if (!data || !data.matchLineup) {
    return <div>Loading...</div>; 
  }

  const { team_1: team1Lineup, team_2: team2Lineup } = data.matchLineup;

  const { matchBasicInfo } = data;
  const team1Id = matchBasicInfo.team1.id;
  const team2Id = matchBasicInfo.team2.id;

  const injuries = [
    ...(team1Lineup.injuriesAndSuspensions || []),
    ...(team2Lineup.injuriesAndSuspensions || [])
  ];

  const sectionsToShow = includeMainSections
    ? ['backs', 'forwards', 'interchange', 'reserves']
    : ['interchange', 'reserves'];

  return (
    <Box className="list-view-lineup-container">
      {sectionsToShow.map((section) => (
        <Box key={section} className="list-view-lineup-section">
          <Typography className="section-title">
            {section.charAt(0).toUpperCase() + section.slice(1)}
          </Typography>
          <Box className="list-view-lineup-rows">
            {renderLineup(team1Lineup[section], team2Lineup[section], handlePlayerClick, isPostMatch)}
          </Box>
        </Box>
      ))}
      {injuries.length > 0 && (
        <Box className="list-view-lineup-injuries-section">
          <Typography variant="h6" className="section-title">
            Injuries and Suspensions <span role="img" aria-label="sad face">🏥</span>
          </Typography>
          <Box className="list-view-lineup-rows">
          {renderInjuries(injuries, handlePlayerClick, isPostMatch, team1Id, team2Id)}
          </Box>
        </Box>
      )}
      {isPostMatch && selectedPlayer && (
        <PlayerModal open={!!selectedPlayer} handleClose={handleClose} player={selectedPlayer} />
      )}
    </Box>
  );
};

export default ListViewLineup;
