import React from "react";
import { Box, Typography } from "@mui/material";
import { Link } from "react-router-dom"; // Import Link from react-router-dom
import "./TeamStatsCard.css"; // Import the CSS file for styling
import { useTeamColors } from '../../../hooks/TeamColorsContext'; // Import the useTeamColors hook

const getImagePath = (imageName) => {
  try {
    return require(`./../../../assets/team_logos/${imageName.toLowerCase().replace(/\s/g, '-')}.svg`);
  } catch (error) {
    return require(`./../../../assets/lineupPlayer.png`);
  }
};

// Function to format numbers according to the category
const formatValue = (value, category) => {
  if (typeof value === "string") {
    value = parseFloat(value); // Convert to number if it is a string
  }
  
  if (category.toLowerCase().endsWith("percentage")) {
    // Round percentages to the nearest integer
    return `${Math.round(value)}%`;
  } else if (category.includes("Completion Rate")) {
    // Round percentages to the nearest integer
    return `${Math.round(value)}%`;
  } else if (category.toLowerCase().startsWith("points")) {
    // Round point values to the nearest integer
    return Math.round(value);
  } else if (category.includes("Avg")) {
    // Round average values to 2 decimal places
    return value.toFixed(2);
  }
  
  return value; // Return the value as is for other categories
};

const TeamStatsCard = ({ category, teamName, teamValue, maxTeam, maxTeamValue, lowTeam, lowTeamValue }) => {
  const { teamColours } = useTeamColors(); // Fetch team colors from context

  // Find the team color
  const teamColor = teamColours.find(color => color.team_name_short === teamName)?.colour || '#4a90e2'; // Default color if not found

  // Determine the order based on values
  let entries = [];

  // Add the best team first
  if (teamName === maxTeam) {
    entries.push({ name: teamName, value: formatValue(teamValue, category), imagePath: getImagePath(teamName), highlight: true, color: teamColor, rank: "(1st)" });
  } else {
    entries.push({ name: maxTeam, value: formatValue(maxTeamValue, category), imagePath: getImagePath(maxTeam), rank: "(1st)" });
  }

  // Add the middle team (either your team or low team if your team is not max or low)
  if (teamName !== maxTeam && teamName !== lowTeam) {
    entries.push({ name: teamName, value: formatValue(teamValue, category), imagePath: getImagePath(teamName), highlight: true, color: teamColor });
  }

  // Add the lowest team last
  if (teamName === lowTeam) {
    entries.push({ name: teamName, value: formatValue(teamValue, category), imagePath: getImagePath(teamName), highlight: true, color: teamColor, rank: "(17th)" });
  } else {
    entries.push({ name: lowTeam, value: formatValue(lowTeamValue, category), imagePath: getImagePath(lowTeam), rank: "(17th)" });
  }

  // Handle case when your team is both the max and min
  if (teamName === maxTeam && teamName === lowTeam) {
    entries = [
      { name: teamName, value: formatValue(teamValue, category), imagePath: getImagePath(teamName), highlight: true, color: teamColor, rank: "(1st)" },
      { name: teamName, value: formatValue(teamValue, category), imagePath: getImagePath(teamName), highlight: false, color: teamColor },
      { name: teamName, value: formatValue(teamValue, category), imagePath: getImagePath(teamName), highlight: true, color: teamColor, rank: "(17th)" }
    ];
  }

  return (
    <Box className="team-stats-card">
      <Typography className="team-stats-category" variant="h6">
        {category}
      </Typography>
      {entries.map((entry, index) => (
        <Link to={`/teams/${entry.name.toLowerCase().replace(/\s/g, '-')}`} key={index} className="team-stats-entry-link"> {/* Make the entry clickable */}
          <Box className="team-stats-entry">
            <img src={entry.imagePath} alt={entry.name} className="team-stats-flag" />
            <Typography className="team-stats-name">
              {entry.name} {entry.rank && <span className="team-stats-rank">{entry.rank}</span>}
            </Typography>
            <Typography
              className={`team-stats-value ${entry.highlight ? 'team-stats-highlight' : ''}`}
              style={entry.highlight ? { backgroundColor: entry.color } : {}}
            >
              {entry.value}
            </Typography>
          </Box>
        </Link>
      ))}
    </Box>
  );
};

export default TeamStatsCard;
