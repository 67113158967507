import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box
} from '@mui/material';
import "./BottomDetailsBar.css";
import FinalsImage from './../../../assets/FinalsInfographic.png';

const BottomDetailsBar = () => {
  const [openModal, setOpenModal] = useState(false); // State to manage modal open/close

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <div className='ladder-bottom-details-container'>
      <Button className='ladder-bottom-details-top-8'>
        Finals
      </Button>
      <Button className='ladder-bottom-details-finals' onClick={handleOpenModal}>
        Finals Infographic
      </Button>
      <Dialog
        open={openModal}
        onClose={handleCloseModal}
        maxWidth="md" // Set the maximum width of the dialog to large
        fullWidth // Makes the dialog take up the full width
      >
        <DialogTitle>Finals Infographic</DialogTitle>
        <DialogContent>
          <Box>
            <img src={FinalsImage} alt="Finals Infographic" style={{ width: '100%', height: '100%' }} />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} style={{ color: 'black' }}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default BottomDetailsBar;
