const playerPositions = {
  1: { x: 95, y: 49, label: 'FB' },   // Fullback
  2: { x: 90, y: 13, label: 'RW' },  // Right Winger
  3: { x: 90, y: 31, label: 'RC' },  // Right Centre
  4: { x: 90, y: 66, label: 'LC' },  // Left Centre
  5: { x: 90, y: 83, label: 'LW' },  // Left Winger
  6: { x: 82, y: 60, label: 'FE' },  // Five-Eighth
  7: { x: 82, y: 39, label: 'HF' },  // Halfback
  8: { x: 58, y: 77, label: 'LP' },  // Left Prop
  9: { x: 58, y: 49, label: 'HK' },  // Hooker
  10: { x: 58, y: 21, label: 'RP' }, // Right Prop
  11: { x: 65, y: 60, label: '2R' }, // Left 2nd Row
  12: { x: 65, y: 39, label: '2R' }, // Right 2nd Row
  13: { x: 74, y: 49, label: 'LK' }, // Lock
  // 15: { x: 32, y: 49, label: 'IC' }, 
  // 17: { x: 20, y: 56, label: 'RV' }, 
  // 18: { x: 40, y: 21, label: 'XX' }, 
};

export default playerPositions;
