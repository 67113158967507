import React from "react";
import { Box, Skeleton } from "@mui/material";
// import "./UpcomingMatchBanner.css";

const UpcomingMatchBannerSkeleton = () => {
  const waveColor = 'rgba(255, 255, 255, 0.1)';
  const backgroundGradient = `linear-gradient(90deg, transparent, ${waveColor}, transparent)`;
  const animationDuration = '1.5s';

  const skeletonProps = {
    animation: "wave",
    sx: {
      bgcolor: 'rgba(255, 255, 255, 0.1)',
      '&::after': {
        animationDuration: animationDuration,
        background: backgroundGradient,
      }
    }
  };

  return (
    // <Box className="upcoming-banner-container" sx={{ background: 'linear-gradient(to right, #4F83E6, #8E6BCE, #C52F2F)' }}>
      <Box className="upcoming-banner-container" sx={{ background: 'linear-gradient(to right, #86C34D, #57B58F, #2FA7C5)' }}>
      <Skeleton variant="text" width="60%" height={24} {...skeletonProps} />
      <Skeleton variant="text" width="80%" height={20} {...skeletonProps} />
      <Box className="upcoming-banner-teams-wrapper">
        <Box className="upcoming-banner-teams-info">
          <Box className="upcoming-banner-team">
            <Skeleton variant="text" width={100} height={24} {...skeletonProps} />
            <Skeleton variant="circular" width={60} height={60} {...skeletonProps} />
          </Box>
          <Skeleton variant="text" width={60} height={24} {...skeletonProps} />
          <Box className="upcoming-banner-team">
            <Skeleton variant="circular" width={60} height={60} {...skeletonProps} />
            <Skeleton variant="text" width={100} height={24} {...skeletonProps} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default UpcomingMatchBannerSkeleton;