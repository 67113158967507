import React from 'react';
import { Box, Typography } from '@mui/material';
import './../PreMatchBettingAnalytics/PreMatchBettingAnalytics.css';

const PreMatchBettingAnalysisText = ({ matchBasicInfo }) => {
  const { bettingAnalysis } = matchBasicInfo;

  if (!bettingAnalysis) {
    return (
      <Box className="prematch-betting-analysis">
        <Typography className="prematch-betting-analysis-title">
            Understanding Our Data
        </Typography>
        <Typography className="prematch-betting-analysis-text">
        You can click the information button to gain more information on some sections.
        We noticed that splitting between ladder position categories allows for a more valid analysis of statistics.
        </Typography>
      </Box>
    );
  }

  const dotpoints = bettingAnalysis.split('Dotpoint:').filter(Boolean).map(point => point.trim());

  return (
    <Box className="prematch-betting-analysis">
      <Typography className="prematch-betting-analysis-title">
        Our Data
      </Typography>
      {dotpoints.length > 0 ? (
        <ul>
          {dotpoints.map((point, index) => (
            <li key={index} className="prematch-betting-analysis-text">
              {point}
            </li>
          ))}
        </ul>
      ) : (
        <Typography className="prematch-betting-analysis-text">
          Betting analysis not currently available for this match.
        </Typography>
      )}
    </Box>
  );
};

export default PreMatchBettingAnalysisText;