import React from 'react';
import { Box, Typography, IconButton, Paper, Divider, useMediaQuery } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import TvIcon from '@mui/icons-material/Tv';
import LinkIcon from '@mui/icons-material/Link';
import PersonIcon from '@mui/icons-material/Person'; // Import PersonIcon for referee
import { useNavigate } from 'react-router-dom';
import { usePreMatchDataContext } from '../../../hooks/PreMatchDataContext';
import './../../MatchPageSharedComponents/MatchHeader.css';
import './PreMatchHeader.css'

const getImagePath = (teamName) => {
  try {
    return require(`./../../../assets/team_logos/${teamName.toLowerCase().replace(/\s/g, '-')}.svg`);
  } catch (error) {
    return require(`./../../../assets/team_logos/bye.png`);
  }
};

const formatRank = (position) => {
  if (position === 1) return '1st';
  if (position === 2) return '2nd';
  if (position === 3) return '3rd';
  return `${position}th`;
};

const formatDate = (dateString) => {
  const options = { weekday: 'long', day: 'numeric', month: 'long', timeZone: 'UTC' };
  const date = new Date(dateString);
  return date.toLocaleDateString('en-GB', options);
};

const formatTime = (datetime) => {
  const matchDate = new Date(datetime);
  return matchDate.toLocaleTimeString('en-GB', {
    hour: 'numeric',
    minute: '2-digit',
    hour12: true,
    timeZone: 'UTC',
  });
};

const PreMatchHeader = () => {
  const data = usePreMatchDataContext();
  const isSmallScreen = useMediaQuery('(max-width: 850px)');
  const navigate = useNavigate();

  if (!data || !data.matchBasicInfo) return null;

  const { matchBasicInfo } = data;
  const { team1, team2, datetime, season, round, venue, channel9, officialMatchPageUrl, referee } = matchBasicInfo;

  const handleBackClick = () => {
    navigate('/');
  };

  const handleTeamClick = (teamName) => {
    const teamLink = `/teams/${teamName.toLowerCase().replace(/\s/g, '-')}`;
    navigate(teamLink);
  };

  const formattedTime = formatTime(datetime);
  const formattedDate = formatDate(datetime);

  return (
    <Box className="match-header-container">
      <Box className="match-header-top">
        <Box className="match-header-back-section" onClick={handleBackClick}>
          <IconButton className="back-button-box match-header-back-button-box">
            <ArrowBackIosIcon className="back-button-icon" />
          </IconButton>
          <Typography className="match-header-back-text">Matches</Typography>
        </Box>
        <Typography variant="h6" className="match-header-round-info">Round {round}, {season}</Typography>
      </Box>
      <Divider className="match-header-divider" />
      <Box className="match-header-middle">
        <Box className="match-header-details">
          <Box 
            className="match-header-team-details"
            onClick={() => handleTeamClick(team1.name)} // Make the entire Box clickable
            style={{ cursor: 'pointer' }} // Change cursor to pointer to indicate clickable
          >
            {isSmallScreen ? (
              <Box className="match-header-team-column">
                <img src={getImagePath(team1.name)} alt={team1.name} className="match-header-team-logo" />
                <Typography className="match-header-team-name">{team1.name}</Typography>
                <Typography className="match-header-team-rank">{formatRank(team1.position)}</Typography>
              </Box>
            ) : (
              <>
                <Box className="match-header-team">
                  <Typography variant="h6" className="match-header-team-name flex-end">{team1.name}</Typography>
                  <Typography variant="body1" className="match-header-team-rank flex-end left-rank">{formatRank(team1.position)}</Typography>
                </Box>
                <img src={getImagePath(team1.name)} alt={team1.name} className="match-header-team-logo flex-start" id="match-header-team-logo1" />
              </>
            )}
          </Box>
          <Box className="match-header-match-details">
            <Typography className="match-header-match-time-or-score">
              {formattedTime}
            </Typography>
            <Typography variant="body1" className="match-header-ht-score-and-date">
              {formattedDate}
            </Typography>
          </Box>
          <Box 
            className="match-header-team-details"
            onClick={() => handleTeamClick(team2.name)} // Make the entire Box clickable
            style={{ cursor: 'pointer' }} // Change cursor to pointer to indicate clickable
          >
            {isSmallScreen ? (
              <Box className="match-header-team-column">
                <img src={getImagePath(team2.name)} alt={team2.name} className="match-header-team-logo" />
                <Typography variant="h6" className="match-header-team-name">{team2.name}</Typography>
                <Typography variant="body1" className="match-header-team-rank">{formatRank(team2.position)}</Typography>
              </Box>
            ) : (
              <>
                <img src={getImagePath(team2.name)} alt={team2.name} className="match-header-team-logo flex-start" id="match-header-team-logo2" />
                <Box className="match-header-team">
                  <Typography variant="h6" className="match-header-team-name flex-start">{team2.name}</Typography>
                  <Typography variant="body1" className="match-header-team-rank flex-start right-rank">{formatRank(team2.position)}</Typography>
                </Box>
              </>
            )}
          </Box>
        </Box>
      </Box>
      <Divider className="match-header-divider" />
      <Box className="match-header-bottom">
        <Box className="match-header-match-info">
          <Paper className="match-header-tag">
            <LocationOnIcon className="match-header-tag-icon" /> <Typography>{venue}</Typography>
          </Paper>
          {channel9 && (
            <Paper className="match-header-tag">
              <TvIcon className="match-header-tag-icon" /> <Typography>Channel 9</Typography>
            </Paper>
          )}
          {referee && referee !== 'NaN' && (
            <Paper className="match-header-tag">
              <PersonIcon className="match-header-tag-icon" /> <Typography>{referee}</Typography>
            </Paper>
          )}
          <Paper className="match-header-official-match-page">
            <LinkIcon className="match-header-tag-icon" />
            <Typography>
              <a href={officialMatchPageUrl} target="_blank" rel="noopener noreferrer" className="official-match-page-link">
                Official Match Page
              </a>
            </Typography>
          </Paper>
        </Box>
      </Box>
    </Box>
  );
};

export default PreMatchHeader;
