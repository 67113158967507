import React, { useState } from "react";
import { Box, Typography, Avatar } from "@mui/material";
import { Link } from "react-router-dom"; // Import Link from react-router-dom
import { useTeamDataContext } from "../../../hooks/TeamDataContext";
import "./ConversionsGraph.css";

const getImagePath = (imageName) => {
  try {
    return require(`../../../assets/${imageName}.png`);
  } catch (error) {
    return require(`../../../assets/lineupPlayer.png`);
  }
};

const ConversionsGraph = () => {
  const [selectedColumn, setSelectedColumn] = useState("leftSide");
  const data = useTeamDataContext();

  if (!data || !data.teamTriesAndConversions) return null;

  const { conversionEvents } = data.teamTriesAndConversions;

  const columns = [
    { key: "leftSide", label: "Left" },
    { key: "midLeftSide", label: "MidLeft" },
    { key: "middle", label: "Middle" },
    { key: "midRightSide", label: "MidRight" },
    { key: "rightSide", label: "Right" },
  ];

  const handleColumnClick = (key) => {
    setSelectedColumn(key);
  };

  return (
    <Box className="temp-row1">
      <Box className="temp-col2">
        <Box className="conversions-graph-container">
          <Box className="conversions-graph">
            {columns.map(({ key, label }) => (
              <Box
                key={key}
                className={`conversions-graph-column ${
                  selectedColumn === key ? "selected" : ""
                }`}
                onClick={() => handleColumnClick(key)}
              >
                <Typography className="conversions-graph-label">
                  {label}
                </Typography>
                <Box className="conversions-graph-bar-container">
                  <Box
                    className="conversions-graph-bar"
                    style={{
                      height: `${conversionEvents[key].percentageTotal}%`,
                    }}
                  >
                    <Typography className="conversions-graph-percentage">
                      {Math.round(conversionEvents[key].percentageTotal)}%
                    </Typography>
                  </Box>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
      <Box className="temp-col2">
        <Typography className="kickers-title">Kickers Here</Typography>
        <Box className="kickers-list">
          {conversionEvents[selectedColumn].kickers.map((kicker) => (
            <Link
              key={kicker.playerid}
              to="/players" // Link to the /players page
              className="kicker-item-link" // Add a class to style the link
            >
              <Box className="kicker-item">
                <Avatar
                  src={getImagePath(
                    `${kicker.first_name}${kicker.last_name}`.toLowerCase()
                  )}
                  alt={`${kicker.first_name} ${kicker.last_name}`}
                  className="kicker-avatar"
                />
                <Typography className="kicker-name">
                  {kicker.first_name} {kicker.last_name}
                </Typography>
                <Typography className="kicker-percentage">
                  {Math.round(kicker.percentageScoredHere)}% (
                  {kicker.kickedTrue}/{kicker.kickedAttempt})
                </Typography>
              </Box>
            </Link>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default ConversionsGraph;
