import React, { useMemo } from "react";
import { Box, Typography, Card, CardContent } from "@mui/material";
import { Link } from "react-router-dom";
import { useTeamDataContext } from "../../../hooks/TeamDataContext";
import MatchesTableLoadingSkeleton from "../../MatchPageSharedComponents/MatchesTableLoadingSkeleton";
// import FixturesDropdown from "../FixturesDropdown/FixturesDropdown";
import "./TeamFixtures.css";

const getImagePath = (teamName) => {
  try {
    return require(`./../../../assets/team_logos/${teamName.toLowerCase().replace(/\s/g, "-")}.svg`);
  } catch (error) {
    return require(`./../../../assets/team_logos/bye.png`);
  }
};

const TeamFixtures = () => {
  const data = useTeamDataContext();

  const teamFixtures = useMemo(() => data?.teamFixtures || {}, [data]);
  const teamBasicInfo = data?.teamBasicInfo || {};
  const selectedTeam = teamBasicInfo.team_name_short || "";

  const allMatches = useMemo(() => {
    return Object.values(teamFixtures).flat();
  }, [teamFixtures]);

  const upcomingMatches = useMemo(() => {
    const currentDate = new Date();
    return allMatches
      .filter((match) => new Date(match.datetime) > currentDate && match.channel9 !== null)
      .slice(0, 3);
  }, [allMatches]);

  const groupedMatches = (matches) => {
    return matches.reduce((acc, match) => {
      const key = new Date(match.datetime).toISOString().split("T")[0];
      if (!acc[key]) acc[key] = [];
      acc[key].push(match);
      return acc;
    }, {});
  };

  const formatDate = (dateString) => {
    const options = { weekday: "long", day: "numeric", month: "long", year: "numeric" };
    const date = new Date(dateString);
    return date.toLocaleDateString("en-GB", options);
  };

  const formatTime = (datetime) => {
    const matchDate = new Date(datetime);
    return matchDate.toLocaleTimeString("en-GB", {
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
      timeZone: "UTC",
    });
  };

  if (!data || !data.teamFixtures) return <MatchesTableLoadingSkeleton />;

  return (
    <div className="team-fixtures-matches-table">
      {/* Display Upcoming Matches */}
      <Box className="team-fixtures-row">
        <Typography className="h1-text-team-fixtures">Upcoming Matches</Typography>
      </Box>
      {upcomingMatches.length === 0 ? (
        <Typography className="team-fixtures-no-upcoming-text">No upcoming matches</Typography>
      ) : (
        Object.entries(groupedMatches(upcomingMatches)).map(([key, matches]) => (
          <Card className="team-fixtures-matches-in-day-card" key={key}>
            <Typography variant="h6" className="team-fixtures-match-date team-fixtures-matches-table-header-text">
              {formatDate(key)}
            </Typography>
            {matches.map((match) => {
              const route = `/pre?matchid=${match.match_id}`;

              return (
                <Link key={match.match_id} to={route} className="team-fixtures-match-wrapper">
                  <CardContent className="team-fixtures-match-content">
                    <Box className="team-fixtures-team-info team-fixtures-align-right">
                      <Typography variant="h6" className="team-fixtures-team-name">{match.team_1.name}</Typography>
                      <img src={getImagePath(match.team_1.name)} alt={match.team_1.name} className="team-fixtures-team-logo" id="team-fixtures-match-table-team1-logo" />
                    </Box>
                    <Typography variant="h6" className="team-fixtures-match-score upcoming">{formatTime(match.datetime)}</Typography>
                    <Box className="team-fixtures-team-info team-fixtures-align-left">
                      <img src={getImagePath(match.team_2.name)} alt={match.team_2.name} className="team-fixtures-team-logo" id="team-fixtures-match-table-team2-logo" />
                      <Typography variant="h6" className="team-fixtures-team-name">{match.team_2.name}</Typography>
                    </Box>
                  </CardContent>
                  <Box className="team-fixtures-matches-table-location-box">
                    <Typography className="team-fixtures-matches-table-match-location">{match.location}</Typography>
                  </Box>
                </Link>
              );
            })}
          </Card>
        ))
      )}

      {/* Display All Matches */}
      <Box className="team-fixtures-row all-matches-box">
        <Typography className="h1-text-team-fixtures">All Matches</Typography>
        {/* <FixturesDropdown /> */}
      </Box>
      {Object.entries(groupedMatches(allMatches)).map(([key, matches]) => (
        <Card className="team-fixtures-all-matches-card team-fixtures-matches-in-day-card" key={key}>
          <Typography variant="h6" className="team-fixtures-match-date team-fixtures-matches-table-header-text">
            {formatDate(key)}
          </Typography>
          {matches.map((match) => {
            const isUpcoming = new Date(match.datetime) > new Date();
            const route = isUpcoming ? `/pre?matchid=${match.match_id}` : `/post?matchid=${match.match_id}`;

            const teamScore = match.team_1.name === selectedTeam ? match.team_1.score : match.team_2.score;
            const opponentScore = match.team_1.name !== selectedTeam ? match.team_1.score : match.team_2.score;
            const isWin = teamScore > opponentScore;
            const scoreClass = isUpcoming ? "upcoming" : isWin ? "win" : "loss"; // Assign "upcoming" class for upcoming matches

            return (
              <Link key={match.match_id} to={route} className="team-fixtures-match-wrapper">
                <CardContent className="team-fixtures-match-content">
                  <Box className="team-fixtures-team-info team-fixtures-align-right">
                    <Typography variant="h6" className="team-fixtures-team-name">{match.team_1.name}</Typography>
                    <img src={getImagePath(match.team_1.name)} alt={match.team_1.name} className="team-fixtures-team-logo" id="team-fixtures-match-table-team1-logo" />
                  </Box>
                  {isUpcoming ? (
                    <Typography variant="h6" className={`team-fixtures-match-score ${scoreClass}`}>{formatTime(match.datetime)}</Typography>
                  ) : (
                    <Typography variant="h6" className={`team-fixtures-match-score ${scoreClass}`}>
                      {match.team_1.score} - {match.team_2.score}
                    </Typography>
                  )}
                  <Box className="team-fixtures-team-info team-fixtures-align-left">
                    <img src={getImagePath(match.team_2.name)} alt={match.team_2.name} className="team-fixtures-team-logo" id="team-fixtures-match-table-team2-logo" />
                    <Typography variant="h6" className="team-fixtures-team-name">{match.team_2.name}</Typography>
                  </Box>
                </CardContent>
                <Box className="team-fixtures-matches-table-location-box">
                  <Typography className="team-fixtures-matches-table-match-location">{match.location}</Typography>
                </Box>
              </Link>
            );
          })}
        </Card>
      ))}
    </div>
  );
};

export default TeamFixtures;
