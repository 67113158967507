// hooks/useSeasonMatchData.js
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import config from '../config';

const fetchSeasonMatchData = async (season) => {
  const response = await axios.get(`${config.backendUrl}/frontpage_match_year_data?season=${season}`);
  return response.data;
};

export const useSeasonMatchData = (season) => {
  return useQuery({
    queryKey: ['seasonMatchData', season],
    queryFn: () => fetchSeasonMatchData(season),
    staleTime: 15 * 60 * 1000, // 5 minutes
  });
};