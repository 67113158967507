import React from 'react';
import { Container, CssBaseline, Box, Typography } from '@mui/material';
import Header from '../../components/SharedComponents/Header/Header';
import Footer from '../../components/SharedComponents/Footer/Footer';
import "../AboutUsPage/AboutUsPage.css";

function TermsOfService() {
  return (
    <div className="about-page-root">
      <div className="scrollable-content">
    <div className="outer-container">
      <Container component="main" className="main-container">
        <CssBaseline />
        <Header />
        <Box className="about-us-page-container main-content-box">
          <Typography className="aboutus-header-text" component="h1" gutterBottom>
            Terms of Service
          </Typography>
          <Typography variant="body1" className="aboutus-text" paragraph>
            Tryline is a platform developed by footy enthusiasts to provide free, accessible rugby league information and data analysis to fans. By using Tryline, you agree to the following terms:
          </Typography>
          
          <Typography className="aboutus-section-header" gutterBottom>
            Purpose and Community Engagement
          </Typography>
          <Typography variant="body1" className="aboutus-text" paragraph>
            Tryline aims to foster community engagement and grow the game of rugby league by providing easy-to-understand team and match information. Users are encouraged to participate in discussions and share insights responsibly.
          </Typography>

          <Typography className="aboutus-section-header" gutterBottom>
            Data Usage
          </Typography>
          <Typography variant="body1" className="aboutus-text" paragraph>
            Information on Tryline is sourced from publicly available data and our own analysis. While we strive for accuracy, users access and use this information at their own risk.
          </Typography>

          <Typography className="aboutus-section-header" gutterBottom>
            Private and Entertainment Use
          </Typography>
          <Typography variant="body1" className="aboutus-text" paragraph>
            Tryline's content is intended for private, non-commercial use and entertainment purposes only. Users should not rely on this information for professional advice or decision-making.
          </Typography>

          <Typography className="aboutus-section-header" gutterBottom>
            Gambling Disclaimer
          </Typography>
          <Typography variant="body1" className="aboutus-text" paragraph>
            Tryline strongly discourages using our data or analysis for gambling purposes. Users acknowledge that gambling carries inherent risks, and Tryline accepts no responsibility for any losses incurred.
          </Typography>

          <Typography className="aboutus-section-header" gutterBottom>
            User Conduct
          </Typography>
          <Typography variant="body1" className="aboutus-text" paragraph>
            Users agree to engage respectfully with the community, refraining from posting offensive, unlawful, or discriminatory content. Tryline reserves the right to moderate or remove inappropriate content.
          </Typography>

          <Typography className="aboutus-section-header" gutterBottom>
            Data Usage and Scraping
          </Typography>
          <Typography variant="body1" className="aboutus-text" paragraph>
            Tryline uses a range of publicly available data and adds value such as analysis and visualization. Use of the data, content, or any information displayed on Tryline for any purpose, including but not limited to scraping, reproduction, redistribution, or commercial purposes, without the express written consent of Tryline is strictly prohibited.
          </Typography>

          <Typography className="aboutus-section-header" gutterBottom>
            Third-Party Links
          </Typography>
          <Typography variant="body1" className="aboutus-text" paragraph>
            Tryline only contains external links to the official NRL Website. In the future, Tryline may contain external links to reputable news sources. We are not responsible for the content or practices of these sites.
          </Typography>

          <Typography className="aboutus-section-header" gutterBottom>
            Disclaimer of Warranties
          </Typography>
          <Typography variant="body1" className="aboutus-text" paragraph>
            Tryline provides its services "as is" without any warranties, express or implied. We do not guarantee the accuracy, completeness, or reliability of our data.
          </Typography>

          <Typography className="aboutus-section-header" gutterBottom>
            Limitation of Liability
          </Typography>
          <Typography variant="body1" className="aboutus-text" paragraph>
            Tryline and its team are not liable for any damages or losses resulting from the use of our platform or reliance on our information.
          </Typography>

          <Typography className="aboutus-section-header" gutterBottom>
            Changes to Terms
          </Typography>
          <Typography variant="body1" className="aboutus-text" paragraph>
            Tryline reserves the right to modify these terms at any time. Continued use of the platform constitutes acceptance of any changes.
          </Typography>

          <Typography className="aboutus-section-header" gutterBottom>
            Arbitration
          </Typography>
          <Typography variant="body1" className="aboutus-text" paragraph>
            If you encounter any issues or disagreements with Tryline, we encourage you to reach out to us directly. We are committed to addressing and resolving disputes in good faith through informal discussions.
          </Typography>

          <Typography variant="body1" className="aboutus-text" paragraph>
            By using our services, you agree to resolve any dispute with us on an individual basis through arbitration. This means that any dispute will be resolved through arbitration, and not as part of a class arbitration, class action, or any other type of consolidated or representative proceeding.
          </Typography>

          <Typography variant="body1" className="tos-text aboutus-text" paragraph>
            By using Tryline, you acknowledge that you have read, understood, and agree to be bound by these Terms of Service.
          </Typography>
        </Box>
      </Container>
    </div>
      <Footer/>
      </div>
      </div>
  );
}

export default TermsOfService;
