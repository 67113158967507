import React, { useState, useMemo, useRef, useEffect } from 'react';
import { LineChart, Line, XAxis, YAxis, Tooltip, ResponsiveContainer, ReferenceLine } from 'recharts';
import { Box, Typography, useMediaQuery, useTheme, Paper } from '@mui/material';
import './TrajectoryLadder.css';

const teamColors = {
  Storm: '#62058a',
  Panthers: '#383838',
  Roosters: '#132951',
  Sharks: '#0d9ac1',
  Bulldogs: '#1f5ac7',
  Cowboys: '#090b51',
  'Sea Eagles': '#76174d',
  Dragons: '#f13123',
  Broncos: '#3e0315',
  Dolphins: '#980a0a',
  Knights: '#110d95',
  Raiders: '#26d337',
  Titans: '#10b6e3',
  Warriors: '#141414',
  Rabbitohs: '#169850',
  'Wests Tigers': '#fb7005',
  Eels: '#ebc321'
};

// Custom tooltip component
const CustomTooltip = ({ active, payload, label, selectedTeams }) => {
    if (active && payload && payload.length) {
      // Check if all the rankings are the same
      const allSame = payload.every(entry => entry.value === payload[0].value);
  
      if (allSame) {
        return null; // Return null if all rankings are the same
      }
  
      const sortedPayload = [...payload].sort((a, b) => a.value - b.value);
  
      return (
        <Paper elevation={3} sx={{ padding: 2, backgroundColor: '#f5f5f5', maxWidth: 300 }}>
          <Typography variant="subtitle1" gutterBottom>
            Round {label}
          </Typography>
          {sortedPayload.map((entry, index) => (
            <Box key={`item-${index}`} sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
              <Typography
                variant="body2"
                sx={{
                  color: teamColors[entry.name],
                  fontWeight: selectedTeams.includes(entry.name) ? 'bold' : 'normal',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  marginRight: '12px'
                }}
              >
                {entry.name}
              </Typography>
              <Typography variant="body2">{entry.value}</Typography>
            </Box>
          ))}
        </Paper>
      );
    }
  
    return null; // Return null if tooltip is not active or payload is empty
  };

  const TrajectoryLadder = ({ ladderData, currentRound }) => {
    const [selectedTeams, setSelectedTeams] = useState([]);
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
    const chartRef = useRef(null);
  
    const trajectoryData = useMemo(() => {
      return ladderData
        .map(team => ({
          name: team.team,
          data: team.trajectory.map((position, index) => ({
            round: index + 1,
            position: position
          }))
        }))
        .sort((a, b) => a.name.localeCompare(b.name));
    }, [ladderData]);
  
    const latestRound = useMemo(() => {
      return Math.max(...trajectoryData[0].data.map(d => d.round));
    }, [trajectoryData]);
  
    const latestPositions = useMemo(() => {
      return trajectoryData.map(team => ({
        name: team.name,
        latestPosition: team.data[team.data.length - 1].position
      }));
    }, [trajectoryData]);
  
    const sortedLegendData = useMemo(() => {
      return latestPositions.sort((a, b) => a.latestPosition - b.latestPosition);
    }, [latestPositions]);
  
    const handleLegendClick = (teamName) => {
      setSelectedTeams((prevSelected) => 
        prevSelected.includes(teamName)
          ? prevSelected.filter(name => name !== teamName)
          : [...prevSelected, teamName]
      );
    };
  
    useEffect(() => {
      if (chartRef.current) {
        const chart = chartRef.current;
        chart.scrollLeft = chart.scrollWidth;
      }
    }, []);
  
    if (isSmallScreen) {
      return (
        <Box>
          <Typography variant="h6">Trajectory view not available on small screens</Typography>
        </Box>
      );
    }
  
    return (
      <Box className="trajectory-ladder-container">
        <ResponsiveContainer>
          <LineChart
            margin={{ top: 20, right: 120, left: 20, bottom: 20 }}
            ref={chartRef}
          >
            <XAxis
              dataKey="round"
              type="number"
              domain={[1, latestRound]}
              ticks={trajectoryData[0].data.map(d => d.round)}
              allowDataOverflow={false}
              interval={0}
              label={{ value: 'Round', position: 'bottom', offset: 0 }}
            />
            <YAxis
              reversed
              domain={[1, 17]}
              ticks={[...Array(17).keys()].map(i => i + 1)}
              interval={0}
              label={{ value: 'Position', angle: -90, position: 'insideLeft' }}
            />
            <Tooltip 
              content={<CustomTooltip selectedTeams={selectedTeams} />} 
              filterNull={true} 
              isAnimationActive={false} 
            />
            <ReferenceLine x={latestRound} stroke="#888" strokeDasharray="3 3" />
            {trajectoryData.map((team) => (
              <Line
                key={team.name}
                type="linear"
                dataKey="position"
                data={team.data}
                name={team.name}
                stroke={teamColors[team.name]}
                strokeWidth={selectedTeams.includes(team.name) ? 3 : 1}
                dot={false}
                opacity={selectedTeams.includes(team.name) ? 1 : 0.2}
                style={{ filter: selectedTeams.includes(team.name) ? 'none' : 'grayscale(100%)' }}
              />
            ))}
          </LineChart>
        </ResponsiveContainer>
        <Box className="trajectory-ladder-legend">
          {sortedLegendData.map((team) => (
            <Box
              key={team.name}
              className="trajectory-ladder-legend-item"
              sx={{
                opacity: selectedTeams.includes(team.name) ? 1 : 0.2,
              }}
              onClick={() => handleLegendClick(team.name)}
            >
              <Box
                className="trajectory-ladder-legend-dot"
                sx={{ backgroundColor: teamColors[team.name] }}
              />
              <Typography variant="caption" noWrap>{team.name}</Typography>
            </Box>
          ))}
        </Box>
      </Box>
    );
  };
  
  export default TrajectoryLadder;