import React, { useEffect, useState, useCallback } from 'react';
import { Box, Typography, Paper, Card, CardContent } from '@mui/material';
import { usePreMatchDataContext } from '../../../hooks/PreMatchDataContext';
import './PreMatchHighlights.css';

import whitePlayTriangle from './../../../assets/white-play-triangle-transparent-bg.svg';

import broncosImage from './../../../assets/team_logos/broncos.svg';
import bulldogsImage from './../../../assets/team_logos/bulldogs.svg';
import cowboysImage from './../../../assets/team_logos/cowboys.svg';
import dolphinsImage from './../../../assets/team_logos/dolphins.svg';
import dragonsImage from './../../../assets/team_logos/dragons.svg';
import eelsImage from './../../../assets/team_logos/eels.svg';
import knightsImage from './../../../assets/team_logos/knights.svg';
import panthersImage from './../../../assets/team_logos/panthers.svg';
import rabbitohsImage from './../../../assets/team_logos/rabbitohs.svg';
import raidersImage from './../../../assets/team_logos/raiders.svg';
import roostersImage from './../../../assets/team_logos/roosters.svg';
import seaEaglesImage from './../../../assets/team_logos/sea-eagles.svg';
import sharksImage from './../../../assets/team_logos/sharks.svg';
import stormImage from './../../../assets/team_logos/storm.svg';
import titansImage from './../../../assets/team_logos/titans.svg';
import warriorsImage from './../../../assets/team_logos/warriors.svg';
import westsTigersImage from './../../../assets/team_logos/wests-tigers.svg';

const teamData = {
  Roosters: { 
    colorStart: '#132951',
    colorEnd: '#1d3c7a',
    logo: roostersImage 
  },
  'Sea Eagles': {
    colorStart: '#76174d',
    colorEnd: '#942262',
    logo: seaEaglesImage
  },
  Rabbitohs: {
    colorStart: '#169850',
    colorEnd: '#1eb55f',
    logo: rabbitohsImage
  },
  Panthers: { 
    colorStart: '#383838',
    colorEnd: '#515151',
    logo: panthersImage
  },
  Cowboys: { 
    colorStart: '#090b51',
    colorEnd: '#0e1270',
    logo: cowboysImage
  },
  Storm: { 
    colorStart: '#62058a',
    colorEnd: '#7b0aad',
    logo: stormImage
  },
  Bulldogs: { 
    colorStart: '#1f5ac7',
    colorEnd: '#2a6ae2',
    logo: bulldogsImage
  },
  Broncos: { 
    colorStart: '#3e0315',
    colorEnd: '#590420',
    logo: broncosImage 
  },
  Warriors: { 
    colorStart: '#141414',
    colorEnd: '#2b2b2b',
    logo: warriorsImage 
  },
  Eels: { 
    colorStart: '#ebc321',
    colorEnd: '#f2d035',
    logo: eelsImage 
  },
  Dragons: { 
    colorStart: '#f13123', 
    colorEnd: '#f94b3d',
    logo: dragonsImage
  },
  Knights: { 
    colorStart: '#110d95', 
    colorEnd: '#1612b3',
    logo: knightsImage
  },
  'Wests Tigers': { 
    colorStart: '#fb7005', 
    colorEnd: '#ff8c21',
    logo: westsTigersImage
  },
  Titans: { 
    colorStart: '#10b6e3', 
    colorEnd: '#25c8eb',
    logo: titansImage
  },
  Raiders: { 
    colorStart: '#26d337', 
    colorEnd: '#3be14b',
    logo: raidersImage
  },
  Sharks: { 
    colorStart: '#0d9ac1', 
    colorEnd: '#11b2d9',
    logo: sharksImage
  },
  Dolphins: { 
    colorStart: '#980a0a', 
    colorEnd: '#b91111',
    logo: dolphinsImage
  },
};

const PreMatchHighlights = () => {
  const data = usePreMatchDataContext();
  const { matchBasicInfo } = data;
  const [imageDataUrl, setImageDataUrl] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const loadImage = useCallback((src) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => resolve(img);
      img.onerror = reject;
      img.src = src;
    });
  }, []);

  const createGradientImage = useCallback(async (team1, team2) => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
  
    if (!teamData[team1] || !teamData[team2]) {
      console.error('Team data not found for one or both teams:', team1, team2);
      return null;
    }
  
    canvas.width = 220;
    canvas.height = 200;
  
    const gradient1 = ctx.createLinearGradient(0, 0, canvas.width, 0);
    gradient1.addColorStop(0, teamData[team1].colorStart);
    gradient1.addColorStop(1, teamData[team2].colorEnd);

    const gradient2 = ctx.createLinearGradient(canvas.width, 0, 0, canvas.height);
    gradient2.addColorStop(0, teamData[team2].colorStart);
    gradient2.addColorStop(1, teamData[team1].colorEnd);

    ctx.beginPath();
    ctx.moveTo(0, 0);
    ctx.lineTo(canvas.width, 0);
    ctx.lineTo(0, canvas.height);
    ctx.closePath();
    ctx.fillStyle = gradient1;
    ctx.fill();

    ctx.beginPath();
    ctx.moveTo(canvas.width, 0);
    ctx.lineTo(canvas.width, canvas.height);
    ctx.lineTo(0, canvas.height);
    ctx.closePath();
    ctx.fillStyle = gradient2;
    ctx.fill();

    const blurGradient = ctx.createRadialGradient(
      canvas.width / 2, canvas.height / 2, 0,
      canvas.width / 2, canvas.height / 2, canvas.width * 0.7
    );
    blurGradient.addColorStop(0, 'rgba(0, 0, 0, 0)');
    blurGradient.addColorStop(0.5, 'rgba(0, 0, 0, 0.1)');
    blurGradient.addColorStop(0.8, 'rgba(0, 0, 0, 0.3)');
    blurGradient.addColorStop(1, 'rgba(0, 0, 0, 0.5)');

    ctx.fillStyle = blurGradient;
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    try {
      const [img1, img2] = await Promise.all([
        loadImage(teamData[team1].logo),
        loadImage(teamData[team2].logo)
      ]);

      ctx.drawImage(img1, 30, 40, 60, 60);
      ctx.drawImage(img2, 130, 110, 60, 60);
    } catch (error) {
      console.error('Error loading images:', error);
    }

    return canvas.toDataURL();
  }, [loadImage]);

  useEffect(() => {
    const generateImage = async () => {
      const highlightImage = await createGradientImage(matchBasicInfo.team1.name, matchBasicInfo.team2.name);
      setImageDataUrl(highlightImage);
      setIsLoading(false);
    };

    generateImage();
  }, [matchBasicInfo, createGradientImage]);

  if (isLoading) {
    return (
      <Box className="prematch-highlights-container">
        <Typography variant="h6" className="h1-text">Match Preview</Typography>
        <Typography>Loading preview...</Typography>
      </Box>
    );
  }
  
  const highlightData = {
    id: 1,
    title: matchBasicInfo.previewUrl
      ? `${matchBasicInfo.team1.name} vs ${matchBasicInfo.team2.name}`
      : 'Preview Currently Unavailable',
    round: `Round ${matchBasicInfo.round}`,
    year: matchBasicInfo.season,
    url: matchBasicInfo.previewUrl,
  };

  const isExternalLink = (url) => {
    return url && (url.startsWith('http://') || url.startsWith('https://'));
  };

  const hasValidUrl = highlightData.url && highlightData.url !== "null";
  const LinkComponent = hasValidUrl && isExternalLink(highlightData.url) ? 'a' : 'div';
  const linkProps = hasValidUrl && isExternalLink(highlightData.url)
    ? { href: highlightData.url, target: "_blank", rel: "noopener noreferrer" }
    : {};

    return (
      <Box className="prematch-highlights-container">
        <Typography variant="h6" className="h1-text">
          Match Preview
        </Typography>
        <LinkComponent
          {...linkProps}
          style={{ textDecoration: 'none' }}
        >
          <Card 
            className="prematch-highlights-card"
            style={{ cursor: hasValidUrl ? 'pointer' : 'default' }}
          >
            <CardContent className="prematch-highlights-content">
              <div className="prematch-highlights-image-container">
                {imageDataUrl && (
                  <img
                    src={imageDataUrl}
                    alt={highlightData.title}
                    className="prematch-highlights-image"
                  />
                )}
                {hasValidUrl && (
                  <img
                    src={whitePlayTriangle}
                    alt="Play"
                    className="centered-button"
                  />
                )}
              </div>
              <Box className="prematch-highlights-details">
                <Typography variant="body1" className="prematch-highlights-title">
                  {highlightData.title}
                </Typography>
                <Box className="prematch-highlights-tags">
                  <Paper className="prematch-highlights-tag">{highlightData.round}</Paper>
                  <Paper className="prematch-highlights-tag">{highlightData.year}</Paper>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </LinkComponent>
      </Box>
    );
};
export default PreMatchHighlights;
