import React from 'react';
import { Button, Box } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import './ShowMoreButton.css';

const ShowMoreButton = ({ showAll, onClick, className }) => {
  return (
    <Box className={`show-more-button-container ${className}`}>
      <Button 
        onClick={onClick} 
        variant="text" 
        className="show-more-button"
      >
        {showAll ? (
          <>
            Show Less <ExpandLessIcon className="arrow-icon" />
          </>
        ) : (
          <>
            Show All <ExpandMoreIcon className="arrow-icon" />
          </>
        )}
      </Button>
    </Box>
  );
};

export default ShowMoreButton;
