import React from "react";
import { Box, Divider, Typography } from "@mui/material";
import { usePreMatchDataContext } from "../../../hooks/PreMatchDataContext";
import PreMatchTryscorers from "./../PreMatchTryscorers/PreMatchTryscorers";
import HTFT from "../HTFT/HTFT";
import FirstTry from "../FirstTry/FirstTry";
import PreMatchBettingAnalysisText from "../PreMatchBettingAnalysisText/PreMatchBettingAnalysisText";
import InfoTooltip from "../../SharedComponents/InfoToolTip/InfoToolTip"; // Import the new InfoTooltip component

// import "./PreMatchBettingAnalytics.css";

const calculateWinPercentage = (wins, total) =>
  Math.round((wins / total) * 100);

const determineHighlight = (position) => {
  if (position <= 4) return "vsTop4";
  if (position >= 5 && position <= 8) return "vs5to8";
  return "vsBottom9";
};

const renderCategoryRow = (label, value, isHighlighted) => (
  <Box className={`prematch-stat-row row ${isHighlighted ? "highlighted-row" : ""}`}>
    <Typography>{label}</Typography>
    <Typography className="stat-bold">
      {value}
    </Typography>
  </Box>
);

const renderScoreFirst = (team, highlightedCategory, teamName) => (
  <Box className="prematch-betting-team-info">
    <Typography className="h2-text betting-analytics-subtitle">{`${teamName} scoring first...`}</Typography>
    <Box className="prematch-betting-tendencies column">
      {renderCategoryRow(
        "Vs Top 4",
        `${calculateWinPercentage(
          team.ScoreFirst.vsTop4.gamesScoredFirst,
          team.ScoreFirst.vsTop4.totalGames
        )}% (${team.ScoreFirst.vsTop4.gamesScoredFirst}/${
          team.ScoreFirst.vsTop4.totalGames
        })`,
        highlightedCategory === "vsTop4"
      )}
      {renderCategoryRow(
        "Vs 5 - 8",
        `${calculateWinPercentage(
          team.ScoreFirst.vs5to8.gamesScoredFirst,
          team.ScoreFirst.vs5to8.totalGames
        )}% (${team.ScoreFirst.vs5to8.gamesScoredFirst}/${
          team.ScoreFirst.vs5to8.totalGames
        })`,
        highlightedCategory === "vs5to8"
      )}
      {renderCategoryRow(
        "Vs Bottom 9",
        `${calculateWinPercentage(
          team.ScoreFirst.vsBottom9.gamesScoredFirst,
          team.ScoreFirst.vsBottom9.totalGames
        )}% (${team.ScoreFirst.vsBottom9.gamesScoredFirst}/${
          team.ScoreFirst.vsBottom9.totalGames
        })`,
        highlightedCategory === "vsBottom9"
      )}
    </Box>
  </Box>
);

const determineScoreFirstProbability = (team, highlightedCategory) => {
  const category = team.ScoreFirst[highlightedCategory];
  return calculateWinPercentage(category.gamesScoredFirst, category.totalGames);
};

const renderTeamInfo = ({
  teamName,
  seasonStats,
  last5GamesStats,
  vsTop8,
  vsBottom9,
  winMargins,
}) => (
  <Box className="prematch-betting-team-info">
    <Typography className="h2">{teamName}</Typography>
    <Box className="row">
      <Box className="column">
        <Typography className="header-stat prematch-altered-text">Season Win</Typography>
        <Typography className="prematch-analytic-main-stat-value">
          {calculateWinPercentage(
            seasonStats.gamesWon,
            seasonStats.gamesPlayed
          )}
          % ({seasonStats.gamesWon}/{seasonStats.gamesPlayed})
        </Typography>
      </Box>
      <Box className="column">
        <Typography className="header-stat prematch-altered-text">Last 5 Games</Typography>
        <Typography className="prematch-analytic-main-stat-value">
          {calculateWinPercentage(
            last5GamesStats.gamesWon,
            last5GamesStats.gamesPlayed
          )}
          % ({last5GamesStats.gamesWon}/{last5GamesStats.gamesPlayed})
        </Typography>
      </Box>
    </Box>
    <Box className="prematch-betting-tendencies column">
      {renderCategoryRow(
        "Win vs top 8",
        `${calculateWinPercentage(vsTop8.wins, vsTop8.wins + vsTop8.losses)}% (${vsTop8.wins}/${vsTop8.wins + vsTop8.losses})`,
        vsTop8.case
      )}
      {renderCategoryRow(
        "Win vs bottom 9",
        `${calculateWinPercentage(vsBottom9.wins, vsBottom9.wins + vsBottom9.losses)}% (${vsBottom9.wins}/${vsBottom9.wins + vsBottom9.losses})`,
        vsBottom9.case
      )}
      <Typography className="prematch-stat-row">
        <span className="prematch-altered-text">with a{" "}</span>
        <span className="bold-text">1-12</span>
        <span className="prematch-altered-text"> of{" "} </span>
        <Typography
          display="inline"
          className="prematch-analytic-sentence-value"
        >
          {Math.round(winMargins.wins1to12)}%
        </Typography>{" "}
        <span className="prematch-altered-text">and</span> <span className="bold-text">13+</span>
        <span className="prematch-altered-text"> of{" "} </span>
        <Typography
          display="inline"
          className="prematch-analytic-sentence-value"
        >
          {Math.round(winMargins.wins13plus)}%
        </Typography>
      </Typography>
    </Box>
  </Box>
);

const renderTeamTries = (team, highlightedCategory, teamName) => (
  <Box className="prematch-betting-team-info">
    <Typography className="h2-text betting-analytics-subtitle">{`${teamName} total tries...`}</Typography>
    <Box className="prematch-betting-tendencies column">
      {renderCategoryRow(
        "Vs Top 4",
        `${team.TeamTotalTries.vsTop4.average.toFixed(1)} (Min: ${
          team.TeamTotalTries.vsTop4.minimum
        } Max: ${team.TeamTotalTries.vsTop4.maximum})`,
        highlightedCategory === "vsTop4"
      )}
      {renderCategoryRow(
        "Vs 5 - 8",
        `${team.TeamTotalTries.vs5to8.average.toFixed(1)} (Min: ${
          team.TeamTotalTries.vs5to8.minimum
        } Max: ${team.TeamTotalTries.vs5to8.maximum})`,
        highlightedCategory === "vs5to8"
      )}
      {renderCategoryRow(
        "Vs Bottom 9",
        `${team.TeamTotalTries.vsBottom9.average.toFixed(1)} (Min: ${
          team.TeamTotalTries.vsBottom9.minimum
        } Max: ${team.TeamTotalTries.vsBottom9.maximum})`,
        highlightedCategory === "vsBottom9"
      )}
    </Box>
  </Box>
);

const calculateTotalTries = (team1, team2, team1Highlight, team2Highlight) => {
  const team1Tries = team1.TeamTotalTries[team1Highlight].average;
  const team2Tries = team2.TeamTotalTries[team2Highlight].average;
  return (team1Tries + team2Tries).toFixed(1);
};

const renderTotalPoints = (team, teamName, highlightedCategory) => (
  <Box className="prematch-betting-team-info">
    <Typography className="h2-text betting-analytics-subtitle">{`${teamName} points...`}</Typography>
    <Box className="prematch-betting-tendencies column">
      <Typography className="h2-text">First Half</Typography>
      {renderCategoryRow(
        "Vs Top 4",
        `${team.TotalPoints.firstHalf.vsTop4.average.toFixed(1)} (Min: ${
          team.TotalPoints.firstHalf.vsTop4.minimum
        } Max: ${team.TotalPoints.firstHalf.vsTop4.maximum})`,
        highlightedCategory === "vsTop4"
      )}
      {renderCategoryRow(
        "Vs 5 - 8",
        `${team.TotalPoints.firstHalf.vs5to8.average.toFixed(1)} (Min: ${
          team.TotalPoints.firstHalf.vs5to8.minimum
        } Max: ${team.TotalPoints.firstHalf.vs5to8.maximum})`,
        highlightedCategory === "vs5to8"
      )}
      {renderCategoryRow(
        "Vs Bottom 9",
        `${team.TotalPoints.firstHalf.vsBottom9.average.toFixed(1)} (Min: ${
          team.TotalPoints.firstHalf.vsBottom9.minimum
        } Max: ${team.TotalPoints.firstHalf.vsBottom9.maximum})`,
        highlightedCategory === "vsBottom9"
      )}
      <Box className="total-points-column">
        <Typography className="h2-text">Second Half</Typography>
        {renderCategoryRow(
          "Vs Top 4",
          `${team.TotalPoints.secondHalf.vsTop4.average.toFixed(1)} (Min: ${
            team.TotalPoints.secondHalf.vsTop4.minimum
          } Max: ${team.TotalPoints.secondHalf.vsTop4.maximum})`,
          highlightedCategory === "vsTop4"
        )}
        {renderCategoryRow(
          "Vs 5 - 8",
          `${team.TotalPoints.secondHalf.vs5to8.average.toFixed(1)} (Min: ${
            team.TotalPoints.secondHalf.vs5to8.minimum
          } Max: ${team.TotalPoints.secondHalf.vs5to8.maximum})`,
          highlightedCategory === "vs5to8"
        )}
        {renderCategoryRow(
          "Vs Bottom 9",
          `${team.TotalPoints.secondHalf.vsBottom9.average.toFixed(1)} (Min: ${
            team.TotalPoints.secondHalf.vsBottom9.minimum
          } Max: ${team.TotalPoints.secondHalf.vsBottom9.maximum})`,
          highlightedCategory === "vsBottom9"
        )}
      </Box>
    </Box>
  </Box>
);

const calculateTotalPoints = (team1, team2, team1Highlight, team2Highlight) => {
  const team1FirstHalf = team1.TotalPoints.firstHalf[team1Highlight].average;
  const team1SecondHalf = team1.TotalPoints.secondHalf[team1Highlight].average;
  const team2FirstHalf = team2.TotalPoints.firstHalf[team2Highlight].average;
  const team2SecondHalf = team2.TotalPoints.secondHalf[team2Highlight].average;
  return (
    team1FirstHalf +
    team1SecondHalf +
    team2FirstHalf +
    team2SecondHalf
  ).toFixed(1);
};

const PreMatchBettingAnalytics = () => {
  const { bettingBWLW, bettingTryscorers, bettingTeams, matchBasicInfo } =
    usePreMatchDataContext();

  if (!bettingBWLW || !bettingTryscorers || !bettingTeams || !matchBasicInfo)
    return null;

  const { team1, team2 } = bettingBWLW;
  const team1Highlight = determineHighlight(matchBasicInfo.team2.position);
  const team2Highlight = determineHighlight(matchBasicInfo.team1.position);
  const totalTries = calculateTotalTries(
    bettingTeams.team1,
    bettingTeams.team2,
    team1Highlight,
    team2Highlight
  );
  const totalPoints = calculateTotalPoints(
    bettingTeams.team1,
    bettingTeams.team2,
    team1Highlight,
    team2Highlight
  );

  const team1ScoreFirstProb = determineScoreFirstProbability(bettingTeams.team1, team1Highlight);
  const team2ScoreFirstProb = determineScoreFirstProbability(bettingTeams.team2, team2Highlight);

  const morelikelyToScoreFirst = team1ScoreFirstProb > team2ScoreFirstProb ? team1.teamName : team2.teamName;

  // New function to determine which team is more likely to win
  const determineMoreLikelyWinner = () => {
    const team1WinPercentage = team1Highlight === "vsBottom9" 
      ? calculateWinPercentage(team1.vsBottom9.wins, team1.vsBottom9.wins + team1.vsBottom9.losses)
      : team1Highlight === "vs5to8"
        ? calculateWinPercentage(team1.vsTop8.wins, team1.vsTop8.wins + team1.vsTop8.losses)
        : calculateWinPercentage(team1.seasonStats.gamesWon, team1.seasonStats.gamesPlayed);

    const team2WinPercentage = team2Highlight === "vsBottom9"
      ? calculateWinPercentage(team2.vsBottom9.wins, team2.vsBottom9.wins + team2.vsBottom9.losses)
      : team2Highlight === "vs5to8"
        ? calculateWinPercentage(team2.vsTop8.wins, team2.vsTop8.wins + team2.vsTop8.losses)
        : calculateWinPercentage(team2.seasonStats.gamesWon, team2.seasonStats.gamesPlayed);

    return team1WinPercentage > team2WinPercentage ? team1.teamName : team2.teamName;
  };

  const moreLikelyWinner = determineMoreLikelyWinner();
  return (
    <Box className="prematch-betting-container">
      <PreMatchBettingAnalysisText matchBasicInfo={matchBasicInfo} />
      <Box className="title-container">
      <Typography className="betting-analtics-title">Big Win Little Win</Typography>
      <InfoTooltip title="1-12 and 13+ analysis are conditional and apply to the highlighted category." />
      </Box>
      <Box className="prematch-betting-team">
        {renderTeamInfo(team1)}
        {renderTeamInfo(team2)}
      </Box>
      
      {/* New sentence about which team is more likely to win */}
      <Typography className="prematch-stat-row prematch-conclusion-text">
        <strong>{moreLikelyWinner}</strong> 
        <span className="prematch-altered-text"> is more likely to win.</span>
      </Typography>

      <Divider className="prematch-betting-divider" />

      <PreMatchTryscorers bettingTryscorers={bettingTryscorers} />

      <Divider className="prematch-betting-divider" />
      <Box className="title-container">
      <Typography className="betting-analtics-title">First Try</Typography>
      <InfoTooltip title="The Minute is the time of first try. Green tick indicates our team scored the try first. Red cross indicates the opponent scored the try first." />
      </Box>
      <FirstTry />

      <Divider className="prematch-betting-divider" />
      <Typography className="betting-analtics-title">Score First</Typography>
      <Box className="prematch-betting-team">
        {renderScoreFirst(bettingTeams.team1, team1Highlight, team1.teamName)}
        {renderScoreFirst(bettingTeams.team2, team2Highlight, team2.teamName)}
      </Box>
      <Typography className="prematch-stat-row prematch-conclusion-text">
        <strong>{morelikelyToScoreFirst}</strong> 
        <span className="prematch-altered-text"> are more likely to score first. </span>
      </Typography>

      <Divider className="prematch-betting-divider" />
      <Typography className="betting-analtics-title">Team Tries</Typography>
      <Box className="prematch-betting-team">
        {renderTeamTries(bettingTeams.team1, team1Highlight, team1.teamName)}
        {renderTeamTries(bettingTeams.team2, team2Highlight, team2.teamName)}
      </Box>
      <Typography className="prematch-conclusion-text">
        <span className="prematch-altered-text">Most likely tries scored is {" "} </span>
        <strong>{totalTries} tries</strong>.
      </Typography>

      <Divider className="prematch-betting-divider" />
      <HTFT />

      <Divider className="prematch-betting-divider" />
      <Typography className="betting-analtics-title">Total Points</Typography>
      <Box className="prematch-betting-team">
        {renderTotalPoints(bettingTeams.team1, team1.teamName, team1Highlight)}
        {renderTotalPoints(bettingTeams.team2, team2.teamName, team2Highlight)}
      </Box>
      <Typography className="prematch-conclusion-text">
        <span className="prematch-altered-text">Most likely total points this game is{" "} </span>
        <strong>{totalPoints} points</strong>.
      </Typography>
    </Box>
  );
};

export default PreMatchBettingAnalytics;
