import React from "react";
import { Box, Typography, Avatar } from "@mui/material";
import LinkIcon from '@mui/icons-material/Link';
import "./EndorsedChannels.css";

const getImagePath = (imageName) => {
  try {
    return require(`./../../../assets/${imageName}`);
  } catch (error) {
    return require(`./../../../assets/lineupPlayer.png`);
  }
};

const EndorsedChannels = () => {
  const channels = [
    {
      name: "Snag Sports",
      logo: getImagePath("snagSportsLogo.png"), // Dynamically get Snag Sports logo
      platformLink: "https://linktr.ee/Liamlowe", // YouTube link
      platformIcon: <LinkIcon className="endorsed-channels-link-icon" />,
      description: "NRL Content Creator",
    },
    {
      name: "League of Inches",
      logo: getImagePath("leagueOfInchesLogo.png"), // Dynamically get League of Inches logo
      platformLink: "https://linktr.ee/leagueofinches", // YouTube link
      platformIcon: <LinkIcon className="endorsed-channels-link-icon" />,
      description: "NRL Content Creator",
    },
  ];

  return (
    <Box className="endorsed-channels">
        <Typography className="h1-text-endorsed">Endorsed Channels</Typography>
      {channels.map((channel, index) => (
        <Box key={index} className="endorsed-channels-box">
          <Avatar src={channel.logo} alt={channel.name} className="endorsed-channels-avatar" />
          <Box>
            <Typography className="endorsed-channels-name">{channel.name}</Typography>
            <a
              href={channel.platformLink}
              target="_blank"
              rel="noopener noreferrer"
              className="endorsed-channels-link"
            >
              <Box className="endorsed-channels-info">
                {channel.platformIcon}
                <Typography variant="body2" className="endorsed-channels-description">
                  {channel.description}
                </Typography>
              </Box>
            </a>
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default EndorsedChannels;
