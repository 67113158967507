import React from 'react';
import { Box, Typography } from '@mui/material';
import { usePreMatchDataContext } from "../../../hooks/PreMatchDataContext";
import './WhoWillWin.css';

const getImagePath = (teamName) => {
  try {
    return require(`./../../../assets/team_logos/${teamName.toLowerCase().replace(/\s/g, '-')}.svg`);
  } catch (error) {
    return require(`./../../../assets/team_logos/bye.png`);
  }
};

const calculatePercentage = (votes, totalVotes) => {
  if (totalVotes === 0) return "N/A";
  return `${((votes / totalVotes) * 100).toFixed(2)}%`;
};

const WhoWillWin = () => {
  const data = usePreMatchDataContext();
  const { matchBasicInfo } = data;

  const team1Votes = matchBasicInfo.team1.votes || 0;
  const team2Votes = matchBasicInfo.team2.votes || 0;
  const tieVotes = matchBasicInfo.tieVotes || 0;

  const totalVotes = team1Votes + team2Votes + tieVotes;

  const team1Percentage = calculatePercentage(team1Votes, totalVotes);
  const team2Percentage = calculatePercentage(team2Votes, totalVotes);
  const drawPercentage = calculatePercentage(tieVotes, totalVotes);

  return (
    <Box className="who-will-win-container">
      <Typography variant="h6" className="who-will-win-title">Who will win?</Typography>
      <Box className="who-will-win-content">
        <Box className="who-will-win-team">
          <img src={getImagePath(matchBasicInfo.team1.name)} alt={matchBasicInfo.team1.name} className="who-will-win-flag" />
          <Typography variant="body2" className="who-will-win-percentage">{team1Percentage}</Typography>
        </Box>
        <Typography variant="body2" className="who-will-win-vs">X</Typography>
        <Box className="who-will-win-team">
          <img src={getImagePath(matchBasicInfo.team2.name)} alt={matchBasicInfo.team2.name} className="who-will-win-flag" />
          <Typography variant="body2" className="who-will-win-percentage">{team2Percentage}</Typography>
        </Box>
      </Box>
      <Typography variant="body2" className="who-will-win-draw">{drawPercentage}</Typography>
      <Typography variant="body2" className="who-will-win-total-votes">Total votes: {totalVotes}</Typography>
    </Box>
  );
};

export default WhoWillWin;
