import React from "react";
import { Box, Typography, Divider, Paper } from "@mui/material";
import "./PostMatchAllStats.css";
import PostMatchPlayerStats from "../PostMatchPlayerStats/PostMatchPlayerStats";
import TriesKicksLocations from "../TriesKicksLocations/TriesKicksLocations";
import { usePostMatchDataContext } from "../../../hooks/PostMatchDataContext";
import { useTeamColors } from "../../../hooks/TeamColorsContext";

const PostMatchAllStats = () => {
  const data = usePostMatchDataContext();
  const teamColors = useTeamColors().teamColours;

  if (!data || !data.matchStats || !data.scoringEvents) {
    return <div>Loading...</div>;
  }

  const { scoringEvents } = data;
  const { teamStats } = data.matchStats;
  const team1Stats = teamStats.team_1;
  const team2Stats = teamStats.team_2;

  const team1Id = team1Stats.team_id;
  const team2Id = team2Stats.team_id;

  const team1Color = teamColors.find(team => team.team_id === team1Id)?.colour || "#74C8D4";
  const team2Color = teamColors.find(team => team.team_id === team2Id)?.colour || "#F19858";

  const formatTimeInPossession = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  const renderStatRow = (team1Stat, statType, team2Stat, unit = "") => {
    const formatStat = (stat) => {
      if (statType === "Avg Play The Ball Speed") {
        return parseFloat(stat).toFixed(2) + "s";
      } else if (statType === "Avg Set Distance") {
        return parseFloat(stat).toFixed(2) + "m";
      } else if (statType === "Time In Possession") {
        return formatTimeInPossession(stat);
      }
      return stat;
    };

    let team1Class = "";
    let team2Class = "";

    if (statType === "Avg Play The Ball Speed") {
      team1Class = parseFloat(team1Stat) < parseFloat(team2Stat) ? "postmatch-matchplayerstats-highlight-team1" : "";
      team2Class = parseFloat(team2Stat) < parseFloat(team1Stat) ? "postmatch-matchplayerstats-highlight-team2" : "";
    } else {
      team1Class = parseFloat(team1Stat) > parseFloat(team2Stat) ? "postmatch-matchplayerstats-highlight-team1" : "";
      team2Class = parseFloat(team2Stat) > parseFloat(team1Stat) ? "postmatch-matchplayerstats-highlight-team2" : "";
    }

    return (
      <Box className="matchplayerstats-stat-row">
        <Paper className={`postmatch-matchplayerstats-stat ${team1Class}`} style={{backgroundColor: team1Class ? team1Color : 'inherit', color: team1Class ? 'white' : 'inherit'}}>
          {formatStat(team1Stat)}
          {unit}
        </Paper>
        <Typography className="postmatch-matchplayerstats-type">
          {statType}
        </Typography>
        <Paper className={`postmatch-matchplayerstats-stat ${team2Class}`} style={{backgroundColor: team2Class ? team2Color : 'inherit', color: team2Class ? 'white' : 'inherit'}}>
          {formatStat(team2Stat)}
          {unit}
        </Paper>
      </Box>
    );
  };

  const renderPossessionSection = (team1, team2) => {
    const team1Percentage = parseFloat(team1.percentage);
    const team2Percentage = parseFloat(team2.percentage);

    return (
      <Box className="postmatch-matchplayerstats-section">
        <Typography className="postmatch-matchplayerstats-title">
          Possession
        </Typography>
        <Box className="postmatch-matchplayerstats-possession-bar">
          <Box
            className="postmatch-matchplayerstats-possession-team1"
            style={{ width: `${team1Percentage}%`, backgroundColor: team1Color }}
          >
            {team1.percentage}%
          </Box>
          <Box
            className="postmatch-matchplayerstats-possession-team2"
            style={{ width: `${team2Percentage}%`, backgroundColor: team2Color }}
          >
            {team2.percentage}%
          </Box>
        </Box>
        {renderStatRow(
          team1.time_in_possession,
          "Time In Possession",
          team2.time_in_possession
        )}
        {renderStatRow(
          Math.round(team1.completion_rate_percentage),
          "Completion Rate",
          Math.round(team2.completion_rate_percentage),
          "%"
        )}
        <Divider className="postmatch-matchplayerstats-section-divider" />
      </Box>
    );
  };

  const renderEffectiveTackleSection = (team1, team2) => {
    const team1Percentage = Math.floor(parseFloat(team1.effective_tackle));
    const team2Percentage = Math.floor(parseFloat(team2.effective_tackle));

    return (
      <Box className="postmatch-matchplayerstats-section">
        <Typography className="postmatch-matchplayerstats-title">
          Defence
        </Typography>
        <Typography className="postmatch-matchplayerstats-stat-title">
          Effective Tackle
        </Typography>
        <Box className="postmatch-matchplayerstats-effective-tackle-bar">
          <Box
            className="postmatch-matchplayerstats-effective-tackle-team1"
            style={{ width: `${team1Percentage}%`, backgroundColor: team1Color }}
          >
            {team1Percentage}%
          </Box>
          <Box
            className="postmatch-matchplayerstats-effective-tackle-team2"
            style={{ width: `${team2Percentage}%`, backgroundColor: team2Color }}
          >
            {team2Percentage}%
          </Box>
        </Box>
        {renderStatRow(team1.all_run_metres, "All Run Metres", team2.all_run_metres)}
        {renderStatRow(
          team1.post_contact_metres,
          "Post Contact Metres",
          team2.post_contact_metres
        )}
        {renderStatRow(team1.line_breaks, "Line Breaks", team2.line_breaks)}
        <Divider className="postmatch-matchplayerstats-section-divider" />
      </Box>
    );
  };

  // Helper function to convert snake_case to Title Case
  const formatStatKey = (key) => {
    if (key === "interchanges_hia") {
      return "Interchanges HIA";
    }
    return key
      .split('_')
      .map(word => {
        if (word === "hia") return "HIA";
        return word.charAt(0).toUpperCase() + word.slice(1);
      })
      .join(' ');
  };

  const renderStatSection = (title, stats, team1, team2) => {
    return (
      <Box className="postmatch-matchplayerstats-section">
        <Typography className="postmatch-matchplayerstats-title">
          {title}
        </Typography>
        {Object.keys(stats).map((statKey, index) => (
          <React.Fragment key={index}>
            {renderStatRow(
              team1[statKey],
              formatStatKey(statKey),
              team2[statKey],
              statKey === "completion_rate_percentage" ? "%" : ""
            )}
          </React.Fragment>
        ))}
        <Divider className="postmatch-matchplayerstats-section-divider" />
      </Box>
    );
  };

  return (
    <Box className="postmatch-matchplayerstats-container column">
      <TriesKicksLocations 
        scoringEvents={scoringEvents}
        team1Id={team1Id} 
        team2Id={team2Id} 
        team1Color={team1Color}
        team2Color={team2Color}
      />
      <Divider flexItem className="postmatch-matchplayerstats-vertical-divider" />
      <Box className="matchplayerstats-row">
        <Box className="postmatch-matchplayerstats-column">
          {renderPossessionSection(team1Stats.possession, team2Stats.possession)}
          {renderStatSection("Attack", team1Stats.attack, team1Stats.attack, team2Stats.attack)}
          {renderStatSection("Passing", team1Stats.passing, team1Stats.passing, team2Stats.passing)}
          {renderStatSection("Interchanges", team1Stats.interchanges, team1Stats.interchanges, team2Stats.interchanges)}
        </Box>
        <Divider
          orientation="vertical"
          flexItem
          className="postmatch-matchplayerstats-vertical-divider"
        />
        <Box className="postmatch-matchplayerstats-column">
          {renderStatSection("Discipline", team1Stats.discipline, team1Stats.discipline, team2Stats.discipline)}
          {renderEffectiveTackleSection(team1Stats.defence, team2Stats.defence)}
          {renderStatSection("Kicking", team1Stats.kicking, team1Stats.kicking, team2Stats.kicking)}
        </Box>
      </Box>
      <Box className="matchplayerstats-row">
        <PostMatchPlayerStats team1Id={team1Id} team2Id={team2Id} team1Color={team1Color} team2Color={team2Color} />
      </Box>
    </Box>
  );
};

export default PostMatchAllStats;
