import React from 'react';
import { Box } from '@mui/material';
import './LineupToggle.css';

const LineupToggle = ({ options, value, onChange }) => {
  return (
    <Box className="lineup-toggle">
      <Box className="lineup-toggle-options">
        {options.map((option) => (
          <Box
            key={option}
            className={`lineup-toggle-option ${value === option ? 'selected' : ''}`}
            onClick={() => onChange(option)}
          >
            {option}
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default LineupToggle;
