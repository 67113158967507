// hooks/useUpcomingMatchData.js
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import config from '../config';

const fetchUpcomingMatchData = async () => {
  const response = await axios.get(`${config.backendUrl}/frontpage_match_upcoming_live`);
  return response.data;
};

export const useUpcomingMatchData = () => {
  return useQuery({
    queryKey: ['upcomingMatchData'],
    queryFn: fetchUpcomingMatchData,
    staleTime: 15 * 60 * 1000, // 5 minutes
  });
};