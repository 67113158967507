import React, { useState, useEffect, useMemo } from "react";
import { Box, Typography, IconButton } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import scoringMap from "./../../../assets/scoringMap.svg";
import PlayerModal from "../PlayerModal/PlayerModal";
import CustomTabs from "../../SharedComponents/CustomTabs/CustomTabs";
import "./TriesKicksLocations.css";

const TriesKicksLocations = ({
  scoringEvents,
  team1Id,
  team2Id,
  team1Color,
  team2Color,
}) => {
  const [activeTab, setActiveTab] = useState(0);
  const [selectedTryIndex, setSelectedTryIndex] = useState(0);
  const [selectedKickIndex, setSelectedKickIndex] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);

  const tabs = ["All", "1st Half", "2nd Half"];

  const filteredEvents = useMemo(() => {
    return scoringEvents.filter((event) => {
      if (activeTab === 0) return true; // All
      if (activeTab === 1) return parseFloat(event.time) <= 40.0; // H1
      if (activeTab === 2) return parseFloat(event.time) > 40.0; // H2
      return false; 
    });
  }, [scoringEvents, activeTab]);
  

  const triesEvents = useMemo(
    () => filteredEvents.filter((event) => event.scoring_type === "Try"),
    [filteredEvents]
  );

  const kickingEvents = useMemo(
    () => filteredEvents.filter((event) => 
      ["Conversion", "Penalty Goal", "1 Point Field Goal", "2 Point Field Goal"].includes(event.scoring_type)
    ),
    [filteredEvents]
  );

  useEffect(() => {
    setSelectedTryIndex(0);
    setSelectedKickIndex(0);
  }, [activeTab]);

  const handleNext = (type) => {
    if (type === "Try") {
      setSelectedTryIndex((prevIndex) =>
        prevIndex === triesEvents.length - 1 ? 0 : prevIndex + 1
      );
    } else {
      setSelectedKickIndex((prevIndex) =>
        prevIndex === kickingEvents.length - 1 ? 0 : prevIndex + 1
      );
    }
  };

  const handlePrev = (type) => {
    if (type === "Try") {
      setSelectedTryIndex((prevIndex) =>
        prevIndex === 0 ? triesEvents.length - 1 : prevIndex - 1
      );
    } else {
      setSelectedKickIndex((prevIndex) =>
        prevIndex === 0 ? kickingEvents.length - 1 : prevIndex - 1
      );
    }
  };

  const handleModalOpen = (event) => {
    setSelectedEvent(event);
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const flipCoordinates = (x, y, isSecondHalf) => {
    if (isSecondHalf) {
      return [92 - x, 50 - y];
    }
    return [x, y];
  };

  const renderDots = (events, type, selectedIndex) => {
    return events.map((event, index) => {
      const isSecondHalf = parseFloat(event.time) > 40.0;
      const shouldFlip = (activeTab === 2) || (activeTab === 0 && isSecondHalf);
      
      let [x, y] = flipCoordinates(
        (type === "Try" ? event.try_score_x : event.kicking_x) * 0.92,
        (type === "Try" ? event.try_score_y : event.kicking_y) * 0.5,
        shouldFlip
      );
      
      let [breakX, breakY] = flipCoordinates(
        event.break_x * 0.92,
        event.break_y * 0.5,
        shouldFlip
      );

      const color = event.team_id === team1Id ? team1Color : team2Color;
      const isSelected = index === selectedIndex;

      // Only render the dot if it's on the correct side for the current half
      const shouldRender = (activeTab === 0) || 
                           (activeTab === 1 && !isSecondHalf) || 
                           (activeTab === 2 && isSecondHalf);

      if (!shouldRender) return null;

      return (
        <g
          key={index}
          onClick={() =>
            type === "Try"
              ? setSelectedTryIndex(index)
              : setSelectedKickIndex(index)
          }
          style={{ cursor: "pointer" }}
        >
          <circle cx={x} cy={y} r="1" fill={color} className="scoring-dot" />
          {type === "Try" && (
            <circle cx={breakX} cy={breakY} r="1" fill="#C9CCCC" className="break-dot" />
          )}
          {type === "Try" && isSelected && (
            <line x1={breakX} y1={breakY} x2={x} y2={y} stroke="red" strokeWidth="0.3" />
          )}
        </g>
      );
    });
  };

  const renderMap = (events, type, selectedIndex) => {
    const selectedEvent = events[selectedIndex];
    
    if (!selectedEvent) {
      return (
        <Box className="tries-kicks-locations-container">
          <Typography className="tries-kicks-locations-title h2-text">
            {type === "Try" ? "Tries" : "Kicking"}
          </Typography>
          <Typography>{type} locations map not available for this match yet.</Typography>
        </Box>
      );
    }
    
    const isSecondHalf = parseFloat(selectedEvent.time) > 40.0;
    const shouldFlip = (activeTab === 2) || (activeTab === 0 && isSecondHalf);
    
    let [x, y] = flipCoordinates(
      (type === "Try" ? selectedEvent.try_score_x : selectedEvent.kicking_x) * 0.92,
      (type === "Try" ? selectedEvent.try_score_y : selectedEvent.kicking_y) * 0.5,
      shouldFlip
    );
    
    let [breakX, breakY] = flipCoordinates(
      selectedEvent.break_x * 0.92,
      selectedEvent.break_y * 0.5,
      shouldFlip
    );

    const goalCircleColor = 
      selectedEvent.kick_successful === false ? "#FF685F" : "#73D482";
  
    return (
      <Box className="tries-kicks-locations-container">
        <Typography className="tries-kicks-locations-title h2-text">
          {type === "Try" ? "Tries" : "Kicking"}
        </Typography>
        <svg
          viewBox="0 0 92 50"
          preserveAspectRatio="xMidYMid meet"
          className="tries-kicks-locations-image"
        >
          <image href={scoringMap} width="92" height="50" />
          {renderDots(events, type, selectedIndex)}
          {events.length > 0 && (
            <>
              <circle
                cx={x}
                cy={y}
                r="1.2"
                fill="none"
                stroke="white"
                strokeWidth="0.3"
                className="highlighted-circle"
              />
              <circle
                cx={x}
                cy={y}
                r="1.4"
                fill="none"
                stroke="red"
                strokeWidth="0.3"
                className="highlighted-circle"
              />
              {type === "Try" && (
                <>
                  <circle
                    cx={breakX}
                    cy={breakY}
                    r="1.2"
                    fill="none"
                    stroke="white"
                    strokeWidth="0.3"
                    className="highlighted-break-circle"
                  />
                  <circle
                    cx={breakX}
                    cy={breakY}
                    r="1.4"
                    fill="none"
                    stroke="red"
                    strokeWidth="0.3"
                    className="highlighted-break-circle"
                  />
                  <line 
                    x1={breakX} 
                    y1={breakY} 
                    x2={x} 
                    y2={y} 
                    stroke="red" 
                    strokeWidth="0.3" 
                    className="connecting-line"
                  />
                </>
              )}
            </>
          )}
        </svg>
        <Box className="tries-kicks-navigation">
          <IconButton
            className="back-button-box backward-button-box"
            onClick={() => handlePrev(type)}
          >
            <ArrowBackIosIcon className="back-button-icon" />
          </IconButton>
          <Box className="player-info">
            <Box className="goal-circle" style={{ backgroundColor: goalCircleColor }}>
            </Box>
            <Box>
              <Typography
                className="locations-player-name"
                onClick={() => handleModalOpen(selectedEvent)}
              >
                {`${selectedEvent.time}' ${selectedEvent.first_name} ${selectedEvent.last_name}`}
              </Typography>
              {type !== "Try" && (
                <Typography className="scoring-type-text">
                  {selectedEvent.scoring_type}
                </Typography>
              )}
            </Box>
          </Box>
          <IconButton
            className="back-button-box forward-button-box"
            onClick={() => handleNext(type)}
          >
            <ArrowForwardIosIcon className="back-button-icon " />
          </IconButton>
        </Box>
      </Box>
    );
  };
  

  return (
    <Box className="tries-kicks-main-column">
      <Box className="row">
        <CustomTabs
          tabs={tabs}
          activeTab={activeTab}
          onTabClick={setActiveTab}
        />
      </Box>
      <Box className="row collapse-collumns">
        <Box className="postmatch-matchplayerstats-column">
          {renderMap(triesEvents, "Try", selectedTryIndex)}
        </Box>
        <Box className="postmatch-matchplayerstats-column">
          {renderMap(kickingEvents, "Conversion", selectedKickIndex)}
        </Box>
      </Box>

      {selectedEvent && (
        <PlayerModal
          open={isModalOpen}
          handleClose={handleModalClose}
          player={{
            playerName: `${selectedEvent.first_name} ${selectedEvent.last_name}`,
            position: "Position", // Replace with actual position data if available
            team: selectedEvent.team_id === team1Id ? "Team 1" : "Team 2", // Replace with actual team names if available
            jerseyNumber: "Number", // Replace with actual jersey number if available
          }}
        />
      )}
    </Box>
  );
};

export default TriesKicksLocations;
