import React from 'react';
import { Box, Typography } from '@mui/material';
import { usePreMatchDataContext } from '../../../hooks/PreMatchDataContext';
import { useTeamColors } from '../../../hooks/TeamColorsContext';
import './HeadToHead.css';

const getImagePath = (imageName) => {
  try {
    return require(`./../../../assets/team_logos/${imageName.toLowerCase().replace(/\s/g, '-')}.svg`);
  } catch (error) {
    return require(`./../../../assets/lineupPlayer.png`);
  }
};

const HeadToHead = () => {
  const data = usePreMatchDataContext();
  const teamColors = useTeamColors().teamColours;
  const { matchForm } = data;
  const { matchBasicInfo } = data;
  if (!matchForm || !matchForm.headToHead) return null;

  const { headToHead } = matchForm;
  const team1 = matchBasicInfo.team1;
  const team2 = matchBasicInfo.team2;
  
  // Get the colors from the context based on team IDs
  const team1Color = teamColors.find(team => team.team_id === team1.id)?.colour || '#74C8D4';
  const team2Color = teamColors.find(team => team.team_id === team2.id)?.colour || '#F19858';

 // Calculate wins, draws dynamically based on team IDs
  const team1Wins = headToHead.filter(match => 
  (match.team1.id === team1.id && match.team1.score > match.team2.score) ||
  (match.team2.id === team1.id && match.team2.score > match.team1.score)
  ).length;

  const team2Wins = headToHead.filter(match => 
    (match.team1.id === team2.id && match.team1.score > match.team2.score) ||
    (match.team2.id === team2.id && match.team2.score > match.team1.score)
  ).length;
  const draws = headToHead.filter(match => match.team1.score === match.team2.score).length;

  const team1Logo = getImagePath(team1.name);
  const team2Logo = getImagePath(team2.name);

  return (
    <Box className="postmatch-headtohead-container">
      <Box className="postmatch-headtohead-logo-column">
        <img src={team1Logo} alt={team1.name} className="postmatch-headtohead-logo" id="postmatch-headtohead-logo1"/>
      </Box>
      <Box className="postmatch-headtohead-stat-column">
        <Typography className="postmatch-headtohead-stat" style={{ backgroundColor: team1Color }}>
          {team1Wins}
        </Typography>
        <Typography className="postmatch-headtohead-label">Wins</Typography>
      </Box>
      <Box className="postmatch-headtohead-stat-column left-margin right-margin">
        <Typography className="postmatch-headtohead-stat draws">
          {draws}
        </Typography>
        <Typography className="postmatch-headtohead-label">Draws</Typography>
      </Box>
      <Box className="postmatch-headtohead-stat-column">
        <Typography className="postmatch-headtohead-stat" style={{ backgroundColor: team2Color }}>
          {team2Wins}
        </Typography>
        <Typography className="postmatch-headtohead-label">Wins</Typography>
      </Box>
      <Box className="postmatch-headtohead-logo-column">
        <img src={team2Logo} alt={team2.name} className="postmatch-headtohead-logo" id="postmatch-headtohead-logo2" />
      </Box>
    </Box>
  );
};

export default HeadToHead;
