import React, { useState, useRef, useEffect } from "react";
import { Box, Typography, LinearProgress } from "@mui/material";
import CustomTabs from "../../SharedComponents/CustomTabs/CustomTabs";
import { usePreMatchDataContext } from "../../../hooks/PreMatchDataContext";
import ShowMoreButton from "../../SharedComponents/ShowMoreButton/ShowMoreButton";
import InfoTooltip from "../../SharedComponents/InfoToolTip/InfoToolTip";
import "./PreMatchTryscorers.css";

const PreMatchTryscorers = ({ bettingTryscorers }) => {
  const [activeTab, setActiveTab] = useState(0);
  const [showAll, setShowAll] = useState(false);
  const data = usePreMatchDataContext();
  const { bettingBWLW } = data;

  const contentRef = useRef(null);
  const [contentHeight, setContentHeight] = useState(0);

  useEffect(() => {
    if (showAll) {
      setContentHeight(contentRef.current.scrollHeight);
    } else {
      setContentHeight(0);
    }
  }, [showAll]);

  if (!bettingTryscorers) return null;

  const getImagePath = (imageName) => {
    try {
      return require(`./../../../assets/${imageName}.png`);
    } catch (error) {
      return require(`./../../../assets/lineupPlayer.png`);
    }
  };

  const renderTryscorer = (player) => {
    let numerator, percentage;
    switch (activeTab) {
      case 0: // Anytime
        numerator = player.games_scored_1_or_more;
        break;
      case 1: // 2 or more
        numerator = player.games_scored_2_or_more;
        break;
      case 2: // 3 or more
        numerator = player.games_scored_3_or_more;
        break;
      case 3: // 1st tryscorer
        numerator = player.games_scored_first;
        break;
      case 4: // 2nd tryscorer
        numerator = player.games_scored_second;
        break;
      case 5: // 3rd tryscorer
        numerator = player.games_scored_third;
        break;
      case 6: // 1st or 2nd tryscorer
        numerator = player.games_scored_first_or_second;
        break;
      case 7: // 1st, 2nd, or 3rd tryscorer
        numerator = player.games_scored_first_or_second_or_third;
        break;
      case 8: // Last Tryscorer
        numerator = player.games_scored_last;
        break;
      case 9: // First or last tryscorer
        numerator = player.games_scored_first_or_last;
        break;
      default:
        numerator = 0;
    }
    percentage = ((numerator / player.games_played) * 100).toFixed(0);

    return (
      <Box className="tryscorer-row" key={player.id}>
        <Box className="tryscorer-info">
          <Box className="tryscorer-name-container">
            <img
              src={getImagePath(
                `${player.first_name.toLowerCase()}_${player.last_name.toLowerCase()}`
              )}
              alt={`${player.first_name} ${player.last_name}`}
              className="tryscorer-image"
            />
            <Typography className="tryscorer-name body-text">
              {`${player.first_name} ${player.last_name}`}
            </Typography>
          </Box>
          <Typography className="tryscorer-bold-value">
            {`${percentage}% (${numerator}/${player.games_played})`}
          </Typography>
        </Box>
        <LinearProgress
          variant="determinate"
          value={percentage}
          className="tryscorer-progress-bar"
          sx={{ width: "100%", marginTop: "5px", height: "4px" }}
        />
      </Box>
    );
  };

  const orderedTeamIds = [bettingBWLW.team1.teamId, bettingBWLW.team2.teamId];

  const getPlayersByTeam = (teamId) => {
    return bettingTryscorers.players
      .filter((player) => player.team_id === teamId)
      .sort((a, b) => {
        let aValue, bValue;
        switch (activeTab) {
          case 0: // Anytime
            aValue = a.games_scored_1_or_more;
            bValue = b.games_scored_1_or_more;
            break;
          case 1: // 2 or more
            aValue = a.games_scored_2_or_more;
            bValue = b.games_scored_2_or_more;
            break;
          case 2: // 3 or more
            aValue = a.games_scored_3_or_more;
            bValue = b.games_scored_3_or_more;
            break;
          case 3: // 1st tryscorer
            aValue = a.games_scored_first;
            bValue = b.games_scored_first;
            break;
          case 4: // 2nd tryscorer
            aValue = a.games_scored_second;
            bValue = b.games_scored_second;
            break;
          case 5: // 3rd tryscorer
            aValue = a.games_scored_third;
            bValue = b.games_scored_third;
            break;
          case 6: // 1st or 2nd tryscorer
            aValue = a.games_scored_first_or_second;
            bValue = b.games_scored_first_or_second;
            break;
          case 7: // 1st, 2nd, or 3rd tryscorer
            aValue = a.games_scored_first_or_second_or_third;
            bValue = b.games_scored_first_or_second_or_third;
            break;
          case 8: // Last Tryscorer
            aValue = a.games_scored_last;
            bValue = b.games_scored_last;
            break;
          case 9: // First or last tryscorer
            aValue = a.games_scored_first_or_last;
            bValue = b.games_scored_first_or_last;
            break;
          default:
            aValue = 0;
            bValue = 0;
        }
        return bValue / b.games_played - aValue / a.games_played;
      });
  };

  const tabs = [
    "Anytime",
    "2 or more",
    "3 or more",
    "First",
    "Second",
    "Third",
    "1st or 2nd",
    "1st, 2nd, or 3rd",
    "Last",
    "First / Last",
  ];

  const getTeamName = (teamId) => {
    if (bettingBWLW.team1.teamId === teamId) {
      return bettingBWLW.team1.teamName;
    } else if (bettingBWLW.team2.teamId === teamId) {
      return bettingBWLW.team2.teamName;
    }
    return `Team ${teamId}`; // Fallback in case team is not found
  };

  const renderTeamPlayers = (teamId) => {
    const players = getPlayersByTeam(teamId);
    const displayedPlayers = showAll ? players : players.slice(0, 7);
    const teamName = getTeamName(teamId);

    return (
      <Box key={teamId} className="tryscorers-column">
        <Typography className="h2-text">{teamName}</Typography>
        {displayedPlayers.slice(0, 7).map((player) => renderTryscorer(player))}
        <Box
          ref={contentRef}
          className={`collapsible-content ${showAll ? 'expanded' : 'collapsed'}`}
          sx={{ maxHeight: `${contentHeight}px` }}
        >
          {players.slice(7).map((player) => renderTryscorer(player))}
        </Box>
      </Box>
    );
  };

  return (
    <Box className="prematch-betting-tryscorers">
      <Box className="title-container">
        <Typography className="betting-analtics-title">Top Tryscorers</Typography>
        <InfoTooltip title="Number of games it actually happened divided by number of games the player has played in." />
      </Box>
      {bettingTryscorers.players.length === 0 ? (
        <Typography className="body-text section-margin">
          Our tryscoring data is released at 4:30PM Tuesday when our lineup updates.
        </Typography>
      ) : (
        <>
          <CustomTabs
            tabs={tabs}
            activeTab={activeTab}
            onTabClick={setActiveTab}
          />
          <Box className="tryscorers-list">
            {orderedTeamIds.map((teamId) => renderTeamPlayers(teamId))}
          </Box>
          {bettingTryscorers.players.length > 7 && (
            <ShowMoreButton
              showAll={showAll}
              onClick={() => setShowAll(!showAll)}
              className="show-more-button"
            />
          )}
        </>
      )}
    </Box>
  );
};

export default PreMatchTryscorers;