import React from 'react';
import { Box, Typography, Paper, Divider } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { useTeamDataContext } from '../../../hooks/TeamDataContext';
import PersonIcon from '@mui/icons-material/Person';
import LinkIcon from '@mui/icons-material/Link';
import './TeamPageBanner.css';

const getImagePath = (teamName) => {
  try {
    return require(`../../../assets/team_logos/${teamName.toLowerCase().replace(/\s/g, '-')}.svg`);
  } catch (error) {
    return require(`../../../assets/team_logos/bye.png`);
  }
};

const TeamPageBanner = () => {
  const data = useTeamDataContext();
  if (!data || !data.teamBasicInfo) return null;

  const { teamBasicInfo } = data;
  const { team_name_short, ladder_position, coach, official_page_url } = teamBasicInfo;

  const teamName = team_name_short;

  return (
    <Box className="team-page-banner-container">
      <Box className="team-page-banner-content">
        <Box className="team-page-banner-logo-name">
          <img src={getImagePath(teamName)} alt={teamName} className="team-page-banner-logo" />
          <Typography variant="h4" className="team-page-banner-team-name">{teamName}</Typography>
        </Box>
      </Box>
      <Divider className="team-page-banner-divider" />
      <Box className="team-page-banner-bottom">
        <Box className="team-page-banner-info">
          <Paper className="match-header-tag">
            <LocationOnIcon className="team-page-banner-tag-icon" /> 
            <Typography>Ladder Position: {ladder_position}</Typography>
          </Paper>
          <Paper className="match-header-tag">
            <PersonIcon className="team-page-banner-tag-icon" /> 
            <Typography>Coach: {coach}</Typography>
          </Paper>
          <Paper className="match-header-official-match-page">
            <LinkIcon className="team-page-banner-tag-icon" />
            <Typography>
              <a href={official_page_url} target="_blank" rel="noopener noreferrer" className="official-match-page-link">
                Official Team Page
              </a>
            </Typography>
          </Paper>
        </Box>
      </Box>
    </Box>
  );
};

export default TeamPageBanner;
