// hooks/TeamDataContext.js
import React, { createContext, useContext } from 'react';

const TeamDataContext = createContext(null);

export const useTeamDataContext = () => {
  return useContext(TeamDataContext);
};

export const TeamDataProvider = ({ value, children }) => {
  return (
    <TeamDataContext.Provider value={value}>
      {children}
    </TeamDataContext.Provider>
  );
};