import React from 'react';
import { Box, Typography } from '@mui/material';
import underConstructionSvg from "./../../../assets/underConstruction.svg"; // Make sure this path is correct
import './UnderConstructionMessage.css';

const UnderConstructionMessage = () => {
  return (
    <Box className="construction-container">
      <img 
        src={underConstructionSvg} 
        alt="Under Construction" 
        style={{ width: '300px', height: '300px', marginBottom: '20px', marginTop: '150px' }} // Inline styles for the SVG
      />
      <Typography className="construction-msg">
        Players page coming soon!
      </Typography>
      <Typography className="construction-msg">
        Follow us on instagram @trylineapp for all feature release updates
      </Typography>
    </Box>
  );
};

export default UnderConstructionMessage;
