import React from 'react';
import { Box, Typography, Divider } from '@mui/material';
import { usePreMatchDataContext } from '../../../hooks/PreMatchDataContext';
import PostMatchFormLast5Matches from '../../PostMatchComponents/PostMatchFormLast5Matches/PostMatchFormLast5Matches';
import HeadToHead from '../../PostMatchComponents/HeadToHead/HeadToHead';
import PastRoundsForTwoTeams from '../../PostMatchComponents/PastRoundsForTwoTeams/PastRoundsForTwoTeams';
import './PreMatchFormSection.css';

const PreMatchFormSection = () => {
  const data = usePreMatchDataContext();
  const { matchForm } = data;

  if (!matchForm) return null;

  return (
    <Box className="prematch-form-container">
      <Box className="prematch-form-section">
        <Typography variant="h6" className="prematch-form-title">Last 5 Matches</Typography>
        <Box>
          <PostMatchFormLast5Matches />
        </Box>
      </Box>
      <Divider className='prematch-form-section-divider'/>
      <Box className="prematch-form-section">
        <Typography variant="h6" className="prematch-form-title">Head To Head</Typography>
        <HeadToHead />
      </Box>
      <Divider className='prematch-form-section-divider'/>
      <Box className="prematch-form-section">
        <PastRoundsForTwoTeams />
      </Box>
    </Box>
  );
};

export default PreMatchFormSection;