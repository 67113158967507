import React from 'react';
import { Container, CssBaseline, Box, Typography } from '@mui/material';
import Header from '../../components/SharedComponents/Header/Header';
import Footer from '../../components/SharedComponents/Footer/Footer';
import "../AboutUsPage/AboutUsPage.css";

function OurDataPage() {
  return (
    <div className="about-page-root">
      <div className="scrollable-content">
    <div className="outer-container">
      <Container component="main" className="main-container">
        <CssBaseline />
        <Header />
        <Box className="about-us-page-container main-content-box">
          <Typography className="aboutus-header-text" component="h1" gutterBottom>
            Our Data
          </Typography>
          <Typography variant="body1" className="aboutus-text" paragraph>
            Information available on our site is taken from various publicly available sources.
          </Typography>
          <Typography variant="body1" className="aboutus-text" paragraph>
            Additionally, some information is also generated by us.
          </Typography>
          <Typography variant="body1" className="aboutus-text" paragraph>
            Tryline aims to provide accurate data and we constantly check against official data and other sources to ensure that our data is matching.
          </Typography>
          <Typography variant="body1" className="aboutus-text" paragraph>
            The data provided by us is for private use, entertainment and general information purposes only.
          </Typography>
          <Typography variant="body1" className="aboutus-text" paragraph>
            As a user, you accept that you access our site and information at your own risk.
          </Typography>
          {/* <Typography className="aboutus-gambling-text" paragraph>
            Our data should not be used as a reason for you to gamble.
          </Typography> */}
        </Box>
      </Container>
    </div>
      <Footer/>
    </div>
    </div>
  );
}

export default OurDataPage;
