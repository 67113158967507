import React, { useState } from 'react';
import {
  IconButton,
  Select,
  MenuItem,
  Box,
  Divider,
  Snackbar
} from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import "./LadderFilterBar.css";

const LadderFilterBar = React.memo(({ currentYear, currentRound, seasonSetter, roundSetter, seasons, rounds, viewMode, setViewMode, isSmallScreen }) => {
  const [showSnackbar, setShowSnackbar] = useState(false);

  const handlePreviousRound = () => {
    const currentIndex = rounds.indexOf(currentRound);
    if (currentIndex > 0) {
      roundSetter(rounds[currentIndex - 1]);
    }
  };

  const handleNextRound = () => {
    const currentIndex = rounds.indexOf(currentRound);
    if (currentIndex < rounds.length - 1) {
      roundSetter(rounds[currentIndex + 1]);
    }
  };

  const handleYearChange = (event) => {
    const newYear = Number(event.target.value);
    if (newYear !== currentYear) {
      seasonSetter(newYear);
    }
  };

  const handleViewModeChange = (event) => {
    const newViewMode = event.target.value;
    if (isSmallScreen && newViewMode === 'Trajectory') {
      setShowSnackbar(true);
    } else {
      setViewMode(newViewMode);
    }
  };

  return (
    <Box className="ladder-filterbar-container">
      {viewMode !== 'Trajectory' && (
        <Box className="round-selection">
          <IconButton className="back-button-box backward-button-box" onClick={handlePreviousRound} disabled={rounds.indexOf(currentRound) === 0}>
            <ArrowBackIosIcon className="back-button-icon"/>
          </IconButton>
          <Select 
            variant="standard" 
            value={currentRound}
            onChange={(e) => roundSetter(e.target.value)}
            className="round-dropdown ladder-round-select"
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 300,
                },
              },
            }}
          >
            {rounds.map(round => (
              <MenuItem key={round} value={round}>Round {round}</MenuItem>
            ))}
          </Select>
          <IconButton className="back-button-box forward-button-box" onClick={handleNextRound} disabled={rounds.indexOf(currentRound) === rounds.length - 1}>
            <ArrowForwardIosIcon className="back-button-icon"/>
          </IconButton>
        </Box>
      )}
      <Divider />
      <Box className="filters-container">
        <Box className="filter-dropdown tag">
          <Select 
            variant="standard" 
            value={currentYear} 
            onChange={handleYearChange}
            className="filter-dropdown-field-label ladder-year-select"
            fullWidth
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 300,
                },
              },
            }}
          >
            {seasons.map(year => (
              <MenuItem key={year} value={year}>{year}</MenuItem>
            ))}
          </Select>
        </Box>
        <Box className="filter-dropdown tag">
          <Select
            variant="standard"
            value={viewMode}
            onChange={handleViewModeChange}
            className="filter-dropdown-field-label ladder-view-mode-select"
            fullWidth
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 300,
                },
              },
            }}
          >
            <MenuItem value="Standard">Standard</MenuItem>
            <MenuItem value="Trajectory">Trajectory</MenuItem>
          </Select>
        </Box>
      </Box>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={showSnackbar}
        onClose={() => setShowSnackbar(false)}
        message="Trajectory view is only available on larger screens."
        autoHideDuration={3000}
      />
    </Box>
  );
});

export default LadderFilterBar;