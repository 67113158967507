import React, { useState, useMemo, useCallback } from "react";
import { Box, Typography, Switch } from "@mui/material";
import { styled } from "@mui/material/styles";
import scoringMap from "../../../assets/scoringMap.svg";
import { useTeamDataContext } from "../../../hooks/TeamDataContext";
import "./TeamTriesLocation.css";
const teamColors = {
  Storm: "#62058a",
  Panthers: "#383838",
  Roosters: "#132951",
  Sharks: "#0d9ac1",
  Bulldogs: "#1f5ac7",
  Cowboys: "#090b51",
  "Sea Eagles": "#76174d",
  Dragons: "#f13123",
  Broncos: "#3e0315",
  Dolphins: "#980a0a",
  Knights: "#110d95",
  Raiders: "#26d337",
  Titans: "#10b6e3",
  Warriors: "#141414",
  Rabbitohs: "#169850",
  "Wests Tigers": "#fb7005",
  Eels: "#ebc321",
};

const CustomSwitch = styled(Switch)(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: "#36b241",
        opacity: 1,
        border: 0,
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: "#E9E9EA",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const TeamTriesLocation = () => {
  const [selectedTryIndex, setSelectedTryIndex] = useState(0);
  const [showHeatmap, setShowHeatmap] = useState(false);
  const teamData = useTeamDataContext();

  const triesEvents = useMemo(() => {
    if (!teamData || !teamData.teamTriesAndConversions) return [];
    return teamData.teamTriesAndConversions.tryEvents;
  }, [teamData]);

  const teamColor = useMemo(() => {
    if (!teamData || !teamData.teamBasicInfo) return "#000000";
    const teamName = teamData.teamBasicInfo.team_name_short;
    return teamColors[teamName] || "#000000";
  }, [teamData]);

  const adjustCoordinates = useCallback((x, y) => {
    x = x * 0.5;
    y = (100 - y) * 0.92;
    if (y > 46) {
      x = 50 - x;
      y = 92 - y;
    }
    return [x, y];
  }, []);

  const renderDots = useCallback(() => {
    return triesEvents.map((event, index) => {
      let [x, y] = adjustCoordinates(
        parseFloat(event.try_score_y),
        parseFloat(event.try_score_x)
      );
      let [breakX, breakY] = adjustCoordinates(
        parseFloat(event.break_y),
        parseFloat(event.break_x)
      );

      return (
        <g
          key={index}
          onClick={() => setSelectedTryIndex(index)}
          style={{ cursor: "pointer" }}
        >
          <circle
            cx={x}
            cy={y}
            r="0.6"
            fill={teamColor}
            className="scoring-dot"
          />
          <circle
            cx={breakX}
            cy={breakY}
            r="0.6"
            fill="#C9CCCC"
            className="break-dot"
          />
        </g>
      );
    });
  }, [triesEvents, teamColor, adjustCoordinates]);

  const renderHighlights = useCallback(() => {
    if (selectedTryIndex >= 0 && selectedTryIndex < triesEvents.length) {
      const event = triesEvents[selectedTryIndex];
      let [x, y] = adjustCoordinates(
        parseFloat(event.try_score_y),
        parseFloat(event.try_score_x)
      );
      let [breakX, breakY] = adjustCoordinates(
        parseFloat(event.break_y),
        parseFloat(event.break_x)
      );

      return (
        <g>
          <line
            x1={breakX}
            y1={breakY}
            x2={x}
            y2={y}
            stroke="red"
            strokeWidth="0.15"
          />
          <circle
            cx={x}
            cy={y}
            r="0.8"
            fill="none"
            stroke="white"
            strokeWidth="0.15"
            className="highlighted-circle"
          />
          <circle
            cx={x}
            cy={y}
            r="1"
            fill="none"
            stroke="red"
            strokeWidth="0.15"
            className="highlighted-circle"
          />
          <circle
            cx={breakX}
            cy={breakY}
            r="0.8"
            fill="none"
            stroke="white"
            strokeWidth="0.15"
            className="highlighted-break-circle"
          />
          <circle
            cx={breakX}
            cy={breakY}
            r="1"
            fill="none"
            stroke="red"
            strokeWidth="0.15"
            className="highlighted-break-circle"
          />
        </g>
      );
    }
    return null;
  }, [selectedTryIndex, triesEvents, adjustCoordinates]);

  const getHeatmapColor = useCallback((intensity) => {
    if (intensity < 0.33) {
      // Green to Yellow
      const r = Math.round(255 * (intensity * 3));
      const g = 255;
      const b = 0;
      return `rgb(${r},${g},${b})`;
    } else if (intensity < 0.66) {
      // Yellow to Red
      const r = 255;
      const g = Math.round(255 * (1 - (intensity - 0.33) * 3));
      const b = 0;
      return `rgb(${r},${g},${b})`;
    } else {
      // Red
      return "rgb(255,0,0)";
    }
  }, []);

  const renderHeatmap = useCallback(() => {
    if (!showHeatmap) return null;

    const heatmapData = triesEvents.reduce((acc, event) => {
      let [x, y] = adjustCoordinates(
        parseFloat(event.try_score_y),
        parseFloat(event.try_score_x)
      );
      const key = `${Math.round(x)},${Math.round(y)}`;
      acc[key] = (acc[key] || 0) + 1;
      return acc;
    }, {});

    const maxIntensity = Math.max(...Object.values(heatmapData));

    // Sort heatmap points by intensity to bring high concentration areas to the front
    const sortedHeatmapPoints = Object.entries(heatmapData)
      .map(([key, value]) => ({ key, value }))
      .sort((a, b) => a.value - b.value);

    return (
      <g filter="url(#blur)">
        {sortedHeatmapPoints.map(({ key, value }, index) => {
          const [x, y] = key.split(",").map(Number);
          const intensity = value / maxIntensity;
          const radius = 1 + intensity * 1.5; // Reduced base and max radius
          const opacity = 0.3 + intensity * 0.5; // Adjusted opacity range
          const color = getHeatmapColor(intensity);

          return (
            <circle
              key={index}
              cx={x}
              cy={y}
              r={radius}
              fill={color}
              opacity={opacity}
              className="heatmap-point"
            />
          );
        })}
      </g>
    );
  }, [showHeatmap, triesEvents, adjustCoordinates, getHeatmapColor]);

  if (!teamData || !teamData.teamTriesAndConversions) return null;

  return (
    <Box className="tries-locations-container">
      <Box className="tries-locations-container-row">
        {/* <Box className="tries-locations-header"> */}
          <Typography className="h1-text h1-text-team-overview">
            Tries Location
          </Typography>
          <Box className="heatmap-toggle">
            <Typography
              className={`heatmap-label ${showHeatmap ? "active" : ""}`}
            >
              Heatmap
            </Typography>
            <CustomSwitch
              checked={showHeatmap}
              onChange={(e) => setShowHeatmap(e.target.checked)}
              size="small"
            />
          </Box>
        {/* </Box> */}
      </Box>

      <Box className="tries-locations-container-row2">
        <Box className="tries-locations-map-container">
          <Box className="tries-locations-map">
            <svg
              viewBox="0 0 50 92"
              preserveAspectRatio="xMidYMid meet"
              className="tries-locations-image"
            >
              <defs>
                <filter id="blur">
                  <feGaussianBlur in="SourceGraphic" stdDeviation="0.5" />
                </filter>
              </defs>
              <image
                href={scoringMap}
                width="92"
                height="50"
                transform="translate(50, 0) rotate(90)"
              />
              {renderHeatmap()}
              {!showHeatmap && renderDots()}
              {!showHeatmap && renderHighlights()}
            </svg>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default TeamTriesLocation;
