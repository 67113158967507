import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Container, CssBaseline, Box } from '@mui/material';
import Header from '../../components/SharedComponents/Header/Header';
import './ChatBot.css';
import config from '../../config';
import Footer from '../../components/SharedComponents/Footer/Footer';

const ChatBot = () => {
  const [query, setQuery] = useState('');
  const [messages, setMessages] = useState([]);
  const [isTyping, setIsTyping] = useState(false);
  const chatBoxRef = useRef(null);

  useEffect(() => {
    setMessages([
      {
        type: 'bot',
        text: "Our dataBot is experimental and being improved.\n\n Start by asking questions like: \n\nHow many tries did Josh Reynolds score in 2016\nWho scored the most points in 2023\nWho made the most tackles in 2023\n\nSome complex questions can also be considered 'easy' by our ChatBot"
      }
    ]);
  }, []);

  useEffect(() => {
    if (chatBoxRef.current) {
      chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
    }
  }, [messages, isTyping]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!query.trim()) return;

    const userMessage = { type: 'user', text: query };
    setMessages((prevMessages) => [...prevMessages, userMessage]);
    setQuery('');
    setIsTyping(true);

    try {
      const response = await axios.post(`${config.backendUrl}/query`, { question: query });
      const botResponse = response.data.answer;
      setIsTyping(false);
      setMessages((prevMessages) => [...prevMessages, { type: 'bot', text: botResponse }]);
    } catch (error) {
      console.error('Error querying the backend:', error);
      setIsTyping(false);
      setMessages((prevMessages) => [...prevMessages, { type: 'bot', text: 'Error: Unable to get response.' }]);
    }
  };

  return (
    <div className="chatbot-page-root">
      <div className="scrollable-content">
        <div className ="outer-container">
        <Container component="main" className="main-container-chatbot">
          <CssBaseline />
          <Header />
          <Box className="chat-container">
            <div className="chat-box" ref={chatBoxRef}>
              {messages.map((msg, index) => (
                <div key={index} className={`message ${msg.type}`}>
                  <div className="message-bubble">
                    {msg.text.split('\n').map((line, i) => (
                      <React.Fragment key={i}>
                        {line}
                        {i < msg.text.split('\n').length - 1 && <br />}
                      </React.Fragment>
                    ))}
                  </div>
                </div>
              ))}
              {isTyping && (
                <div className="message bot">
                  <div className="typing-indicator">
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </div>
              )}
            </div>
            <form onSubmit={handleSubmit} className="input-form">
              <input
                type="text"
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                placeholder="Type your query here..."
                className="input-field"
              />
              <button type="submit" className="send-button">Send</button>
            </form>
          </Box>
        </Container>
        </div>
        <Footer></Footer>
      </div>
    </div>
  );
};

export default ChatBot;