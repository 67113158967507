import React, { useEffect } from "react";
import { Container, CssBaseline, Box } from "@mui/material";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "../../components/SharedComponents/Header/Header";
import SmallLadder from "../../components/SharedComponents/Ladder/SmallLadder";
import PostMatchHighlights from "../../components/PostMatchComponents/PostMatchHighlights/PostMatchHighlights";
import PostMatchHeader from "../../components/PostMatchComponents/PostMatchHeader/PostMatchHeader";
import PostMatchOverviewSection from "../../components/PostMatchComponents/PostMatchOverviewSection/PostMatchOverviewSection";
import MatchLineUpSection from "../../components/PostMatchComponents/MatchLineupSection/MatchLineupSection";
import PostMatchAllStats from "../../components/PostMatchComponents/PostMatchAllStats/PostMatchAllStats";
import { usePostMatchData } from "../../hooks/usePostMatchData";
import { PostMatchDataProvider } from "../../hooks/PostMatchDataContext";
import MatchPageLoadingSkeleton from "../../components/MatchPageSharedComponents/MatchPageLoadingSkeleton"; // Import the new component
import ErrorMessage from "../../components/SharedComponents/ErrorMessage/ErrorMessage";
import Footer from "../../components/SharedComponents/Footer/Footer";
import "./PostMatchPage.css";
import "./../../components/MatchPageSharedComponents/MatchPage.css";

function PostMatchPage() {
  const location = useLocation();
  const navigate = useNavigate();

  const searchParams = new URLSearchParams(location.search);
  const matchId = searchParams.get("matchid");

  const { data, isLoading, isError } = usePostMatchData(matchId);

  const initialTab = searchParams.get("tab") || "overview";
  const [value, setValue] = React.useState(initialTab);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    searchParams.set("tab", newValue);
    navigate({ search: searchParams.toString() }, { replace: true });
  };

  useEffect(() => {
    setValue(initialTab);
  }, [initialTab]);

  return (
    <div className="postmatch-page-root">
      <div className="scrollable-content">
    <PostMatchDataProvider value={data}>
      {/* <div> */}
      <Container component="main" className="main-container">
        <CssBaseline />
        <Header />
        {isLoading ? (
          <MatchPageLoadingSkeleton />
        ) : isError ? (
          <ErrorMessage />
        ) : (
          <Box className="match-page-container main-content-box">
            <Box className="match-page-column1">
              <PostMatchHeader />
              <Box className="match-page-content">
                <TabContext value={value}>
                  <Box
                    sx={{ borderBottom: 1, borderColor: "divider" }}
                    className="sticky-tabs"
                  >
                    <TabList
                      onChange={handleChange}
                      aria-label="match tabs"
                      variant="scrollable"
                      scrollButtons={false}
                      TabIndicatorProps={{ style: { backgroundColor: "#969696" } }}
                      className="scrollable-tabs"
                    >
                      <Tab
                        label="Overview"
                        value="overview"
                        className="match-page-tabs"
                      />
                      <Tab
                        label="Lineup"
                        value="lineup"
                        className="match-page-tabs"
                      />
                      <Tab
                        label="Stats"
                        value="stats"
                        className="match-page-tabs"
                      />
                      <Tab
                        label="Comments"
                        value="comments"
                        className="match-page-tabs"
                      />
                      </TabList>
                    </Box>
                  <TabPanel value="overview">
                    <PostMatchOverviewSection />
                  </TabPanel>
                  <TabPanel value="lineup">
                    <MatchLineUpSection isPostMatch={true}/>
                  </TabPanel>
                  <TabPanel value="stats">
                    <PostMatchAllStats />
                  </TabPanel>
                    <TabPanel value="comments">
                        Coming 2025 season...
                    </TabPanel>
                </TabContext>
              </Box>
            </Box>
            <Box className="match-page-column2">
              <PostMatchHighlights className="postmatch-highlights" />
              <SmallLadder />
            </Box>
          </Box>
        )}
      </Container>
    
    </PostMatchDataProvider>  
    <Footer/>
      </div>
      </div>
  );
}

export default PostMatchPage;