import React, { useEffect } from "react";
import { Container, CssBaseline, Box } from "@mui/material";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { useLocation, useNavigate } from 'react-router-dom'; 
import Header from "../../components/SharedComponents/Header/Header";
import SmallLadder from "../../components/SharedComponents/Ladder/SmallLadder";
import PreMatchHighlights from "../../components/PreMatchComponents/PreMatchHighlights/PreMatchHighlights";
import PreMatchHeader from "../../components/PreMatchComponents/PreMatchHeader/PreMatchHeader";
import Footer from "../../components/SharedComponents/Footer/Footer";
import PreMatchPreviewSection from "../../components/PreMatchComponents/PreMatchPreviewSection/PreMatchPreviewSection";
import PreMatchFormSection from "../../components/PreMatchComponents/PreMatchFormSection/PreMatchFormSection";
import WhoWillWin from "../../components/PreMatchComponents/WhoWillWIn/WhoWillWIn";
import PreMatchBettingAnalytics from "../../components/PreMatchComponents/PreMatchBettingAnalytics/PreMatchBettingAnalytics";
import PreMatchScoringMap from "../../components/PreMatchComponents/PreMatchScoringMap/PreMatchScoringMap";
import { usePreMatchData } from "../../hooks/usePreMatchData";
import { PreMatchDataProvider } from "../../hooks/PreMatchDataContext";
import ErrorMessage from "../../components/SharedComponents/ErrorMessage/ErrorMessage";
import MatchPageLoadingSkeleton from "../../components/MatchPageSharedComponents/MatchPageLoadingSkeleton"; // Import the MatchPageLoadingSkeleton component

import "./PreMatchPage.css";
import "./../../components/MatchPageSharedComponents/MatchPage.css";

function PreMatchPage() {
  const location = useLocation();
  const navigate = useNavigate(); 
  const searchParams = new URLSearchParams(location.search);
  const matchId = searchParams.get("matchid");
  const initialTab = searchParams.get("tab") || "preview"; 

  const { data, isLoading, isError } = usePreMatchData(matchId);

  const [value, setValue] = React.useState(initialTab);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    searchParams.set("tab", newValue); 
    navigate({ search: searchParams.toString() }, { replace: true });
  };

  useEffect(() => {
    setValue(initialTab);
  }, [initialTab]);

  return (
    <div className="prematch-page-root">
      <div className="scrollable-content">
    <PreMatchDataProvider value={data}>

      <Container component="main" className="main-container">
        <CssBaseline />
        <Header />
        {isLoading ? (
          <MatchPageLoadingSkeleton /> // Use the MatchPageLoadingSkeleton during the loading state
        ) : isError ? (
          <ErrorMessage />
        ) : (
          <Box className="match-page-container main-content-box">
            <Box className="prematch-column-1 match-page-column1">
              <PreMatchHeader />
              <Box className="match-page-content">
                <TabContext value={value}>
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }} className="scrollable-tabs-container">
                    <TabList
                      onChange={handleChange}
                      aria-label="match tabs"
                      variant="scrollable"
                      scrollButtons={false}
                      TabIndicatorProps={{ style: { backgroundColor: "#969696" } }}
                      className="scrollable-tabs"
                    >
                      <Tab label="Preview" value="preview" className="match-page-tabs" />
                      <Tab label="Form" value="form" className="match-page-tabs" />
                      <Tab label="Betting Analytics" value="betting-analytics" className="match-page-tabs" />
                      <Tab label="Scoring Map" value="scoring-map" className="match-page-tabs" />
                      <Tab label="Comments" value="comments" className="match-page-tabs" />
                    </TabList>
                  </Box>
                  <TabPanel value="preview">
                    <PreMatchPreviewSection />
                  </TabPanel>
                  <TabPanel value="form">
                    <PreMatchFormSection />
                  </TabPanel>
                  <TabPanel value="scoring-map">
                    <PreMatchScoringMap />
                  </TabPanel>
                  <TabPanel value="betting-analytics">
                    <PreMatchBettingAnalytics />
                  </TabPanel>
                  <TabPanel value="comments">
                  Coming 2025 season...
                  </TabPanel>
                </TabContext>
              </Box>
            </Box>
            <Box className="match-page-column2">
              <PreMatchHighlights />
              <WhoWillWin />
              <SmallLadder />
            </Box>
          </Box>
        )}
      </Container>
    </PreMatchDataProvider>
      <Footer/>
      </div>
      </div>
  );
}

export default PreMatchPage;