const verticalPlayerPositions = {
    1: { x: 50, y: 5.5, label: 'FB' },   // Fullback
    2: { x: 80, y: 10, label: 'RW' },  // Right Winger
    3: { x: 65, y: 10, label: 'RC' },  // Right Centre
    4: { x: 35, y: 10, label: 'LC' },  // Left Centre
    5: { x: 20, y: 10, label: 'LW' },  // Left Winger
    6: { x: 40, y: 18, label: 'FE' },  // Five-Eighth
    7: { x: 60, y: 18, label: 'HF' },  // Halfback
    8: { x: 30, y: 42, label: 'LP' },  // Left Prop
    9: { x: 50, y: 42, label: 'HK' },  // Hooker
    10: { x: 70, y: 42, label: 'RP' }, // Right Prop
    11: { x: 40, y: 34, label: '2R' }, // Left 2nd Row
    12: { x: 60, y: 34, label: '2R' }, // Right 2nd Row
    13: { x: 50, y: 26, label: 'LK' }, // Lock
    // 15: { x: 32, y: 49, label: 'IC' }, 
    // 17: { x: 20, y: 56, label: 'RV' }, 
    // 18: { x: 40, y: 21, label: 'XX' }, 
  };
  
  export default verticalPlayerPositions;
  