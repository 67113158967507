import React from 'react';
import { Box, Typography, Paper } from '@mui/material';
import { useTeamDataContext } from '../../../hooks/TeamDataContext';
import './TeamPageOverviewFormBox.css';

const getImagePath = (imageName) => {
  try {
    return require(`./../../../assets/team_logos/${imageName.toLowerCase().replace(/\s/g, '-')}.svg`);
  } catch (error) {
    return require(`./../../../assets/lineupPlayer.png`);
  }
};

const MatchResult = ({ opponent, score, opponentScore, ourTeamWin }) => {
  const resultColor = ourTeamWin ? 'var(--green)' : 'var(--red)'; // Use CSS variables
  const teamLink = `/teams/${opponent.toLowerCase().replace(/\s/g, '-')}`; // Construct the team link

  return (
    <Box className="team-page-form-match-result">
      {/* Wrap the image with a link */}
      <a href={teamLink} className="small-ladder-team-link">
        <img src={getImagePath(opponent)} alt={opponent} className="team-page-form-opponent-logo" />
      </a>
      <Paper className="team-page-form-score-box" style={{ backgroundColor: resultColor }}>
        <Typography>{score} - {opponentScore}</Typography>
      </Paper>
    </Box>
  );
};

const TeamPageOverviewFormBox = () => {
  const data = useTeamDataContext();
  if (!data || !data.teamBasicInfo || !data.teamLastFiveMatches) return null;

  const { teamBasicInfo, teamLastFiveMatches } = data;
  const { team_name_short } = teamBasicInfo;

  return (
    <Box>
      <Typography className='subheader-text h1-text-team-overview'>Team Form</Typography>
      <Box className="team-page-form-container">
        {teamLastFiveMatches.map(match => {
          // Determine which team is our team and which is the opponent
          const isTeam1OurTeam = match.team1_name === team_name_short;
          const opponent = isTeam1OurTeam ? match.team2_name : match.team1_name;
          const score = isTeam1OurTeam ? match.team1_score : match.team2_score;
          const opponentScore = isTeam1OurTeam ? match.team2_score : match.team1_score;

          return (
            <MatchResult 
              key={match.match_id} 
              opponent={opponent} 
              score={score} 
              opponentScore={opponentScore} 
              ourTeamWin={match.our_team_win} 
            />
          );
        })}
      </Box>
    </Box>
  );
};

export default TeamPageOverviewFormBox;
