import React from 'react';
import { Box } from '@mui/material';
import FieldImage from './FieldImage'; // Import the FieldImage component
import ListViewLineup from './ListViewLineup'; // Import the ListViewLineup component
import { usePostMatchDataContext } from '../../../hooks/PostMatchDataContext';
import { usePreMatchDataContext } from '../../../hooks/PreMatchDataContext';
import './FieldViewLineup.css';

const FieldViewLineup = ({ isPostMatch }) => {

  const postMatchData = usePostMatchDataContext();
  const preMatchData = usePreMatchDataContext();
  
  const data = isPostMatch ? postMatchData : preMatchData;

  if (!data || !data.matchLineup) {
    return <div>Loading...</div>; // Or handle this case differently
  }

  const { team_1: team1Lineup, team_2: team2Lineup } = data.matchLineup;

  return (
    <Box className="field-view-lineup-container">
      <FieldImage className="field-view-lineup-image-stage" isPostMatch={isPostMatch}/> {/* Render the field image with players on it */}
      <ListViewLineup
        team1Lineup={team1Lineup}
        team2Lineup={team2Lineup}
        isPostMatch={isPostMatch}
        includeMainSections={false} // Exclude backs and forwards in field view
      />
    </Box>
  );
};

export default FieldViewLineup;
