import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import config from '../config';

const fetchSmallLadderData = async () => {
  const response = await axios.get(`${config.backendUrl}/ladder?season=2024`);
  const rounds = Object.keys(response.data).map(Number);
  const latestRound = Math.max(...rounds);
  return response.data[latestRound];
};

export const useSmallLadderData = () => {
  return useQuery({
    queryKey: ['smallLadderData2024'],
    queryFn: fetchSmallLadderData,
    staleTime: 15 * 60 * 1000, // 10 minutes
  });
};