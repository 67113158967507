import React from "react";
import { Box, Typography } from "@mui/material";
import MatchLineUpSection from "../../PostMatchComponents/MatchLineupSection/MatchLineupSection";
import { usePreMatchDataContext } from "../../../hooks/PreMatchDataContext";
import "./PreMatchPreviewSection.css";

const PreMatchPreviewSection = () => {
  const { matchBasicInfo } = usePreMatchDataContext();

  const renderAnalysisContent = () => {
    if (!matchBasicInfo || !matchBasicInfo.prematchAnalysis) {
      return (
        <Typography variant="body1" className="prematch-preview-text">
          Our pre-game analysis comes out on Tuesday night. Check back for our tips and analysis
        </Typography>
      );
    }

    const parts = matchBasicInfo.prematchAnalysis.split('Dotpoint:');
    const mainContent = parts[0].replace('Pregame Analysis:', '').trim();
    const dotpoints = parts.slice(1).map(point => point.trim()).filter(Boolean);

    const paragraphs = mainContent.split('{paragraph}').map((paragraph, index) => (
      <React.Fragment key={index}>
        <Typography variant="body1" className="prematch-preview-text">
          {paragraph.trim()}
        </Typography>
        {index < mainContent.split('{paragraph}').length - 1 && <Box sx={{ height: '1em' }} />}
      </React.Fragment>
    ));

    return (
      <>
        {paragraphs}
        {dotpoints.length > 0 && (
          <ul className="prematch-preview-list">
            {dotpoints.map((point, index) => (
              <li key={index}>{point}</li>
            ))}
          </ul>
        )}
      </>
    );
  };

  return (
    <Box className="prematch-preview-container">
      <Box className="prematch-preview-text-background">
        <Typography className="prematch-preview-title">Pre Game Analysis</Typography>
        {renderAnalysisContent()}
      </Box>
      <MatchLineUpSection isPostMatch={false}/>
    </Box>
  );
};

export default PreMatchPreviewSection;