import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography
} from '@mui/material';
import './FullLadder.css';

const FullLadder = ({ ladderData, isLoading }) => {
  const getImagePath = (teamName) => {
    try {
      return require(`./../../../assets/team_logos/${teamName.toLowerCase().replace(/\s/g, '-')}.svg`);
    } catch (error) {
      return require(`./../../../assets/team_logos/bye.png`);
    }
  };

  const handleRowClick = (teamName) => {
    const teamUrl = `/teams/${teamName.toLowerCase().replace(/\s/g, '-')}`;
    window.location.href = teamUrl;
  };

  if (isLoading) {
    return (
      <div className="full-ladder-ladder-container">
        <Typography variant="h6" className="h1-text">NRL Ladder</Typography>
        <Typography>Loading ladder data...</Typography>
      </div>
    );
  }

  return (
    <div>
      <TableContainer component={Paper} className="full-ladder-ladder-container">
        <Table aria-label="fullLadder" className="full-ladder-custom-table">
          <TableHead>
            <TableRow>
              <TableCell className="full-ladder-table-head-cell">#</TableCell>
              <TableCell className="full-ladder-table-head-cell"></TableCell>
              <TableCell className="full-ladder-table-head-cell">Points</TableCell>
              <TableCell className="full-ladder-table-head-cell">Played</TableCell>
              <TableCell className="full-ladder-table-head-cell">Wins</TableCell>
              <TableCell className="full-ladder-table-head-cell">Losses</TableCell>
              <TableCell className="full-ladder-table-head-cell">+/-</TableCell>
              <TableCell className="full-ladder-table-head-cell">Next</TableCell>
              <TableCell className="full-ladder-table-head-cell">D</TableCell>
              <TableCell className="full-ladder-table-head-cell">B</TableCell>
              <TableCell className="full-ladder-table-head-cell">For</TableCell>
              <TableCell className="full-ladder-table-head-cell">Against</TableCell>
              <TableCell className="full-ladder-table-head-cell">Home</TableCell>
              <TableCell className="full-ladder-table-head-cell">Away</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {ladderData.map((team, index) => (
              <TableRow 
                key={index} 
                className="full-ladder-table-row"
                onClick={() => handleRowClick(team.team)} // Make the row clickable
                style={{ cursor: 'pointer' }} // Change cursor to pointer to indicate clickable row
              >
                <TableCell className="full-ladder-table-body-cell">{team.position}</TableCell>
                <TableCell className={`full-ladder-table-body-cell ${index < 8 ? 'top-8' : ''}`}>
                  <div className="full-ladder-team-cell">
                    <img src={getImagePath(team.team)} alt={team.team} className="full-ladder-team-logo" id="full-ladder-team-logo-main" />
                    <Typography className="full-ladder-team-name">
                      <a href={`/teams/${team.team.toLowerCase().replace(/\s/g, '-')}`} className="full-ladder-team-link">{team.team}</a>
                    </Typography>
                  </div>
                </TableCell>
                <TableCell className="full-ladder-table-body-cell pts-cell">{team.points}</TableCell>
                <TableCell className="full-ladder-table-body-cell">{team.played}</TableCell>
                <TableCell className="full-ladder-table-body-cell">{team.wins}</TableCell>
                <TableCell className="full-ladder-table-body-cell">{team.lost}</TableCell>
                <TableCell className="full-ladder-table-body-cell">{team.diff}</TableCell>
                <TableCell className="full-ladder-table-body-cell">
                  <div className="full-ladder-next-team-cell">
                    <img src={getImagePath(team.next_match)} alt={team.next_match} className="full-ladder-team-logo" />
                  </div>
                </TableCell>
                <TableCell className="full-ladder-table-body-cell">{team.drawn}</TableCell>
                <TableCell className="full-ladder-table-body-cell">{team.byes}</TableCell>
                <TableCell className="full-ladder-table-body-cell">{team.fore}</TableCell>
                <TableCell className="full-ladder-table-body-cell">{team.against}</TableCell>
                <TableCell className="full-ladder-table-body-cell">{team.home}</TableCell>
                <TableCell className="full-ladder-table-body-cell">{team.away}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default FullLadder;
