import React, { useState } from "react";
import { Tooltip, ClickAwayListener } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const InfoTooltip = ({ title }) => {
  const [openTooltip, setOpenTooltip] = useState(false);

  const handleTooltipClose = () => {
    setOpenTooltip(false);
  };

  const handleTooltipOpen = () => {
    setOpenTooltip(true);
  };

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <Tooltip
        open={openTooltip}
        onClose={handleTooltipClose}
        onOpen={handleTooltipOpen}
        disableHoverListener
        title={title}
        placement="top-start"
        arrow
      >
        <InfoOutlinedIcon
          className="info-icon"
          onClick={handleTooltipOpen}
        />
      </Tooltip>
    </ClickAwayListener>
  );
};

export default InfoTooltip;
