// hooks/PreMatchDataContext.js
import React, { createContext, useContext } from 'react';

const PreMatchDataContext = createContext(null);

export const usePreMatchDataContext = () => {
  return useContext(PreMatchDataContext);
};

export const PreMatchDataProvider = ({ value, children }) => {
  return (
    <PreMatchDataContext.Provider value={value}>
      {children}
    </PreMatchDataContext.Provider>
  );
};
