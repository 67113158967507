import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import config from '../config';

const fetchLadderData = async (season) => {
  const response = await axios.get(`${config.backendUrl}/ladder?season=${season}`);
  return response.data;
};

export const useLadderData = (season) => {
  return useQuery({
    queryKey: ['ladderData', season],
    queryFn: () => fetchLadderData(season),
    staleTime: 15 * 60 * 1000, // 10 minutes
  });
};