import React from "react";
import { Box, Typography } from "@mui/material";
import { usePreMatchDataContext } from '../../../hooks/PreMatchDataContext';
import "./PostMatchFormLast5Matches.css";

const getImagePath = (imageName) => {
  try {
    return require(`./../../../assets/team_logos/${imageName.toLowerCase().replace(/\s/g, '-')}.svg`);
  } catch (error) {
    return require(`./../../../assets/lineupPlayer.png`);
  }
};

const getScoreColor = (teamScore, opponentScore) => {
  if (teamScore > opponentScore) {
    return "#15D56D";
  } else if (teamScore < opponentScore) {
    return "#FF685F";
  } else {
    return "#F39912";
  }
};

const MatchCard = ({ teamName, match }) => {
  const scoreColor = getScoreColor(match.score, match.opponentScore);
  return (
    <Box className="postmatch-last5games-row">
      <Typography className="postmatch-last5games-team-name">{teamName}</Typography>
      <img
        src={getImagePath(teamName)}
        alt={teamName}
        className="postmatch-last5games-team-logo"
        id="postmatch-last5games-team-logo1"
      />
      <Box className="postmatch-last5games-match-score" style={{ backgroundColor: scoreColor }}>
        {`${match.score} : ${match.opponentScore}`}
      </Box>
      <img
        src={getImagePath(match.opponent)}
        alt={match.opponent}
        className="postmatch-last5games-team-logo"
        id="postmatch-last5games-team-logo2"
      />
      <Typography className="postmatch-last5games-team-name opponent-name">
        {match.opponent}
      </Typography>
    </Box>
  );
};


const PostMatchFormLast5Matches = () => {
  const data = usePreMatchDataContext();
  const { matchForm } = data;

  if (!matchForm || !matchForm.lastFive) return null;

  const { team1, team2 } = matchForm.lastFive;

  return (
    <Box className="postmatch-last5games">
      <Box className="postmatch-last5games-column">
        {team1.lastFiveMatches.map((match, index) => (
          <MatchCard key={`team1-${index}`} teamName={team1.name} match={match} />
        ))}
      </Box>
      <Box className="postmatch-last5games-column">
        {team2.lastFiveMatches.map((match, index) => (
          <MatchCard key={`team2-${index}`} teamName={team2.name} match={match} />
        ))}
      </Box>
    </Box>
  );
};

export default PostMatchFormLast5Matches;