// CustomDropdown.js
import React from 'react';
import { Select, MenuItem } from '@mui/material';
import './CustomDropdown.css';

const CustomDropdown = ({ tabs, activeTab, onTabClick }) => {
  return (
    <Select
      value={activeTab}
      onChange={(e) => onTabClick(e.target.value)}
      className="custom-dropdown"
    >
      {tabs.map((tab, index) => (
        <MenuItem key={index} value={index}>
          {tab}
        </MenuItem>
      ))}
    </Select>
  );
};

export default CustomDropdown;
