import React, { useMemo, useState, useEffect } from 'react';
import { Box, Typography, Card, CardContent } from '@mui/material';
import { useNavigate, Link } from 'react-router-dom';
import MatchesTableSearchBar from './MatchesTableSearchBar';
import { useSeasonMatchData } from '../../../hooks/useSeasonMatchData';
import teamsList from '../../../constants/teamsList.json';
import './MatchesTable.css';
import MatchesTableLoadingSkeleton from '../../MatchPageSharedComponents/MatchesTableLoadingSkeleton';

const getImagePath = (teamName) => {
  try {
    return require(`./../../../assets/team_logos/${teamName.toLowerCase().replace(/\s/g, '-')}.svg`);
  } catch (error) {
    return require(`./../../../assets/team_logos/bye.png`);
  }
};

const determineRound = (currentDate) => {
  if (currentDate >= new Date('2024-08-12T00:00:00') && currentDate <= new Date('2024-08-18T23:59:59')) {
    return 24;
  } else if (currentDate >= new Date('2024-08-19T00:00:00') && currentDate <= new Date('2024-08-25T23:59:59')) {
    return 25;
  } else if (currentDate >= new Date('2024-08-26T00:00:00') && currentDate <= new Date('2024-09-01T23:59:59')) {
    return 26;
  } else if (currentDate >= new Date('2024-09-02T00:00:00') && currentDate <= new Date('2024-09-08T23:59:59')) {
    return 27;
  } else if (currentDate >= new Date('2024-09-09T00:00:00') && currentDate <= new Date('2024-09-15T23:59:59')) {
    return 28; // Finals Week 1
  } else if (currentDate >= new Date('2024-09-16T00:00:00') && currentDate <= new Date('2024-09-22T23:59:59')) {
    return 29; // Finals Week 2
  } else if (currentDate >= new Date('2024-09-23T00:00:00') && currentDate <= new Date('2024-09-29T23:59:59')) {
    return 30; // Preliminary Finals
  } else if (currentDate >= new Date('2024-09-30T00:00:00')) {
    return 31; // Grand Final
  } else {
    return 24; // Default to round 24 if none match
  }
};

const MatchesTable = ({ initialSeason, initialRound, initialTeam }) => {
  const navigate = useNavigate();

  const [selectedSeason, setSelectedSeason] = useState(initialSeason);
  const [selectedRound, setSelectedRound] = useState(initialRound || determineRound(new Date()));
  const [selectedTeam, setSelectedTeam] = useState(initialTeam);
  const { data: matchesData, isLoading, isError } = useSeasonMatchData(selectedSeason);
  useEffect(() => {
    const newSearchParams = new URLSearchParams();
    newSearchParams.set('season', selectedSeason);
    if (selectedTeam) {
      newSearchParams.set('team', selectedTeam);
    } else {
      newSearchParams.set('round', selectedRound);
    }
    navigate(`?${newSearchParams.toString()}`, { replace: true });
  }, [selectedSeason, selectedRound, selectedTeam, navigate]);

  const filteredMatches = useMemo(() => {
    if (!matchesData) return [];
    
    let allMatches = Object.entries(matchesData).flatMap(([round, matches]) => 
      matches.map(match => ({ ...match, round: parseInt(round) }))
    );
    
    if (selectedTeam) {
      return allMatches.filter(match => 
        match.team_1.name === selectedTeam || match.team_2.name === selectedTeam
      );
    } else {
      return matchesData[selectedRound] || [];
    }
  }, [matchesData, selectedRound, selectedTeam]);

  const groupedMatches = useMemo(() => {
    return filteredMatches.reduce((acc, match) => {
      const key = selectedTeam ? match.round : (new Date(match.datetime)).toISOString().split('T')[0];
      if (!acc[key]) acc[key] = [];
      acc[key].push(match);
      return acc;
    }, {});
  }, [filteredMatches, selectedTeam]);

  const formatDate = (dateString) => {
    const options = { weekday: 'long', day: 'numeric', month: 'long', year: 'numeric' };
    const date = new Date(dateString);
    return date.toLocaleDateString('en-GB', options);
  };

  const formatTime = (datetime) => {
    const matchDate = new Date(datetime);
    return matchDate.toLocaleTimeString('en-GB', {
      hour: 'numeric',
      minute: '2-digit',
      hour12: true,
      timeZone: 'UTC'
    });
  };

  const handleTeamChange = (team) => {
    setSelectedTeam(team);
    if (team) {
      setSelectedRound(null);
    } else {
      setSelectedRound(determineRound(new Date()));
    }
  };

  const handleRoundChange = (round) => {
    setSelectedRound(round);
    setSelectedTeam('');
  };

  const handleSeasonChange = (season) => {
    setSelectedSeason(season);
    if (!selectedTeam) {
      setSelectedRound(determineRound(new Date()));
    }
  };

  if (isLoading) {
    return <MatchesTableLoadingSkeleton />;
  }

  if (isError) {
    return <Typography className="internal-error-msg">Oops temporary bug being fixed, refresh few times for page to load again.</Typography>;
  }

  return (
    <div className="matches-table">
      <MatchesTableSearchBar 
        selectedSeason={selectedSeason} 
        onSeasonChange={handleSeasonChange}
        selectedRound={selectedRound}
        onRoundChange={handleRoundChange}
        selectedTeam={selectedTeam}
        onTeamChange={handleTeamChange}
        teamsList={teamsList.teamsList}
        totalRounds={matchesData ? Object.keys(matchesData).length : 0}
      />
      {Object.entries(groupedMatches).map(([key, matches]) => {
        const headerText = selectedTeam ? `Round ${key}` : formatDate(key);

        return (
          <Card className="matches-in-day-card" key={key}>
            <Typography variant="h6" className="match-date matches-table-header-text">
              {headerText}
            </Typography>
            {matches.map((match) => {
              const isUpcoming = match.channel9 !== null;
              const route = isUpcoming ? `/pre?matchid=${match.match_id}` : `/post?matchid=${match.match_id}`;

              return (
                <Link
                  key={match.match_id}
                  to={route}
                  className="match-wrapper"
                >
                  <CardContent className="match-content">
                    {match.channel9 && (
                      <Typography className="ch9-tag">
                        CH9
                      </Typography>
                    )}
                    <Box className="team-info align-right">
                      <Typography variant="h6" className="team-name">
                        {match.team_1.name}
                      </Typography>
                      <img
                        src={getImagePath(match.team_1.name)}
                        alt={match.team_1.name}
                        className="team-logo"
                        id="match-table-team1-logo"
                      />
                    </Box>
                    {isUpcoming ? (
                      <Typography variant="h6" className="match-time">
                        {formatTime(match.datetime)}
                      </Typography>
                    ) : (
                      <Typography variant="h6" className="match-score">
                        {match.team_1.score} - {match.team_2.score}
                      </Typography>
                    )}
                    <Box className="team-info align-left">
                      <img
                        src={getImagePath(match.team_2.name)}
                        alt={match.team_2.name}
                        className="team-logo"
                        id="match-table-team2-logo"
                      />
                      <Typography variant="h6" className="team-name">
                        {match.team_2.name}
                      </Typography>
                    </Box>
                  </CardContent>
                  <Box className="matches-table-location-box">
                    <Typography className="matches-table-match-location">
                      {match.location}
                    </Typography>
                  </Box>
                </Link>
              );
            })}
          </Card>
        );
      })}
    </div>
  );
};

export default MatchesTable;