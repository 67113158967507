import React from 'react';
import { Box, Card, CardContent, Skeleton } from '@mui/material';

const MatchesTableLoadingSkeleton = () => {
  const waveColor = 'rgba(64, 63, 63, 0.050)';
  const backgroundGradient = `linear-gradient(90deg, transparent, ${waveColor}, transparent, ${waveColor}, transparent)`; 
  const animationDuration = '4.2s';

  const SkeletonStyles = {
    bgcolor: 'rgba(0, 0, 0, 0.05)',
    '&::after': {
      animationDuration: animationDuration,
      animationTimingFunction: 'ease-in-out',
      background: backgroundGradient,
    }
  };

  return (
    <div className="matches-table">
      {/* MatchesTableSearchBar skeleton */}
      <Box sx={{ height: '50px', mb: 2 }}>
        <Skeleton variant="rectangular" width="100%" height={50} sx={{ ...SkeletonStyles, borderRadius: '16px' }} animation="wave" />
      </Box>

      {/* Matches skeleton */}
      {[...Array(3)].map((_, dayIndex) => (
        <Card className="matches-in-day-card" key={dayIndex} sx={{ mb: 2, borderRadius: '16px' }}>
          <Skeleton variant="text" width="40%" height={32} sx={{ ...SkeletonStyles, borderRadius: '16px 16px 0 0' }} animation="wave" />
          
          {[...Array(3)].map((_, matchIndex) => (
            <Box key={matchIndex} className="match-wrapper" sx={{ my: 2 }}>
              <CardContent className="match-content">
                <Box className="team-info align-right" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                  <Skeleton variant="text" width={100} height={24} sx={SkeletonStyles} animation="wave" />
                  <Skeleton variant="circular" width={40} height={40} sx={{ ...SkeletonStyles, ml: 1 }} animation="wave" />
                </Box>
                <Skeleton variant="text" width={60} height={24} sx={{ ...SkeletonStyles, mx: 2 }} animation="wave" />
                <Box className="team-info align-left" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                  <Skeleton variant="circular" width={40} height={40} sx={{ ...SkeletonStyles, mr: 1 }} animation="wave" />
                  <Skeleton variant="text" width={100} height={24} sx={SkeletonStyles} animation="wave" />
                </Box>
              </CardContent>
              <Box className="matches-table-location-box">
                <Skeleton variant="text" width="60%" height={20} sx={SkeletonStyles} animation="wave" />
              </Box>
            </Box>
          ))}
        </Card>
      ))}
    </div>
  );
};

export default MatchesTableLoadingSkeleton;
