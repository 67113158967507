// components/InjuriesBanner/InjuriesBanner.js
import React from 'react';
import { Box, Typography } from '@mui/material';
import './InjuriesBanner.css';

const InjuriesBanner = ({ teamLogo }) => {
  return (
    <Box className="injuries-banner">
      <Box className="banner-team-info">
        <Box className="banner-team-details">
          <Typography variant="h4" className="banner-team-name">Injuries Centre</Typography>
          <Typography variant="body1" className="banner-team-subtitle">
          Check out who’s making their way back to their field
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default InjuriesBanner;
