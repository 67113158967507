import React, { useState, useRef, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { usePreMatchDataContext } from "../../../hooks/PreMatchDataContext";
import ShowMoreButton from "../../SharedComponents/ShowMoreButton/ShowMoreButton";
import "./HTFT.css";

const getImagePath = (teamName) => {
  try {
    return require(`./../../../assets/team_logos/${teamName
      .toLowerCase()
      .replace(/\s/g, "-")}.svg`);
  } catch (error) {
    return require(`./../../../assets/team_logos/bye.png`);
  }
};

const ScoreDifferenceBox = ({ value }) => {
  const absValue = Math.abs(value);
  const className = value < 0 ? "htft-red-box" : value > 0 ? "htft-green-box" : "htft-grey-box";
  return (
    <Box className={`htft-score-box ${className}`}>
      <Typography>{absValue}</Typography>
    </Box>
  );
};

const TeamColumn = ({ teamData, title, showAll }) => {
  const contentRef = useRef(null);
  const [contentHeight, setContentHeight] = useState(0);

  useEffect(() => {
    if (showAll) {
      setContentHeight(contentRef.current.scrollHeight);
    } else {
      setContentHeight(0);
    }
  }, [showAll]);

  const initialData = teamData.slice(0, 7); // The first 7 items
  const remainingData = teamData.slice(7); // The remaining items

  return (
    <Box className="htft-team-column">
      <Typography className="htft-h2-text">{title}</Typography>
      {/* Render the first 7 items */}
      {initialData.map((game, index) => (
        <Box key={index} className="htft-game-row">
          <Typography className="htft-opponent-name" align="right">
            {game.opponentName}
          </Typography>
          <Box
            component="img"
            src={getImagePath(game.opponentName)}
            alt={`${game.opponentName} logo`}
            className="htft-team-logo"
          />
          <ScoreDifferenceBox value={game.first_half_score_difference} />
          <ScoreDifferenceBox value={game.second_half_score_difference} />
        </Box>
      ))}
      {/* Collapsible section for the remaining items */}
      <Box
        className={`collapsible-content ${showAll ? 'expanded' : 'collapsed'}`}
        ref={contentRef}
        sx={{ maxHeight: `${contentHeight}px` }}
      >
        {remainingData.map((game, index) => (
          <Box key={index} className="htft-game-row">
            <Typography className="htft-opponent-name" align="right">
              {game.opponentName}
            </Typography>
            <Box
              component="img"
              src={getImagePath(game.opponentName)}
              alt={`${game.opponentName} logo`}
              className="htft-team-logo"
            />
            <ScoreDifferenceBox value={game.first_half_score_difference} />
            <ScoreDifferenceBox value={game.second_half_score_difference} />
          </Box>
        ))}
      </Box>
    </Box>
  );
};

const HTFT = () => {
  const { bettingTeams, bettingBWLW } = usePreMatchDataContext();
  const team1Name = bettingBWLW.team1.teamName;
  const team2Name = bettingBWLW.team2.teamName;
  const [showAll, setShowAll] = useState(false);

  const toggleShowAll = () => {
    setShowAll(!showAll);
  };

  return (
    <Box className="htft-container">
      <TeamColumn
        teamData={bettingTeams.team1.HTFT}
        title={`HT/FT - ${team1Name}`}
        showAll={showAll}
      />
      <TeamColumn
        teamData={bettingTeams.team2.HTFT}
        title={`HT/FT - ${team2Name}`}
        showAll={showAll}
      />
      <ShowMoreButton 
        showAll={showAll} 
        onClick={toggleShowAll} 
      />
    </Box>
  );
};

export default HTFT;
