import React from "react";
import { Box, Typography } from "@mui/material";
import { useUpcomingMatchData } from "../../../hooks/useUpcomingMatchData";
import { useTeamColors } from "../../../hooks/TeamColorsContext";
import UpcomingMatchBannerSkeleton from "../../MatchPageSharedComponents/UpcomingMatchBannerSkeleton";
import { useNavigate } from "react-router-dom";
import "./UpcomingMatchBanner.css";

const getImagePath = (teamName) => {
  try {
    return require(`./../../../assets/team_logos/${teamName
      .toLowerCase()
      .replace(/\s/g, "-")}.svg`);
  } catch (error) {
    return require(`./../../../assets/team_logos/bye.png`);
  }
};

const UpcomingMatchBanner = () => {
  const { data: matchDetails, isLoading, isError } = useUpcomingMatchData();
  const teamColors = useTeamColors().teamColours;
  const navigate = useNavigate();

  if (isLoading) {
    return <UpcomingMatchBannerSkeleton />;
  }

  // Utility function to convert hex to RGB
  const hexToRgb = (hex) => {
    const bigint = parseInt(hex.slice(1), 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;
    return [r, g, b];
  };

  // Utility function to convert RGB to hex
  const rgbToHex = (r, g, b) => {
    const toHex = (value) => {
      const hex = value.toString(16);
      return hex.length === 1 ? "0" + hex : hex;
    };
    return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
  };

  // Function to calculate the middle color
  const calculateMiddleColor = (color1, color2) => {
    const [r1, g1, b1] = hexToRgb(color1);
    const [r2, g2, b2] = hexToRgb(color2);
    const middleR = Math.round((r1 + r2) / 2);
    const middleG = Math.round((g1 + g2) / 2);
    const middleB = Math.round((b1 + b2) / 2);
    return rgbToHex(middleR, middleG, middleB);
  };

  if (isError || !matchDetails) {
    return (
      <div className="matches-table">
        <Box className="upcoming-banner-container">
          <Typography>Error loading upcoming match data</Typography>
        </Box>
      </div>
    );
  }

  const handleClick = () => {
    navigate(`/pre?matchid=${matchDetails.match_id}`);
  };

  const matchDate = new Date(matchDetails.datetime);

  const formattedDate = matchDate.toLocaleDateString("en-GB", {
    weekday: "long",
    day: "numeric",
    month: "long",
    year: "numeric",
    timeZone: "UTC",
  });

  const formattedTime = matchDate.toLocaleTimeString("en-GB", {
    hour: "numeric",
    minute: "2-digit",
    hour12: true,
    timeZone: "UTC",
  });

  const team1Color =
    teamColors.find((team) => team.team_name_short === matchDetails.team_1.name)
      ?.colour || "#4F83E6";
  const team2Color =
    teamColors.find((team) => team.team_name_short === matchDetails.team_2.name)
      ?.colour || "#C52F2F";
  const middleColor = calculateMiddleColor(team1Color, team2Color);

  const gradientStyle = {
    background: `linear-gradient(to right, ${team1Color} 10%, ${middleColor} 50%, ${team2Color} 90%)`,
    cursor: "pointer" // Changes cursor to pointer on hover
  };

  return (
    <div className="upcoming-banner-wrapper">
      <Box
        className="upcoming-banner-container"
        style={gradientStyle}
        onClick={handleClick} // Makes the banner clickable
      >
        <Typography className="upcoming-banner-title">
          Upcoming Match - Round {matchDetails.round}
        </Typography>
        <Typography className="upcoming-banner-details">
          {formattedDate}
        </Typography>
        <Typography className="upcoming-banner-details">
          {matchDetails.location}
        </Typography>
        <Box className="upcoming-banner-teams-wrapper">
          <Box className="upcoming-banner-teams-info">
            <Box className="upcoming-banner-team">
              <Typography>{matchDetails.team_1.name}</Typography>
              <img
                src={getImagePath(matchDetails.team_1.name)}
                alt={matchDetails.team_1.name}
                className="upcoming-banner-team-logo"
                id="upcoming-banner-team-logo-team1"
              />
            </Box>
            <Typography className="upcoming-banner-time">
              {formattedTime}
            </Typography>
            <Box className="upcoming-banner-team">
              <img
                src={getImagePath(matchDetails.team_2.name)}
                alt={matchDetails.team_2.name}
                className="upcoming-banner-team-logo"
                id="upcoming-banner-team-logo-team2"
              />
              <Typography>{matchDetails.team_2.name}</Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default UpcomingMatchBanner;
