import React from 'react';
import { Box, Typography } from '@mui/material';
import errorSvg from "./../../../assets/error.svg";
import './ErrorMessage.css';

const ErrorMessage = () => {
  return (
    <Box className="error-container">
      <img 
        src={errorSvg} 
        alt="Error" 
        style={{ width: '300px', height: '300px', marginBottom: '20px', marginTop: '150px' }} // Inline styles for the SVG
      />
      <Typography className="internal-error-msg">
        Oops, temporary bug being fixed <br />please refresh page until working again!
      </Typography>
    </Box>
  );
};

export default ErrorMessage;
