export const COLUMNS = {
    'Top Stats': [
      { label: 'Points', field: 'points' },
      { label: 'Minutes Played', field: 'mins_played' },
      { label: 'Tries', field: 'tries' },
      { label: 'Try Assists', field: 'try_assists' },
      { label: 'Line Breaks', field: 'line_breaks' },
      { label: 'Tackles Made', field: 'tackles_made' },
      { label: 'Run Metres', field: 'all_run_metres' },
      { label: 'Fantasy Points', field: 'fantasy_points' },
    ],
    'Scoring': [
      { label: 'Points', field: 'points' },
      { label: 'Tries', field: 'tries' },
      { label: 'Try Assists', field: 'try_assists' },
      { label: 'Conversions', field: 'conversions' },
      { label: 'Penalty Goals', field: 'penalty_goals' },
      { label: 'Goal Conversion Rate', field: 'goal_conversion_rate' },
      { label: '1 Point FG', field: 'one_point_field_goals' },
      { label: '2 Point FG', field: 'two_point_field_goals' },
    ],
    'Running Attack': [
      { label: 'All Runs', field: 'all_runs' },
      { label: 'Run Metres', field: 'all_run_metres' },
      { label: 'Line Breaks', field: 'line_breaks' },
      { label: 'Kick Return Metres', field: 'kick_return_metres' },
      { label: 'Post Contact Metres', field: 'post_contact_metres' },
      { label: 'Tackle Breaks', field: 'tackle_breaks' },
      { label: 'Hit Ups', field: 'hit_ups' },
      { label: 'Avg Play The Ball Speed', field: 'avg_play_the_ball_speed' },
    ],
    'Passing': [
      { label: 'Offloads', field: 'offloads' },
      { label: 'Passes', field: 'passes' },
      { label: 'Dummy Passes', field: 'dummy_passes' },
      { label: 'Receipts', field: 'receipts' },
    ],
    'Defence': [
      { label: 'Tackles Made', field: 'tackles_made' },
      { label: 'Missed Tackles', field: 'missed_tackles' },
      { label: 'Ineffective Tackles', field: 'ineffective_tackles' },
      { label: 'Tackle Efficiency', field: 'tackle_efficiency' },
      { label: 'Intercepts', field: 'intercepts' },
      { label: 'One on One Steal', field: 'one_on_one_steal' },
      { label: 'Kicks Defused', field: 'kicks_defused' },
    ],
    'Kicking': [
      { label: 'Kicks', field: 'kicks' },
      { label: 'Kicking Metres', field: 'kicking_metres' },
      { label: 'Kicked Dead', field: 'kicked_dead' },
      { label: 'Forced Drop Outs', field: 'forced_drop_outs' },
      { label: 'Bomb Kicks', field: 'bomb_kicks' },
      { label: 'Grubbers', field: 'grubbers' },
      { label: 'Forty Twenty', field: 'forty_twenty' },
      { label: 'Cross Field Kicks', field: 'cross_field_kicks' },
    ],
    'Discipline': [
      { label: 'Errors', field: 'errors' },
      { label: 'Penalties', field: 'penalties' },
      { label: 'One On One Lost', field: 'one_on_one_lost' },
      { label: 'Ruck Infringements', field: 'ruck_infringements' },
      { label: 'Inside 10 Metres', field: 'inside_10_metres' },
      { label: 'On Report', field: 'on_report' },
      { label: 'Sin Bins', field: 'sin_bins' },
      { label: 'Send Offs', field: 'send_offs' },
    ],
  };
  