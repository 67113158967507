import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import "./TeamsPageScoringMap.css";
import { useTeamDataContext } from "../../../hooks/TeamDataContext";

const getImagePath = (imageName) => {
  try {
    return require(`./../../../assets/${imageName}.png`);
  } catch (error) {
    return require(`./../../../assets/lineupPlayer.png`);
  }
};

const roundWithSuffix = (value) => {
  const roundedValue = Math.round(value);
  const lastDigit = roundedValue % 10;
  const lastTwoDigits = roundedValue % 100;
  if (lastTwoDigits >= 11 && lastTwoDigits <= 13) {
    return `${roundedValue}th`;
  }
  switch (lastDigit) {
    case 1:
      return `${roundedValue}st`;
    case 2:
      return `${roundedValue}nd`;
    case 3:
      return `${roundedValue}rd`;
    default:
      return `${roundedValue}th`;
  }
};

const calculateHeight = (rank) => {
  const maxRank = 17; // Assuming 16 teams in the league, max rank is 17
  const minHeight = 20;
  const maxHeight = 100;
  return `${
    maxHeight - ((rank - 1) * (maxHeight - minHeight)) / (maxRank - 1)
  }%`;
};

const ScoringMapToggle = ({ options, value, onChange }) => {
  return (
    <Box className="scoring-map-toggle">
      <Box className="scoring-map-toggle-options">
        <Typography className="scoring-map-toggle-text">View</Typography>
        {options.map((option) => (
          <Box
            key={option}
            className={`scoring-map-toggle-option ${
              value === option ? "selected" : ""
            }`}
            onClick={() => onChange(option)}
          >
            {option}
          </Box>
        ))}
      </Box>
    </Box>
  );
};

const TeamsPageScoringMap = () => {
  const [selectedSection, setSelectedSection] = useState("Right");
  const [viewMode, setViewMode] = useState("Attacking");
  const teamData = useTeamDataContext();

  if (!teamData || !teamData.teamStrengthMap) return null;

  const { teamStrengthMap } = teamData;

  const handleSectionClick = (section) => {
    setSelectedSection(section);
  };

  const handleViewModeChange = (newMode) => {
    setViewMode(newMode);
  };

  const getSectionData = (section, mode) => {
    if (mode === "Attacking") {
      switch (section) {
        case "Right":
          return teamStrengthMap.attacking.rightSide;
        case "Center":
          return teamStrengthMap.attacking.middle;
        case "Left":
          return teamStrengthMap.attacking.leftSide;
        default:
          return teamStrengthMap.attacking.middle;
      }
    } else {
      // Swap left and right for defense
      switch (section) {
        case "Right":
          return teamStrengthMap.defending.leftSide; // Defending right side is opponent's left attack
        case "Center":
          return teamStrengthMap.defending.middle;
        case "Left":
          return teamStrengthMap.defending.rightSide; // Defending left side is opponent's right attack
        default:
          return teamStrengthMap.defending.middle;
      }
    }
  };

  const sectionData = getSectionData(selectedSection, viewMode);

  // Sort tryscorers by tries scored (descending) and then by percentage (descending)
  const sortedTryScorers = sectionData.tryScorers
    ? [...sectionData.tryScorers].sort((a, b) => {
        if (b.scored !== a.scored) {
          return b.scored - a.scored;
        }
        return b.percentageScoredHere - a.percentageScoredHere;
      })
    : [];

  const renderBar = (section, mode, position) => {
    const data =
      mode === "Attacking"
        ? teamStrengthMap.attacking
        : teamStrengthMap.defending;
    let side;

    if (mode === "Attacking") {
      side = section === "Center" ? "middle" : section.toLowerCase() + "Side";
    } else {
      if (section === "Left") {
        side = "rightSide";
      } else if (section === "Right") {
        side = "leftSide";
      } else {
        side = "middle";
      }
    }

    const rank = data[side].rank;
    const activeColor = mode === "Attacking" ? "#15D56D" : "#FF685F";
    const inactiveColor = "#CCCCCC"; // Light grey color for inactive state
    const isActive = viewMode === mode;

    return (
      <Box
        className={`scoring-map-bar ${mode.toLowerCase()} ${position}`}
        style={{
          height: calculateHeight(rank),
          backgroundColor: isActive ? activeColor : inactiveColor,
        }}
      >
        <Typography
          className={`scoring-map-bar-rank ${
            position === "top" ? "flex-end" : "flex-start"
          }`}
        >
          {roundWithSuffix(rank)}
        </Typography>
      </Box>
    );
  };

  return (
    <Box className="scoring-map-container">
      <Typography className="subheader-text h1-text-team-overview">
        Scoring Map
      </Typography>
      <ScoringMapToggle
        options={["Attacking", "Defending"]}
        value={viewMode}
        onChange={handleViewModeChange}
      />

      <Box className="scoring-map-content">
        <Box className="scoring-map-visualization">
          <Box className="scoring-map-column">
            <Typography className="scoring-map-diagram-header">
              Right
            </Typography>
            <Box
              className={`scoring-map-row top ${
                selectedSection === "Right" ? "selected" : ""
              }`}
              onClick={() => handleSectionClick("Right")}
            >
              {renderBar("Right", "Attacking", "top")}
            </Box>
            <Box
              className={`scoring-map-row bottom ${
                selectedSection === "Right" ? "selected" : ""
              }`}
              onClick={() => handleSectionClick("Right")}
            >
              {renderBar("Right", "Defending", "bottom")}
            </Box>
            <Typography className="scoring-map-diagram-header">Left</Typography>
          </Box>
          <Box className="scoring-map-column">
            <Typography className="scoring-map-diagram-header">
              Attacking
            </Typography>
            <Box
              className={`scoring-map-row top ${
                selectedSection === "Center" ? "selected" : ""
              }`}
              onClick={() => handleSectionClick("Center")}
            >
              {renderBar("Center", "Attacking", "top")}
            </Box>
            <Box
              className={`scoring-map-row bottom ${
                selectedSection === "Center" ? "selected" : ""
              }`}
              onClick={() => handleSectionClick("Center")}
            >
              {renderBar("Center", "Defending", "bottom")}
            </Box>
            <Typography className="scoring-map-diagram-header">
              Defending
            </Typography>
          </Box>
          <Box className="scoring-map-column">
            <Typography className="scoring-map-diagram-header">Left</Typography>
            <Box
              className={`scoring-map-row top ${
                selectedSection === "Left" ? "selected" : ""
              }`}
              onClick={() => handleSectionClick("Left")}
            >
              {renderBar("Left", "Attacking", "top")}
            </Box>
            <Box
              className={`scoring-map-row bottom ${
                selectedSection === "Left" ? "selected" : ""
              }`}
              onClick={() => handleSectionClick("Left")}
            >
              {renderBar("Left", "Defending", "bottom")}
            </Box>
            <Typography className="scoring-map-diagram-header">
              Right
            </Typography>
          </Box>
        </Box>

        <Box className="scoring-map-details">
          <Box className="scoring-map-section">
            <Box className="scoring-map-header">
              <span className="dot green-dot"></span>
              <Typography className="scoring-map-section-title">
                {teamStrengthMap.teamName} Attack
              </Typography>
            </Box>
            <Box className="row">
              <Box className="column">
                <Typography className="title">Rank</Typography>
                <Typography className="scoring-map-bigger-stat">
                  {roundWithSuffix(teamStrengthMap.attacking.overall.rank)}
                </Typography>
              </Box>
              <Box className="column" id="tries-column">
                <Typography className="title">Tries</Typography>
                <Typography className="title scoring-map-column-gap">
                  Tries Per Game
                </Typography>
              </Box>
              <Box className="column">
                <Typography className="value">
                  {Math.round(teamStrengthMap.attacking.overall.tries)}
                </Typography>
                <Typography className="value scoring-map-column-gap">
                  {Number(
                    teamStrengthMap.attacking.overall.triesPerGame
                  ).toFixed(2)}
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box className="scoring-map-section">
            <Box className="scoring-map-header">
              <span className="dot red-dot"></span>
              <Typography className="scoring-map-section-title">
                {teamStrengthMap.teamName} Defence
              </Typography>
            </Box>
            <Box className="row">
              <Box className="column">
                <Typography className="title">Rank</Typography>
                <Typography className="scoring-map-bigger-stat">
                  {roundWithSuffix(teamStrengthMap.defending.overall.rank)}
                </Typography>
              </Box>
              <Box className="column" id="concede-column">
                <Typography className="title">Conceded</Typography>
                <Typography className="title scoring-map-column-gap">
                  Conceded Per Game
                </Typography>
              </Box>
              <Box className="column">
                <Typography className="value">
                  {Math.round(teamStrengthMap.defending.overall.concededTries)}
                </Typography>
                <Typography className="value scoring-map-column-gap">
                  {Number(
                    teamStrengthMap.defending.overall.concededPerGame
                  ).toFixed(2)}
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box className="scoring-map-section">
            <Typography className="scoring-map-section-title">
              {teamStrengthMap.teamName} /{" "}
              {selectedSection === "Center"
                ? "Center"
                : // Swap the section name for defense
                viewMode === "Defending" && selectedSection === "Right"
                ? "Left Side"
                : viewMode === "Defending" && selectedSection === "Left"
                ? "Right Side"
                : `${selectedSection} Side`}{" "}
              {viewMode === "Attacking" ? "Attack" : "Defence"}
            </Typography>

            <Box className="row">
              <Typography>Rank</Typography>
              <Typography id="scoring-map-bigger-stat-tries">
                {viewMode === "Attacking" ? "Tries" : "Conceded"}
              </Typography>
              {viewMode === "Attacking" && <Typography>% Total</Typography>}
            </Box>
            <Box className="row">
              <Typography className="scoring-map-bigger-stat">
                {roundWithSuffix(sectionData.rank)}
              </Typography>
              <Typography className="scoring-map-bigger-stat">
                {Math.round(
                  sectionData[
                    viewMode === "Attacking" ? "tries" : "concededTries"
                  ]
                )}
              </Typography>
              {viewMode === "Attacking" && (
                <Typography className="scoring-map-bigger-stat">
                  {Math.round(sectionData.percentageTotal)}%
                </Typography>
              )}
            </Box>
            {viewMode === "Attacking" && sortedTryScorers.length > 0 && (
              <Box className="scoring-map-subsection">
                <Typography className="scoring-map-section-subtitle">
                  Tryscorers here
                </Typography>
                {sortedTryScorers.map((player, index) => (
                  <Box className="row scoring-map-player-row" key={index}>
                    <img
                      src={getImagePath(
                        `${player.first_name}${player.last_name}`.toLowerCase()
                      )}
                      alt={`${player.first_name} ${player.last_name}`}
                      className="scoring-map-player-image"
                    />
                    <Typography className="scoring-map-player-name">{`${player.first_name} ${player.last_name}`}</Typography>
                    <Typography className="value">
                      {Math.round(player.scored)} (
                      {Math.round(player.percentageScoredHere)}%)
                    </Typography>
                  </Box>
                ))}
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default TeamsPageScoringMap;
