import React from 'react';
import { Box, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import "./GiveawayModal.css";

const getImagePath = (imageName) => {
  try {
    return require(`./../../../assets/${imageName}`);
  } catch (error) {
    return require(`./../../../assets/lineupPlayer.png`);
  }
};

const GiveawayModal = ({ isOpen, handleClose }) => {
  if (!isOpen) return null;

  return (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1300,
      }}
      onClick={handleClose}
    >
      <Box
        sx={{
          position: 'relative',
          maxWidth: '90%',
          maxHeight: '90%',
        }}
        onClick={(e) => e.stopPropagation()}
      >
        {/* Blurred shadow effect behind the image */}
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            margin: 'auto',
            width: 'calc(100% + 20px)', // Adjust width for the shadow
            height: 'calc(100% + 20px)', // Adjust height for the shadow
            filter: 'blur(20px)',
            zIndex: -1, // Ensure the blur is behind the image
            backgroundColor: 'rgba(0, 0, 0, 0.1)',
            borderRadius: '10px', // Rounded corners to match the image container
          }}
        />
        <a
          href="https://www.instagram.com/trylineapp/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={getImagePath("giveaway.png")}
            alt="Giveaway"
            style={{
              width: '100%',
              height: 'auto',
              display: 'block',
              objectFit: 'contain',
              borderRadius: '10px', // Rounded corners for the image
            }}
          />
        </a>
        <IconButton
          onClick={handleClose}
          sx={{
            position: 'absolute',
            top: 8,
            right: 8,
            color: 'white',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.7)',
            },
          }}
        >
          <CloseIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

export default GiveawayModal;
