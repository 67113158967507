import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography
} from '@mui/material';
import { Link } from 'react-router-dom';
import './AdjustableLadder.css';

const AdjustableLadder = ({ ladderData, isLoading  }) => {
  const getImagePath = (teamName) => {
    try {
      return require(`./../../../assets/team_logos/${teamName.toLowerCase().replace(/\s/g, '-')}.svg`);
    } catch (error) {
      return require(`./../../../assets/team_logos/bye.png`);
    }
  };

  if (isLoading) {
    return (
      <div className="adjustable-ladder-container">
        <Typography variant="h6" className="h1-text">NRL Ladder</Typography>
        <Typography>Loading ladder data...</Typography>
      </div>
    );
  }
  
  return (
    <div>
      <TableContainer component={Paper} className="adjustable-ladder-container">
        <Table aria-label="adjustableLadder" className="adjustable-ladder-custom-table">
          <TableHead>
            <TableRow className="adjustable-ladder-table-row">
              <TableCell className="adjustable-ladder-table-head-cell">#</TableCell>
              <TableCell className="adjustable-ladder-table-head-cell"></TableCell>
              <TableCell className="adjustable-ladder-table-head-cell">PTS</TableCell>
              <TableCell className="adjustable-ladder-table-head-cell">PL</TableCell>
              <TableCell className="adjustable-ladder-table-head-cell">W</TableCell>
              <TableCell className="adjustable-ladder-table-head-cell">L</TableCell>
              <TableCell className="adjustable-ladder-table-head-cell">+/-</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {ladderData.map((team, index) => (
              <TableRow key={index} className={`adjustable-ladder-table-row`}>
                <TableCell className="adjustable-ladder-table-body-cell">
                  {team.position}
                </TableCell>
                <TableCell className={`adjustable-ladder-table-body-cell`}>
                  <div className={`adjustable-ladder-team-cell ${index < 8 ? 'top-8' : ''}`}>
                    <img src={getImagePath(team.team)} alt={team.team} className="adjustable-ladder-team-logo" />
                    <Typography className="adjustable-ladder-team-name">
                      <Link to={`/teams/${team.team.toLowerCase().replace(/\s/g, '-')}`} className="adjustable-ladder-team-link">{team.team}</Link>
                    </Typography>
                  </div>
                </TableCell>
                <TableCell className="adjustable-ladder-table-body-cell pts-cell">{team.points}</TableCell>
                <TableCell className="adjustable-ladder-table-body-cell">{team.played}</TableCell>
                <TableCell className="adjustable-ladder-table-body-cell">{team.wins}</TableCell>
                <TableCell className="adjustable-ladder-table-body-cell">{team.lost}</TableCell>
                <TableCell className="adjustable-ladder-table-body-cell">{team.diff}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default AdjustableLadder;