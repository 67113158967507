import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import ListViewLineup from './ListViewLineup';
import FieldViewLineup from './FieldViewLineup';
import LineupToggle from '../LineupToggle/LineupToggle';
import { usePostMatchDataContext } from '../../../hooks/PostMatchDataContext';
import { usePreMatchDataContext } from '../../../hooks/PreMatchDataContext';
import './MatchLineupSection.css';

const MatchLineUpSection = ({ isPostMatch }) => {
  const [view, setView] = useState('Field');
  const [fade, setFade] = useState(false);
  
  const postMatchData = usePostMatchDataContext();
  const preMatchData = usePreMatchDataContext();
  
  const data = isPostMatch ? postMatchData : preMatchData;

  if (!data) {
    return <div>Loading...</div>;
  }

  const { matchLineup } = data;

  if (!matchLineup || Object.keys(matchLineup).length === 0) {
    return (
      <Box className="match-lineup-section-container">
        <Typography className="match-lineup-section-error-msg">The lineup and field map are available 4:30PM Tuesday</Typography>
      </Box>
    );
  }

  const { team_1: team1Lineup, team_2: team2Lineup } = data.matchLineup;

  const handleViewChange = (newView) => {
    if (newView !== null) {
      setFade(true);
      setTimeout(() => {
        setView(newView);
        setFade(false);
      }, 100);
    }
  };

  return (
    <Box className="match-lineup-section-container">
      <LineupToggle
        options={['Field', 'List']}
        value={view.charAt(0).toUpperCase() + view.slice(1)}
        onChange={(newView) => handleViewChange(newView.toLowerCase())}
      />
      <Box className={`content-container ${fade ? 'fade-out' : 'fade-in'}`}>
        {view === 'list' ? (
          <ListViewLineup
            team1Lineup={team1Lineup}
            team2Lineup={team2Lineup}
            isPostMatch={isPostMatch}
            includeMainSections={true}
          />
        ) : (
          <FieldViewLineup
            team1Lineup={team1Lineup}
            team2Lineup={team2Lineup}
            isPostMatch={isPostMatch}
          />
        )}
      </Box>
    </Box>
  );
};

export default MatchLineUpSection;