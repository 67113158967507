import React from "react";
import { Box, Skeleton } from "@mui/material";

const waveColor = 'rgba(64, 63, 63, 0.050)';
const backgroundGradient = `linear-gradient(90deg, transparent, ${waveColor}, transparent, ${waveColor}, transparent)`;
const animationDuration = '4.2s';

const SkeletonStyles = {
  bgcolor: 'rgba(0, 0, 0, 0.05)',
  '&::after': {
    animationDuration: animationDuration,
    animationTimingFunction: 'ease-in-out',
    background: backgroundGradient,
  }
};

const LadderPageLoadingSkeleton = () => (
  <Box
    className="ladder-page-content-container main-content-box"
    sx={{ display: 'flex', flexDirection: 'column', width: '100%', margin: 'var(--header-height) auto' }}
  >
    <Skeleton
      variant="rectangular"
      width="100%"
      height={50}
      sx={{
        ...SkeletonStyles,
        borderRadius: '16px',
        marginBottom: '15px',
      }}
      animation="wave"
    />
    <Box
      className="full-ladder-box"
      sx={{ backgroundColor: '#ffffff', padding: '8px 10px 10px 10px', borderRadius: '16px', boxShadow: 'none', border: 'var(--border-style)', marginBottom: '15px' }}
    >
      <Skeleton
        variant="rectangular"
        width="100%"
        height={500}
        sx={{
          ...SkeletonStyles,
          borderRadius: '16px',
        }}
        animation="wave"
      />
    </Box>
    <Skeleton
      variant="rectangular"
      width="100%"
      height={50}
      sx={{
        ...SkeletonStyles,
        borderRadius: '16px',
      }}
      animation="wave"
    />
  </Box>
);

export default LadderPageLoadingSkeleton;