import React from "react";
import { Box, Divider, Typography } from "@mui/material";
import TeamPageOverviewFormBox from "../TeamPageOverviewFormBox/TeamPageOverviewFormBox";
import TeamPageNextMatch from "../TeamPageNextMatch/TeamPageNextMatch";
import TeamPageTrajectoryLadder from "../TeamPageTrajectoryLadder/TeamPageTrajectoryLadder";
import { useTeamDataContext } from "../../../hooks/TeamDataContext"; // Import the context hook
import "./TeamsPageOverviewSection.css";

const TeamsPageOverviewSection = () => {
  const data = useTeamDataContext();
  
  // Handle loading state or no data
  if (!data || !data.teamBasicInfo) return null;

  const { teamBasicInfo } = data;
  const {
    founded,
    premierships,
    runner_ups,
    minor_premierships,
    home_grounds
  } = teamBasicInfo;

  return (
    <Box className="teams-overview-container">
      <Box className="teams-overview-row1">
        <Box className="column teams-overview-column">
          <TeamPageOverviewFormBox />
        </Box>
        <Divider
          orientation="vertical"
          flexItem
          className="postmatch-matchplayerstats-vertical-divider"
        />
        <Box className="column teams-overview-column">
          <TeamPageNextMatch />
        </Box>
      </Box>
      <Divider className="prematch-form-section-divider" />
      <Box className="column teams-overview-row2">
        <Box className="row">
          <Typography className="subheader-text h1-text-team-overview">
            Ladder Rank
          </Typography>
        </Box>
        <Box className="row">
          <TeamPageTrajectoryLadder />
        </Box>
        <Divider className="prematch-form-section-divider" />
        <Typography className="subheader-text h1-text-team-overview">
          Club Information
        </Typography>
        {/* Display each historical detail in separate rows */}
        <Box className="club-history-row">
          <Typography className="history-label">
            Founded: 
          </Typography>
          <Typography className="body-text history-detail">
            {founded}
          </Typography>
        </Box>
        <Box className="club-history-row">
          <Typography className="history-label">
            Premierships: 
          </Typography>
          <Typography className="body-text history-detail">
            {premierships}
          </Typography>
        </Box>
        <Box className="club-history-row">
          <Typography className="history-label">
            Runner Ups: 
          </Typography>
          <Typography className="body-text history-detail">
            {runner_ups}
          </Typography>
        </Box>
        <Box className="club-history-row">
          <Typography className="history-label">
            Minor Premierships: 
          </Typography>
          <Typography className="body-text history-detail">
            {minor_premierships}
          </Typography>
        </Box>
        <Box className="club-history-row">
          <Typography className="history-label">
            Home Grounds: 
          </Typography>
          <Typography className="body-text history-detail">
            {home_grounds}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default TeamsPageOverviewSection;
