import React, { useRef, useEffect, useState } from "react";
import PlayerModal from "../PlayerModal/PlayerModal";
import { usePostMatchDataContext } from "../../../hooks/PostMatchDataContext";
import playerPositions from "../../../constants/playerPositions";
import fieldImageSrc from "./../../../assets/field.svg";
import verticalFieldImageSrc from "./../../../assets/verticalField.svg";
import { useTeamColors } from "../../../hooks/TeamColorsContext";
import { usePreMatchDataContext } from "../../../hooks/PreMatchDataContext";
import verticalPlayerPositions from "../../../constants/verticalPlayerPositions";
import "./FieldViewLineup.css";

const useMediaQuery = (query) => {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    const media = window.matchMedia(query);
    if (media.matches !== matches) {
      setMatches(media.matches);
    }
    const listener = () => setMatches(media.matches);
    media.addListener(listener);
    return () => media.removeListener(listener);
  }, [matches, query]);

  return matches;
};

const FieldImage = ({ isPostMatch }) => {
  const containerRef = useRef(null);
  const [fieldWidth, setFieldWidth] = useState(0);
  const [fieldHeight, setFieldHeight] = useState(0);
  const [selectedPlayer, setSelectedPlayer] = useState(null);
  const isSmallScreen = useMediaQuery('(max-width: 850px)');

  const postMatchData = usePostMatchDataContext();
  const preMatchData = usePreMatchDataContext();
  const data = isPostMatch ? postMatchData : preMatchData;

  const teamColors = useTeamColors().teamColours;
  const team1Lineup = data.matchLineup.team_1;
  const team2Lineup = data.matchLineup.team_2;

  const team1Color =
    teamColors.find((team) => team.team_id === team1Lineup.team_id)?.colour ||
    "#0056b3";
  const team2Color =
    teamColors.find((team) => team.team_id === team2Lineup.team_id)?.colour ||
    "#ff7f0e";

  useEffect(() => {
    const image = new Image();
    image.src = isSmallScreen ? verticalFieldImageSrc : fieldImageSrc;

    image.onload = () => {
      const aspectRatio = image.width / image.height;

      const updateFieldDimensions = () => {
        if (containerRef.current) {
          const newWidth = containerRef.current.offsetWidth;
          setFieldWidth(newWidth);
          setFieldHeight(isSmallScreen ? newWidth * 1.5 : newWidth / aspectRatio);
        }
      };

      updateFieldDimensions();

      const resizeObserver = new ResizeObserver(updateFieldDimensions);
      if (containerRef.current) {
        resizeObserver.observe(containerRef.current);
      }

      return () => {
        if (containerRef.current) {
          resizeObserver.unobserve(containerRef.current);
        }
      };
    };
  }, [isSmallScreen]);

  if (fieldWidth === 0 || fieldHeight === 0) {
    return (
      <div ref={containerRef} className="field-image-container">
        Loading...
      </div>
    );
  }

  const handlePlayerClick = (player) => {
    if (isPostMatch) {
      setSelectedPlayer(player);
    }
  };

  const swapLeftRightY = (y) => {
    const leftPositions = [13, 31, 39, 21];
    const rightPositions = [83, 66, 60, 77];
    
    if (leftPositions.includes(y)) {
      return rightPositions[leftPositions.indexOf(y)];
    } else if (rightPositions.includes(y)) {
      return leftPositions[rightPositions.indexOf(y)];
    }
    return y;
  };

  const renderPlayers = (lineup, isTeam1 = false, team) => {
    return lineup.map((player, index) => {
      const position = isSmallScreen 
        ? verticalPlayerPositions[player.position_id]
        : playerPositions[player.position_id];
  
      if ([15, 17, 18].includes(player.position_id)) {
        return null;
      }
  
      if (!position) {
        console.warn(
          `No position found for position_id: ${player.position_id} (${player.position_name})`
        );
        return null;
      }
  
      const lastName = player.last_name;
      const positionLabel = position.label;
      const teamColor = team === "team1" ? team1Color : team2Color;
      const playerSize = isSmallScreen ? 30 : 45;
  
      let xPosition, yPosition;
  
      if (isSmallScreen) {
        if (isTeam1) {
          // Swap left and right for the top team
          xPosition = fieldWidth - (position.x / 100) * fieldWidth;
        } else {
          xPosition = (position.x / 100) * fieldWidth;
        }
        yPosition = (position.y / 100) * fieldHeight;

        // Adjust positions for team2 in vertical layout
        if (!isTeam1) {
          yPosition = fieldHeight - yPosition;
        }
      } else {
        if (isTeam1) {
          xPosition = fieldWidth - (position.x / 100) * fieldWidth;
          const swappedY = swapLeftRightY(position.y);
          yPosition = (swappedY / 100) * fieldHeight;
        } else {
          xPosition = (position.x / 100) * fieldWidth;
          yPosition = (position.y / 100) * fieldHeight;
        }
      }
  
       // Ensure players stay within bounds
       xPosition = Math.max(playerSize / 2, Math.min(xPosition, fieldWidth - playerSize / 2));
       yPosition = Math.max(playerSize / 2, Math.min(yPosition, fieldHeight - playerSize / 2));
   
       return (
         <g
           key={index}
           onClick={() => handlePlayerClick(player)}
           className={`field-image-player-group ${isPostMatch ? 'clickable' : ''}`}
         >
          <image
            href={require("./../../../assets/newPlayer.png")}
            x={xPosition - playerSize / 2}
            y={yPosition - playerSize / 2}
            width={playerSize}
            height={playerSize}
            className={`field-image-player-photo ${isPostMatch ? 'clickable' : ''}`}
          />
          <rect
            x={xPosition + (isSmallScreen ? 3 : 8)}
            y={yPosition - (isSmallScreen ? 12 : 20)}
            rx={isSmallScreen ? 2 : 8}
            ry={isSmallScreen ? 2 : 8}
            width={isSmallScreen ? 20 : 24}
            height={isSmallScreen ? 10 : 16}
            fill={teamColor}
            className="field-image-position-label-bg"
          />
          <text
            x={xPosition + (isSmallScreen ? 13 : 20)}
            y={yPosition - (isSmallScreen ? 5 : 10)}
            className="field-image-position-label"
          >
            {positionLabel}
          </text>
          <text
            x={xPosition}
            y={yPosition + (isSmallScreen ? 25 : 35)}
            className="field-image-player-name"
          >
            {lastName}
          </text>
          {isPostMatch && isSmallScreen && (
            <text
              x={xPosition + playerSize / 2 + 5}
              y={yPosition}
              className="field-image-player-score"
              fontSize="12"
              fill="white"
            >
            </text>
          )}
        </g>
      );
    });
  };


  return (
    <div ref={containerRef} className={`field-image-container ${isSmallScreen ? 'vertical' : ''}`}>
      <svg
        width={fieldWidth}
        height={fieldHeight}
        viewBox={`0 0 ${fieldWidth} ${fieldHeight}`}
        preserveAspectRatio="xMidYMid meet"
      >
        <image
          href={isSmallScreen ? verticalFieldImageSrc : fieldImageSrc}
          width={fieldWidth}
          height={fieldHeight}
          preserveAspectRatio="xMidYMid slice"
        />

        {renderPlayers(team1Lineup.backs, true, "team1")}
        {renderPlayers(team1Lineup.forwards, true, "team1")}
        {renderPlayers(team1Lineup.interchange, true, "team1")}

        {renderPlayers(team2Lineup.backs, false, "team2")}
        {renderPlayers(team2Lineup.forwards, false, "team2")}
        {renderPlayers(team2Lineup.interchange, false, "team2")}
      </svg>
      {isPostMatch && selectedPlayer && (
        <PlayerModal
          open={!!selectedPlayer}
          handleClose={() => setSelectedPlayer(null)}
          player={selectedPlayer}
        />
      )}
    </div>
  );
};

export default FieldImage;