import React from 'react';
import { Container, CssBaseline, Box, Typography } from '@mui/material';
import Header from '../../components/SharedComponents/Header/Header';
import notFoundSvg from '../../assets/404.svg'; // Adjust the path if necessary
import Footer from '../../components/SharedComponents/Footer/Footer';
import './NotFoundPage.css'; // Assuming you might have some specific styles for this page

function NotFoundPage() {
  return (
    <div className="not-found-page-root">
      <div className="scrollable-content">
        <Container component="main" className="main-container">
          <CssBaseline />
          <Header />
          <Box className="not-found-container">
            <img
              src={notFoundSvg}
              alt="404 Not Found"
              style={{ width: '300px', height: '300px', marginBottom: '20px', marginTop: '150px' }} // Inline styles for the SVG
            />
            <Typography className="not-found-message">
              404: Looks like you've stumbled onto an unknown page.
            </Typography>
          </Box>
        </Container>
        <Footer></Footer>
      </div>
    </div>
  );
}

export default NotFoundPage;
