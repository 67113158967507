import React from "react";
import { Box, Skeleton, Card, CardContent } from "@mui/material";

const waveColor = 'rgba(64, 63, 63, 0.050)';
const backgroundGradient = `linear-gradient(90deg, transparent, ${waveColor}, transparent, ${waveColor}, transparent)`;
const animationDuration = '4.2s';

const SkeletonStyles = {
  bgcolor: 'rgba(0, 0, 0, 0.05)',
  '&::after': {
    animationDuration: animationDuration,
    animationTimingFunction: 'ease-in-out',
    background: backgroundGradient,
  }
};

const LoadingLandingHighlightsSkeleton = () => {
  return (
    <Box className="match-highlights-container">
      {/* Highlights Skeleton */}
      {[...Array(8)].map((_, index) => (
        <Card key={index} className="highlight-card" sx={{ display: 'flex', alignItems: 'center', marginBottom: '20px', boxShadow: 'none !important' }}>
          <CardContent className="highlight-content" sx={{ display: 'flex', alignItems: 'center', padding: '0 !important' }}>
            <Skeleton 
              variant="rectangular"
              width={110}
              height={80}
              sx={{
                borderRadius: '14px',
                bgcolor: 'rgba(0, 0, 0, 0.05)',
                marginRight: '15px',
                ...SkeletonStyles
              }}
              animation="wave"
            />
            <Box className="highlight-details" sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '80px', marginLeft: '15px' }}>
              <Skeleton variant="text" width="50%" height={24} sx={SkeletonStyles} animation="wave" />
              <Skeleton variant="text" width="30%" height={24} sx={SkeletonStyles} animation="wave" />
              <Box className="highlight-tags" sx={{ marginTop: '3px', display: 'flex', gap: '10px' }}>
                <Skeleton variant="rectangular" width="60px" height={24} sx={{ borderRadius: '16px', bgcolor: 'rgba(0, 0, 0, 0.05)', ...SkeletonStyles }} animation="wave" />
                <Skeleton variant="rectangular" width="60px" height={24} sx={{ borderRadius: '16px', bgcolor: 'rgba(0, 0, 0, 0.05)', ...SkeletonStyles }} animation="wave" />
              </Box>
            </Box>
          </CardContent>
        </Card>
      ))}
    </Box>
  );
};

export default LoadingLandingHighlightsSkeleton;
