import React from "react";
import { Box, Typography } from "@mui/material";
import { usePostMatchDataContext } from "../../../hooks/PostMatchDataContext";
import { useTeamColors } from "../../../hooks/TeamColorsContext";
import "./PostMatchOverviewSection.css";

const getTeamScores = (scoringEvents, teamId, scoringType) => {
  return scoringEvents.filter((event) => {
    if (scoringType === "Conversion" || scoringType === "Penalty Goal") {
      return event.team_id === teamId && event.scoring_type === scoringType && event.kick_successful;
    }
    return event.team_id === teamId && event.scoring_type === scoringType;
  });
};

const PostMatchOverviewSection = () => {
  const data = usePostMatchDataContext();
  const teamColors = useTeamColors().teamColours;
  const { matchBasicInfo, matchStats, scoringEvents } = data;

  const team1Id = matchBasicInfo.team1.id;
  const team2Id = matchBasicInfo.team2.id;

  const team1Color = teamColors.find(team => team.team_id === team1Id)?.colour || "#74C8D4";
  const team2Color = teamColors.find(team => team.team_id === team2Id)?.colour || "#F19858";

  const team1Possession = matchStats.teamStats.team_1.possession.percentage;
  const team2Possession = matchStats.teamStats.team_2.possession.percentage;

  const renderPossessionSection = (team1Percentage, team2Percentage) => {
    return (
      <Box className="postmatch-overview-section">
        <Typography className="postmatch-overview-title">Possession</Typography>
        <Box className="postmatch-overview-possession-bar">
          <Box
            className="postmatch-overview-possession-partial-bar team1-colour possession-padding-left"
            style={{ width: `${team1Percentage}%`, backgroundColor: team1Color }}
          >
            {team1Percentage}%
          </Box>
          <Box
            className="postmatch-overview-possession-partial-bar team2-colour justify-flex-end possession-padding-right"
            style={{ width: `${team2Percentage}%`, backgroundColor: team2Color }}
          >
            {team2Percentage}%
          </Box>
        </Box>
      </Box>
    );
  };

  const renderStatBar = (team1Percentage, team2Percentage, barClass) => {
    return (
      <Box className={barClass}>
        <Box
          className="team1-colour"
          style={{ width: `${team1Percentage}%`, backgroundColor: team1Color }}
        ></Box>
        <Box
          className="team2-colour"
          style={{ width: `${team2Percentage}%`, backgroundColor: team2Color }}
        ></Box>
      </Box>
    );
  };

  const renderStatSection = (statType, team1Data, team2Data, barClass, team1Id, team2Id) => {
    const team1Value = team1Data.length;
    const team2Value = team2Data.length;
    const team1Percentage = (team1Value / (team1Value + team2Value)) * 100 || 50;
    const team2Percentage = (team2Value / (team1Value + team2Value)) * 100 || 50;
    const largerValue = team1Value > team2Value ? 'team1' : 'team2';

    return (
      <Box className="postmatch-overview-section">
        <Box className="postmatch-overview-header">
          <Typography 
            className={`postmatch-overview-header-value ${largerValue === 'team1' ? 'highlight' : ''}`}
            style={{
              backgroundColor: largerValue === 'team1' ? team1Color : 'transparent',
              color: largerValue === 'team1' ? 'white' : 'inherit'
            }}
          >
            {team1Value}
          </Typography>
          <Typography className="postmatch-overview-title">{statType}</Typography>
          <Typography 
            className={`postmatch-overview-header-value ${largerValue === 'team2' ? 'highlight' : ''}`}
            style={{
              backgroundColor: largerValue === 'team2' ? team2Color : 'transparent',
              color: largerValue === 'team2' ? 'white' : 'inherit'
            }}
          >
            {team2Value}
          </Typography>
        </Box>
        {renderStatBar(team1Percentage, team2Percentage, barClass)}
        <Box className="postmatch-overview-stats">
          {team1Data.concat(team2Data).sort((a, b) => parseFloat(a.time) - parseFloat(b.time)).map((event, i) => (
            <Box key={i} className="postmatch-overview-stat-row">
              <Typography 
                className="postmatch-overview-player-name team1-text"
              >
                {event.team_id === team1Id ? `${event.first_name} ${event.last_name}` : ''}
              </Typography>
              <Box className="postmatch-overview-time-circle">
                <Typography className="postmatch-overview-time-text">{Math.round(event.time)}'</Typography>
              </Box>
              <Typography 
                className="postmatch-overview-player-name team2-text"
              >
                {event.team_id === team2Id ? `${event.first_name} ${event.last_name}` : ''}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>
    );
  };

  const scoringTypes = [
    { type: 'Tries', eventType: 'Try' },
    { type: 'Conversions', eventType: 'Conversion' },
    { type: 'Penalty Goals', eventType: 'Penalty Goal' },
    { type: '1 Point Field Goals', eventType: '1 Point Field Goal' },
    { type: '2 Point Field Goals', eventType: '2 Point Field Goal' }
  ];

  return (
    <Box className="postmatch-overview-container">
      {renderPossessionSection(team1Possession, team2Possession)}
      {scoringTypes.map(({ type, eventType }) => {
        const team1Scores = getTeamScores(scoringEvents, team1Id, eventType);
        const team2Scores = getTeamScores(scoringEvents, team2Id, eventType);
        if (team1Scores.length > 0 || team2Scores.length > 0) {
          return renderStatSection(type, team1Scores, team2Scores, 'postmatch-overview-thin-bar', team1Id, team2Id);
        }
        return null;
      })}
    </Box>
  );
};

export default PostMatchOverviewSection;