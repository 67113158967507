import React from 'react';
import { Container, CssBaseline, Box, Typography } from '@mui/material';
// import MatchesTable from '../components/SharedComponents/MatchesTable/MatchesTable';

function TestPage() {
  return (
    <Container component="main">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography component="h1" variant="h5">
          Test
        </Typography>
        {/* <MatchesTable /> */}
      </Box>
    </Container>
  );
}

export default TestPage;
