// hooks/usePostMatchData.js
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import config from '../config';

const fetchPostMatchData = async ({ queryKey }) => {
  const [, matchId] = queryKey;
  const response = await axios.get(`${config.backendUrl}/match_info_post?matchid=${matchId}`);
  return response.data;
};

export const usePostMatchData = (matchId) => {
  return useQuery({
    queryKey: ['matchData', matchId],
    queryFn: fetchPostMatchData,
    staleTime: 15 * 60 * 1000, // 5 minutes
  });
};
