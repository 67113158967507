import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import './SmallLadder.css';
import { useSmallLadderData } from '../../../hooks/useSmallLadderData';

const SmallLadder = () => {
  const navigate = useNavigate();
  const { data: ladderData, isLoading, isError } = useSmallLadderData();

  const getImagePath = (teamName) => {
    try {
      return require(`./../../../assets/team_logos/${teamName.toLowerCase().replace(/\s/g, '-')}.svg`);
    } catch (error) {
      return require(`./../../../assets/team_logos/bye.png`);
    }
  };

  if (isLoading) return <Typography>Loading...</Typography>;

  if (isError) {
    navigate('/404');
    return null;
  }

  const handleRowClick = (teamName) => {
    navigate(`/teams/${teamName.toLowerCase().replace(/\s/g, '-')}`);
  };

  return (
    <div>
      <TableContainer component={Paper} className="small-ladder-ladder-container">
        <Table aria-label="smallLadder" className="small-ladder-custom-table">
          <TableHead>
            <TableRow className="small-ladder-table-row">
              <TableCell className="small-ladder-table-head-cell">#</TableCell>
              <TableCell className="small-ladder-table-head-cell"></TableCell>
              <TableCell className="small-ladder-table-head-cell">PTS</TableCell>
              <TableCell className="small-ladder-table-head-cell">PL</TableCell>
              <TableCell className="small-ladder-table-head-cell">W</TableCell>
              <TableCell className="small-ladder-table-head-cell">L</TableCell>
              <TableCell className="small-ladder-table-head-cell">+/-</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {ladderData && ladderData.map((team, index) => (
              <TableRow
                key={index}
                className="small-ladder-table-row"
                onClick={() => handleRowClick(team.team)} // Make the entire row clickable
              >
                <TableCell className="small-ladder-table-body-cell">
                  {team.position}
                </TableCell>
                <TableCell className="small-ladder-table-body-cell">
                  <div className={`small-ladder-team-cell ${index < 8 ? 'top-8' : ''}`}>
                    <img src={getImagePath(team.team)} alt={team.team} className="small-ladder-team-logo" />
                    <Typography className="small-ladder-team-name">
                      <a href={`/teams/${team.team.toLowerCase().replace(/\s/g, '-')}`} className="small-ladder-team-link">
                        {team.team}
                      </a>
                    </Typography>
                  </div>
                </TableCell>
                <TableCell className="small-ladder-table-body-cell pts-cell">{team.points}</TableCell>
                <TableCell className="small-ladder-table-body-cell">{team.played}</TableCell>
                <TableCell className="small-ladder-table-body-cell">{team.wins}</TableCell>
                <TableCell className="small-ladder-table-body-cell">{team.lost}</TableCell>
                <TableCell className="small-ladder-table-body-cell">{team.diff}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default SmallLadder;
