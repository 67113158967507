import React from 'react';
import { Box, Typography, Link } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import './Footer.css';

const Footer = () => {
  return (
    <Box component="footer" className="footer-wrapper">
      <Box className="footer">
        <Box className="footer-content">
          <Box className="footer-left">
            <Typography variant="h6" component="div" className="footer-title">
              TRYLINE
            </Typography>
            <Typography variant="subtitle1" className="footer-subtitle">
              One stop NRL pregame destination.
            </Typography>
            <Typography variant="body2" className="footer-description">
              Stats, free betting analytics and more.
            </Typography>
          </Box>
          
          <Box className="footer-right">
            <Box className="footer-links">
              <Link href="/about" className="footer-link">About Us</Link>
              <Link href="/ourData" className="footer-link">Our Data</Link>
              <Link href="/terms" className="footer-link">Terms of Services</Link>
              <Link href="/privacyPolicy" className="footer-link">Privacy Policy</Link>
            </Box>
            
            <Box className="footer-social">
              <Link href="https://www.facebook.com/profile.php?id=61564969918279" target="_blank" rel="noopener noreferrer">
                <FacebookIcon className="footer-icon" />
              </Link>
              <Link href="https://www.instagram.com/trylineapp/" target="_blank" rel="noopener noreferrer">
                <InstagramIcon className="footer-icon" />
              </Link>
            </Box>
            
            <Typography variant="body2" className="footer-copyright">
              © Copyright 2024, All Rights Reserved
            </Typography>
          </Box>
        </Box>
        
        <Box className="footer-disclaimer">
          <Box className="footer-disclaimer-content">
            <Typography variant="body2" className="footer-disclaimer-title">
              CHANCES ARE YOU'RE ABOUT TO LOSE.
            </Typography>
            <Typography variant="body2" className="footer-disclaimer-text">
              For free and confidential support call 1800 858 858 or visit Gambling Help Online.
            </Typography>
            <Typography variant="body2" className="footer-disclaimer-text">
              BetStop - National Self-Exclusion Register - www.betstop.gov.au
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default Footer;
