import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import config from '../config';

const fetchMatchHighlights = async () => {
  const response = await axios.get(`${config.backendUrl}/frontpage_match_highlights`);
  return response.data;
};

export const useMatchHighlights = () => {
  return useQuery({
    queryKey: ['matchHighlights'],
    queryFn: fetchMatchHighlights,
    staleTime: 15 * 60 * 1000, // 10 minutes
  });
};