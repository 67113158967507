// pages/InjuriesPage.js
import React from "react";
import { Container, CssBaseline, Box } from "@mui/material";
import Header from "../../components/SharedComponents/Header/Header";
import InjuriesBanner from "../../components/InjuriesBanner/InjuriesBanner";
import SmallLadder from "../../components/SharedComponents/Ladder/SmallLadder";
import "./InjuriesPage.css";
import stormImage from "../../assets/banner/storm.png";
import Footer from "../../components/SharedComponents/Footer/Footer";

function InjuriesPage() {
  return (
    <div className="injuries-page-root">
      <div className="scrollable-content">
        <Container component="main" className="main-container">
          <CssBaseline />
          <Header />
          <InjuriesBanner teamLogo={stormImage} />
          <Box className="injuries-page-container main-content-box">
            <Box className="injuries-column1">
                Hello
            </Box>
            <Box className="injuries-column2">
              <SmallLadder className="small-ladder" />
            </Box>
          </Box>
        </Container>
        <Footer></Footer>
      </div>
    </div>
  );
}

export default InjuriesPage;
