import React, { useState } from "react";
import { Box, Typography, Avatar } from "@mui/material";
import LinkIcon from "@mui/icons-material/Link";
import GiveawayModal from "../GiveawayModal/GiveawayModal";
import "./TrylineMedia.css";

const getImagePath = (imageName) => {
  try {
    return require(`./../../../assets/${imageName}`);
  } catch (error) {
    return require(`./../../../assets/lineupPlayer.png`);
  }
};

const TrylineMedia = () => {
  const [isGiveawayModalOpen, setIsGiveawayModalOpen] = useState(false);

  const channels = [
    {
      name: "Tryline $150 Giveaway",
      logo: getImagePath("thanks.png"),
      platformLink: "#", // Dummy link as the modal will open instead
      platformIcon: <LinkIcon className="endorsed-channels-link-icon" />,
      description: "Our thanks to you!",
      isGiveaway: true, // Flag to indicate this is the giveaway link
    },
    {
      name: "Tryline Instagram",
      logo: getImagePath("insta5main.png"),
      platformLink: "https://www.instagram.com/trylineapp/",
      platformIcon: <LinkIcon className="endorsed-channels-link-icon" />,
      description: "Follow for feature updates",
    },
    {
      name: "Dog Predicts Finals Week 1",
      logo: getImagePath("dog.png"),
      platformLink: "https://www.instagram.com/reel/C_zMRdZgK-y/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==",
      platformIcon: <LinkIcon className="endorsed-channels-link-icon" />,
      description: "Instagram Post",
    },
  ];

  const handleGiveawayClick = (event) => {
    event.preventDefault(); // Prevent default anchor click behavior
    setIsGiveawayModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsGiveawayModalOpen(false);
  };

  return (
    <Box className="endorsed-channels">
      <Typography className="h1-text-endorsed">Tryline Media</Typography>
      {channels.map((channel, index) => (
        <Box
          key={index}
          onClick={channel.isGiveaway ? handleGiveawayClick : undefined} // Make the whole box clickable if it's the giveaway
          className={`tryline-media-box ${channel.isGiveaway ? "endorsed-channels-box-highlight" : ""}`}
          style={{ cursor: channel.isGiveaway ? 'pointer' : 'default' }} // Change cursor to pointer if clickable
        >
          <Avatar src={channel.logo} alt={channel.name} className="endorsed-channels-avatar" />
          <Box>
            <Typography className="endorsed-channels-name">{channel.name}</Typography>
            {channel.isGiveaway ? (
              <Box className="endorsed-channels-info">
                {channel.platformIcon}
                <Typography variant="body2" className="endorsed-channels-description">
                  {channel.description}
                </Typography>
              </Box>
            ) : (
              <a
                href={channel.platformLink}
                target="_blank"
                rel="noopener noreferrer"
                className="endorsed-channels-link"
              >
                <Box className="endorsed-channels-info">
                  {channel.platformIcon}
                  <Typography variant="body2" className="endorsed-channels-description">
                    {channel.description}
                  </Typography>
                </Box>
              </a>
            )}
          </Box>
        </Box>
      ))}

      {/* Use the GiveawayModal component */}
      <GiveawayModal isOpen={isGiveawayModalOpen} handleClose={handleCloseModal} />
    </Box>
  );
};

export default TrylineMedia;
