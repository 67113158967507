import React from "react";
import { Box, Typography } from "@mui/material";
import "./ScoringMapToggle.css";

const ScoringMapToggle = ({ options, value, onChange }) => {
  return (
    <Box className="scoring-map-toggle">
      <Box className="scoring-map-toggle-options">
        <Typography className="scoring-map-toggle-text" >Attacking </Typography>
        {options.map((option) => (
          <Box
            key={option}
            className={`scoring-map-toggle-option ${
              value === option ? "selected" : ""
            }`}
            onClick={() => onChange(option)}
          >
            {option}
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default ScoringMapToggle;
