import React from 'react';
import { Container, CssBaseline, Box, Typography } from '@mui/material';
import Header from '../../components/SharedComponents/Header/Header';
import Footer from '../../components/SharedComponents/Footer/Footer';
import "../AboutUsPage/AboutUsPage.css";

function PrivacyPolicyPage() {
  return (
    <div className="about-page-root">
      <div className="scrollable-content">
    <div className="outer-container">
      <Container component="main" className="main-container">
        <CssBaseline />
        <Header />
        <Box className="about-us-page-container main-content-box">
          <Typography className="aboutus-header-text" component="h1" gutterBottom>
            Privacy Policy
          </Typography>
          <Typography variant="body1" className="aboutus-text" paragraph>
            This application collects the following data about you.
          </Typography>
          <Typography variant="body1" className="aboutus-text" paragraph>
            Our servers automatically collect data like your IP address, browser type and usage patterns when you visit our website.
          </Typography>
          <Typography variant="body1" className="aboutus-text" paragraph>
            We use this information to help us determine what we can improve on our site.
          </Typography>
          <Typography variant="body1" className="aboutus-text" paragraph>
            We reserve the right to terminate your access to our site through IP banning if it is determined that you are using our website with the intent to cause financial loss or harm.
          </Typography>
          <Typography variant="body1" className="aboutus-text" paragraph>
            Tryline does not collect any other information about you at this time.
          </Typography>
          <Typography variant="body1" className="aboutus-text" paragraph>
            We would like to specifically let you know that should login systems and advertisements be implemented on our website, the privacy policy will change and you will be notified when visiting the page.
          </Typography>
          <Typography variant="body1" className="aboutus-text" paragraph>
            Tryline reserves the right to make changes to this privacy policy at any time and will notify users when visiting the site should the privacy policy change.
          </Typography>
        </Box>
      </Container>
      </div>
      <Footer/>
    </div>
    </div>
  );
}

export default PrivacyPolicyPage;
