import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import { usePreMatchDataContext } from "../../../hooks/PreMatchDataContext";
import ScoringMapToggle from "./../ScoringMapToggle/ScoringMapToggle";
import "./PreMatchScoringMap.css";

const getImagePath = (imageName) => {
  try {
    return require(`./../../../assets/${imageName}.png`);
  } catch (error) {
    return require(`./../../../assets/lineupPlayer.png`);
  }
};

const roundWithSuffix = (value) => {
  const roundedValue = Math.round(value);
  const lastDigit = roundedValue % 10;
  const lastTwoDigits = roundedValue % 100;
  if (lastTwoDigits >= 11 && lastTwoDigits <= 13) {
    return `${roundedValue}th`;
  }
  switch (lastDigit) {
    case 1:
      return `${roundedValue}st`;
    case 2:
      return `${roundedValue}nd`;
    case 3:
      return `${roundedValue}rd`;
    default:
      return `${roundedValue}th`;
  }
};

const roundNormally = (value) => Math.round(value);

const getOppositeSectionName = (section) => {
  switch (section) {
    case "left":
      return "Right";
    case "right":
      return "Left";
    case "center":
    default:
      return "Center";
  }
};

const PreMatchScoringMap = () => {
  const data = usePreMatchDataContext();
  const { strengthMap } = data;
  const [selectedTeam, setSelectedTeam] = useState("team1");
  const [selectedSection, setSelectedSection] = useState("left");

  const handleTeamChange = (newTeam) => {
    setSelectedTeam(newTeam === strengthMap.team1.teamName ? "team1" : "team2");
    setSelectedSection("left");
  };

  const handleSectionClick = (section) => {
    setSelectedSection(section);
  };

  const teamData = strengthMap[selectedTeam];
  const oppositionTeam = selectedTeam === "team1" ? "team2" : "team1";
  const oppositionData = strengthMap[oppositionTeam];

  const getSectionData = (section) => {
    switch (section) {
      case "left":
        return teamData.attacking.rightSide;
      case "center":
        return teamData.attacking.middle;
      case "right":
        return teamData.attacking.leftSide;
      default:
        return teamData.attacking.middle;
    }
  };

  const sectionData = getSectionData(selectedSection);
  sectionData.tryScorers.sort((a, b) => b.scored - a.scored);

  const calculateHeight = (rank) => {
    const maxRank = 17; // Assuming 16 teams in the league, max rank is 17
    const minHeight = 20;
    const maxHeight = 100;
    return `${
      maxHeight - ((rank - 1) * (maxHeight - minHeight)) / (maxRank - 1)
    }%`;
  };

  return (
    <Box className="scoring-map-container">
      <ScoringMapToggle
        options={[strengthMap.team1.teamName, strengthMap.team2.teamName]}
        value={teamData.teamName}
        onChange={handleTeamChange}
      />

      <Box className="scoring-map-content">
        <Box className="scoring-map-visualization">
          <Box className="scoring-map-column">
            <Typography className="scoring-map-diagram-header">
              Right
            </Typography>
            <Box
              className={`scoring-map-row top ${
                selectedSection === "left" ? "selected" : ""
              }`}
              onClick={() => handleSectionClick("left")}
            >
              <Box
                className="scoring-map-bar flex-start"
                style={{
                  height: calculateHeight(teamData.attacking.rightSide.rank),
                }}
              >
                <Typography className="scoring-map-bar-rank flex-end">
                  {roundWithSuffix(teamData.attacking.rightSide.rank)}
                </Typography>
              </Box>
            </Box>
            <Box
              className={`scoring-map-row bottom ${
                selectedSection === "left" ? "selected" : ""
              }`}
              onClick={() => handleSectionClick("left")}
            >
              <Box
                className="scoring-map-bar flex-end"
                style={{
                  height: calculateHeight(
                    oppositionData.defending.leftSide.rank
                  ),
                }}
              >
                <Typography className="scoring-map-bar-rank flex-start">
                  {roundWithSuffix(oppositionData.defending.leftSide.rank)}
                </Typography>
              </Box>
            </Box>
            <Typography className="scoring-map-diagram-header">Left</Typography>
          </Box>
          <Box className="scoring-map-column">
            <Typography className="scoring-map-diagram-header">
              Attacking
            </Typography>
            <Box
              className={`scoring-map-row top ${
                selectedSection === "center" ? "selected" : ""
              }`}
              onClick={() => handleSectionClick("center")}
            >
              <Box
                className="scoring-map-bar flex-start"
                style={{
                  height: calculateHeight(teamData.attacking.middle.rank),
                }}
              >
                <Typography className="scoring-map-bar-rank flex-end">
                  {roundWithSuffix(teamData.attacking.middle.rank)}
                </Typography>
              </Box>
            </Box>
            <Box
              className={`scoring-map-row bottom ${
                selectedSection === "center" ? "selected" : ""
              }`}
              onClick={() => handleSectionClick("center")}
            >
              <Box
                className="scoring-map-bar flex-end"
                style={{
                  height: calculateHeight(oppositionData.defending.middle.rank),
                }}
              >
                <Typography className="scoring-map-bar-rank flex-start">
                  {roundWithSuffix(oppositionData.defending.middle.rank)}
                </Typography>
              </Box>
            </Box>
            <Typography className="scoring-map-diagram-header">
              Defending
            </Typography>
          </Box>
          <Box className="scoring-map-column">
            <Typography className="scoring-map-diagram-header">Left</Typography>
            <Box
              className={`scoring-map-row top ${
                selectedSection === "right" ? "selected" : ""
              }`}
              onClick={() => handleSectionClick("right")}
            >
              <Box
                className="scoring-map-bar flex-start"
                style={{
                  height: calculateHeight(teamData.attacking.leftSide.rank),
                }}
              >
                <Typography className="scoring-map-bar-rank flex-end">
                  {roundWithSuffix(teamData.attacking.leftSide.rank)}
                </Typography>
              </Box>
            </Box>
            <Box
              className={`scoring-map-row bottom ${
                selectedSection === "right" ? "selected" : ""
              }`}
              onClick={() => handleSectionClick("right")}
            >
              <Box
                className="scoring-map-bar flex-end"
                style={{
                  height: calculateHeight(
                    oppositionData.defending.rightSide.rank
                  ),
                }}
              >
                <Typography className="scoring-map-bar-rank flex-start">
                  {roundWithSuffix(oppositionData.defending.rightSide.rank)}
                </Typography>
              </Box>
            </Box>
            <Typography className="scoring-map-diagram-header">
              Right
            </Typography>
          </Box>
        </Box>

        <Box className="scoring-map-details">
          <Box className="scoring-map-section">
            <Box className="scoring-map-header">
              <span className={`dot green-dot`}></span>
              <Typography className="scoring-map-section-title">
                {teamData.teamName} Attack
              </Typography>
            </Box>
            <Box className="row">
              <Box className="column">
                <Typography className="title">Rank</Typography>
                <Typography className="scoring-map-bigger-stat">
                  {roundWithSuffix(teamData.attacking.overall.rank)}
                </Typography>
              </Box>
              <Box className="column" id="tries-column">
                <Typography className="title">Tries</Typography>
                <Typography className="title scoring-map-column-gap">
                  Tries Per Game
                </Typography>
              </Box>
              <Box className="column">
                <Typography className="value">
                  {roundNormally(teamData.attacking.overall.tries)}
                </Typography>
                <Typography className="value scoring-map-column-gap">
                  {Number(teamData.attacking.overall.triesPerGame).toFixed(2)}
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box className="scoring-map-section">
            <Box className="scoring-map-header">
              <span className={`dot red-dot`}></span>
              <Typography className="scoring-map-section-title">
                {oppositionData.teamName} Defence
              </Typography>
            </Box>
            <Box className="row">
              <Box className="column">
                <Typography className="title">Rank</Typography>
                <Typography className="scoring-map-bigger-stat">
                  {roundWithSuffix(oppositionData.defending.overall.rank)}
                </Typography>
              </Box>
              <Box className="column" id="concede-column">
                <Typography className="title">Conceded</Typography>
                <Typography className="title scoring-map-column-gap">
                  Conceded Per Game
                </Typography>
              </Box>
              <Box className="column">
                <Typography className="value">
                  {roundNormally(oppositionData.defending.overall.concededTries)}
                </Typography>
                <Typography className="value scoring-map-column-gap">
                  {Number(
                    oppositionData.defending.overall.concededPerGame
                  ).toFixed(2)}
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box className="scoring-map-section">
            <Typography className="scoring-map-section-title">
              {teamData.teamName} / {getOppositeSectionName(selectedSection)}{" "}
              {selectedSection === "center" ? "Attack" : "Side Attack"}
            </Typography>

            <Box className="row">
              <Typography className="scoring-map-bigger-stat-title">Rank</Typography>
              <Typography className="scoring-map-bigger-stat-title" id="scoring-map-bigger-stat-tries">Tries</Typography>
              <Typography className="scoring-map-bigger-stat-title">% Total</Typography>
            </Box>
            <Box className="row">
              <Typography className="scoring-map-bigger-stat">
                {roundWithSuffix(sectionData.rank)}
              </Typography>
              <Typography className="scoring-map-bigger-stat">
                {roundNormally(sectionData.tries)}
              </Typography>
              <Typography className="scoring-map-bigger-stat">
                {roundNormally(sectionData.percentageTotal)}%
              </Typography>
            </Box>
            <Box className="scoring-map-subsection">
              <Typography className="scoring-map-section-subtitle">
                Tryscorers here
              </Typography>
              {sectionData.tryScorers.map((player, index) => (
                <Box className="row scoring-map-player-row" key={index}>
                  <img
                    src={getImagePath(
                      `${player.first_name}${player.last_name}`.toLowerCase()
                    )}
                    alt={`${player.first_name} ${player.last_name}`}
                    className="scoring-map-player-image"
                  />
                  <Typography className="scoring-map-player-name">{`${player.first_name} ${player.last_name}`}</Typography>
                  <Typography className="value">
                    {roundNormally(player.scored)} ({roundNormally(player.percentageScoredHere)}
                    %)
                  </Typography>
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default PreMatchScoringMap;