import React from 'react';
import { Route, Routes } from 'react-router-dom';
import LandingPage from './pages/LandingPage/LandingPage';
import PlayersPage from './pages/PlayersPage/PlayersPage';
import TestPage from './pages/TestPage';
import LadderPage from './pages/LadderPage/LadderPage';
import NotFound404 from './pages/NotFoundPage/NotFoundPage';
import InjuriesPage from './pages/InjuriesPage/InjuriesPage';
import PostMatchPage from './pages/PostMatchPage/PostMatchPage';
import PreMatchPage from './pages/PreMatchPage/PreMatchPage';
import TeamPage from './pages/TeamPage/TeamPage';
import AboutUsPage from './pages/AboutUsPage/AboutUsPage';
import ChatBot from './pages/ChatBot/ChatBot';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage/PrivacyPolicyPage';
import AllTeamsPage from './pages/AllTeamsPage/AllTeamsPage';
import OurDataPage from './pages/OurDataPage/OurDataPage';
import TermsOfService from './pages/TermsOfServicesPage/TermsOfServicesPage';

function Site() {
  return (
    <div>
      <Routes>
      <Route path="/" element={<LandingPage />} />
      <Route path="/players" element={<PlayersPage />} />
      <Route path="/test" element={<TestPage />} />
      <Route path="/ladder" element={<LadderPage />} />
      {/* <Route path="/team" element ={<TeamPage />} /> */}
      <Route path="/teams/:teamname" element={<TeamPage />} />
      {/* <Route path="/team2" element ={<TeamPage />} /> */}
      <Route path="*" element ={<NotFound404 />} />
      <Route path="/injuries" element ={<InjuriesPage />} />
      <Route path="/post" element ={<PostMatchPage />} />
      <Route path="/pre" element ={<PreMatchPage />} />
      <Route path="/chat" element ={<ChatBot />} />
      <Route path="/about" element ={<AboutUsPage />} />
      <Route path="/teams" element ={<AllTeamsPage />} />
      <Route path="/ourData" element ={<OurDataPage />} />
      <Route path="/terms" element ={<TermsOfService />} />
      <Route path="/privacyPolicy" element ={<PrivacyPolicyPage />} />
      </Routes>
    </div>
  );
}

export default Site;
