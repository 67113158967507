// hooks/PostMatchDataContext.js
import React, { createContext, useContext } from 'react';

const PostMatchDataContext = createContext(null);

export const usePostMatchDataContext = () => {
  return useContext(PostMatchDataContext);
};

export const PostMatchDataProvider = ({ value, children }) => {
  return (
    <PostMatchDataContext.Provider value={value}>
      {children}
    </PostMatchDataContext.Provider>
  );
};
