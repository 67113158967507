// hooks/usePreMatchData.js
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import config from '../config';

const fetchPreMatchData = async ({ queryKey }) => {
  const [, matchId] = queryKey;
  const response = await axios.get(`${config.backendUrl}/match_info_pre?matchid=${matchId}`);
  return response.data;
};

export const usePreMatchData = (matchId) => {
  return useQuery({
    queryKey: ['preMatchData', matchId],
    queryFn: fetchPreMatchData,
    staleTime: 15 * 60 * 1000, // Set to 0 to always refetch
  });
};