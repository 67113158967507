import React, { useState, useEffect, useRef } from 'react';
import { Box, Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import { usePreMatchDataContext } from '../../../hooks/PreMatchDataContext';
import ShowMoreButton from "../../SharedComponents/ShowMoreButton/ShowMoreButton";
import './FirstTry.css';

const getImagePath = (teamName) => {
  try {
    return require(`./../../../assets/team_logos/${teamName.toLowerCase().replace(/\s/g, '-')}.svg`);
  } catch (error) {
    return require(`./../../../assets/team_logos/bye.png`);
  }
};

const FirstTry = () => {
  const { bettingTeams, matchBasicInfo } = usePreMatchDataContext();
  const [showAll, setShowAll] = useState(false);
  const [contentHeight, setContentHeight] = useState(0);
  const contentRef = useRef(null);

  useEffect(() => {
    if (showAll) {
      setContentHeight(contentRef.current.scrollHeight);
    } else {
      setContentHeight(0);
    }
  }, [showAll]);

  if (!bettingTeams || !bettingTeams.team1 || !bettingTeams.team2 || !matchBasicInfo) return null;

  const toggleShowAll = () => {
    setShowAll(!showAll);
  };

  const renderCollapsedRows = (firstTryData) => firstTryData.slice(0, 7).map((entry, index) => (
    <Box key={index} className="first-try-row">
      <Link to={`/teams/${entry.against.toLowerCase()}`} className="first-try-team"> {/* Make team name clickable */}
        <img
          src={getImagePath(entry.against)}
          alt={entry.against}
          className="first-try-team-logo"
        />
        <Typography className="first-try-team-name">{entry.against}</Typography>
      </Link>
      <Box className="first-try-icon">
        {entry.firstTry ? (
          <CheckCircleIcon className="first-try-success" />
        ) : (
          <CancelIcon className="first-try-failure" />
        )}
      </Box>
      <Typography className="first-try-minute">{entry.minute}</Typography>
    </Box>
  ));

  const renderExpandedRows = (firstTryData) => firstTryData.slice(7).map((entry, index) => (
    <Box key={index} className="first-try-row">
      <Link to={`/teams/${entry.against.toLowerCase()}`} className="first-try-team"> {/* Make team name clickable */}
        <img
          src={getImagePath(entry.against)}
          alt={entry.against}
          className="first-try-team-logo"
        />
        <Typography className="first-try-team-name">{entry.against}</Typography>
      </Link>
      <Box className="first-try-icon">
        {entry.firstTry ? (
          <CheckCircleIcon className="first-try-success" />
        ) : (
          <CancelIcon className="first-try-failure" />
        )}
      </Box>
      <Typography className="first-try-minute">{entry.minute}</Typography>
    </Box>
  ));

  return (
    <Box className="first-try-container">
      <Box className="first-try-column">
        <Box className="first-try-header">
          <Typography className="first-try-header-title column-team-name">{matchBasicInfo.team1.name} vs</Typography>
          <Typography className="first-try-header-title first-try">First Try</Typography>
          <Typography className="first-try-header-title minute">Minute</Typography>
        </Box>
        {renderCollapsedRows(bettingTeams.team1.firstTry)}
        <Box
          ref={contentRef}
          className={`collapsible-content ${showAll ? 'expanded' : 'collapsed'}`}
          sx={{ maxHeight: `${contentHeight}px` }}
        >
          {renderExpandedRows(bettingTeams.team1.firstTry)}
        </Box>
      </Box>
      <Box className="first-try-column">
        <Box className="first-try-header">
          <Typography className="first-try-header-title column-team-name">{matchBasicInfo.team2.name} vs</Typography>
          <Typography className="first-try-header-title first-try">First Try</Typography>
          <Typography className="first-try-header-title minute">Minute</Typography>
        </Box>
        {renderCollapsedRows(bettingTeams.team2.firstTry)}
        <Box
          ref={contentRef}
          className={`collapsible-content ${showAll ? 'expanded' : 'collapsed'}`}
          sx={{ maxHeight: `${contentHeight}px` }}
        >
          {renderExpandedRows(bettingTeams.team2.firstTry)}
        </Box>
      </Box>
      <ShowMoreButton 
        showAll={showAll} 
        onClick={toggleShowAll} 
        className="first-try-show-more-button"
      />
    </Box>
  );
};

export default FirstTry;
