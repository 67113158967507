import React, { useState, useEffect } from "react";
import { Container, CssBaseline, Box, Tab, Typography } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import TeamPageBanner from "../../components/TeamPageComponents/TeamPageBanner/TeamPageBanner";
import TeamPageSquad from "../../components/TeamPageComponents/TeamPageSquad/TeamPageSquad";
import Header from "../../components/SharedComponents/Header/Header";
import TeamsPageOverviewSection from "../../components/TeamPageComponents/TeamsPageOverviewSection/TeamsPageOverviewSection";
import TeamFixtures from "../../components/TeamPageComponents/TeamFixtures/TeamFixtures";
import { TeamDataProvider } from "../../hooks/TeamDataContext";
import { useTeamData } from "../../hooks/useTeamData";
// import TeamsPageScoringMap from "../../components/TeamPageComponents/TeamsPageScoringMap/TeamsPageScoringMap";
import TeamsPageStatsSection from "../../components/TeamPageComponents/TeamsPageStatsSection/TeamsPageStatsSection";
// import TeamTriesLocation from "../../components/TeamPageComponents/TeamTriesLocation/TeamTriesLocation";
import TeamsPageScoringMapSection from "../../components/TeamPageComponents/TeamsPageScoringMapSection/TeamsPageScoringMapSection";
import Footer from "../../components/SharedComponents/Footer/Footer";
import TeamPageLoadingSkeleton from "../../components/TeamPageComponents/TeamPageLoadingSkeleton/TeamPageLoadingSkeleton";
import "./TeamPage.css";

const teamNameToId = {
  "roosters": 1,
  "sea-eagles": 2,
  "rabbitohs": 3,
  "panthers": 4,
  "cowboys": 5,
  "storm": 6,
  "bulldogs": 7,
  "broncos": 8,
  "warriors": 9,
  "eels": 10,
  "dragons": 11,
  "knights": 12,
  "wests-tigers": 13,
  "titans": 14,
  "raiders": 15,
  "sharks": 16,
  "dolphins": 17
};
const TeamPage = () => {
  const { teamname } = useParams();
  console.log("teamname", teamname)
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const initialTab = searchParams.get("tab") || "overview";

  const [value, setValue] = useState(initialTab);
  const teamId = teamNameToId[teamname.toLowerCase()];
  console.log("teamId: ", teamId);

  const { data, isLoading, isError } = useTeamData(teamId);

  useEffect(() => {
    console.log("hello inside teampage");
    if (data) {
      console.log("Team Data:", data);
    }
  }, [data]);

  useEffect(() => {
    setValue(initialTab);
  }, [initialTab]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    searchParams.set("tab", newValue);
    navigate({ search: searchParams.toString() }, { replace: true });
  };

  return (
    <div className="team-page-root">
      <div className="scrollable-content">
        <TeamDataProvider value={data}>
          <Container component="main" className="main-container">
            <CssBaseline />
            <Header />
            {isLoading ? (
              <TeamPageLoadingSkeleton />
            ) : isError ? (
              <Typography>Error loading team data</Typography>
            ) : (
              <Box className="team-page-container main-content-box">
                <Box className="row team-page-row1">
                  <TeamPageBanner />
                </Box>
                <Box className="team-page-row2">
                  <Box className="team-page-column1">
                    <Box className="team-page-content">
                      <TabContext value={value}>
                        <Box
                          sx={{ borderBottom: 1, borderColor: "divider" }}
                          className="scrollable-tabs-container"
                        >
                          <TabList
                            onChange={handleChange}
                            aria-label="team tabs"
                            variant="scrollable"
                            scrollButtons={false}
                            TabIndicatorProps={{
                              style: { backgroundColor: "#969696" },
                            }}
                            className="scrollable-tabs"
                          >
                            <Tab
                              label="Overview"
                              value="overview"
                              className="team-page-tabs match-page-tabs"
                            />
                            <Tab
                              label="Scoring Map"
                              value="scoring-map"
                              className="team-page-tabs match-page-tabs"
                            />
                            <Tab
                              label="Fixtures"
                              value="fixtures"
                              className="team-page-tabs match-page-tabs"
                            />
                            <Tab
                              label="Stats"
                              value="stats"
                              className="team-page-tabs match-page-tabs"
                            />
                          </TabList>
                        </Box>
                        <TabPanel value="overview">
                          <TeamsPageOverviewSection />
                        </TabPanel>
                        <TabPanel value="scoring-map">
                          <TeamsPageScoringMapSection />
                        </TabPanel>
                        <TabPanel value="fixtures">
                          <TeamFixtures />
                        </TabPanel>
                        <TabPanel value="stats">
                          <TeamsPageStatsSection/>
                        </TabPanel>
                      </TabContext>
                    </Box>
                  </Box>
                  <Box className="team-page-column2">
                    <TeamPageSquad />
                  </Box>
                </Box>
              </Box>
            )}
          </Container>
        </TeamDataProvider>
        <Footer />
      </div>
    </div>
  );
};

export default TeamPage;