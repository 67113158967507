import React from "react";
import { Box, Typography } from "@mui/material";
import { Link } from "react-router-dom"; // Import Link from react-router-dom
import { useTeamDataContext } from "../../../hooks/TeamDataContext"; // Import the context hook
import "./TeamPageSquad.css";

const getImagePath = (imageName) => {
  try {
    return require(`../../../assets/${imageName}.png`);
  } catch (error) {
    return require(`../../../assets/lineupPlayer.png`);
  }
};

const TeamPageSquad = () => {
  const data = useTeamDataContext(); // Use the context hook to get data
  if (!data || !data.teamPlayers) return null; // Return null if data is not available

  const { teamPlayers } = data; // Extract teamPlayers from context data

  return (
    <Box className="team-squad-container">
      <Typography className="h1-text team-squad-title">Team List</Typography>
      <Box className="team-squad-grid">
        <Box className="team-squad-header">
          <Typography className="team-squad-header-item">Name</Typography>
          <Typography className="team-squad-header-item">Played</Typography>
          <Typography className="team-squad-header-item">Tries</Typography>
        </Box>
        {teamPlayers.map((player, index) => (
          <Link to="/players" key={index} className="team-squad-row-link"> {/* Wrap with Link */}
            <Box className="team-squad-row">
              <Box className="team-squad-player">
                <img
                  src={getImagePath("player_icon")} // Assuming the icon file is named "player_icon.png"
                  alt="player"
                  className="team-squad-player-icon"
                />
                <Typography className="team-squad-player-name">
                  {player.first_name} {player.last_name}
                </Typography>
              </Box>
              <Typography className="team-squad-player-stats">{player.games_played}</Typography>
              <Typography className="team-squad-player-stats">{player.tries}</Typography>
            </Box>
          </Link>
        ))}
      </Box>
    </Box>
  );
};

export default TeamPageSquad;
