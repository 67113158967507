import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTeamDataContext } from '../../../hooks/TeamDataContext';
import './TeamPageNextMatch.css';

const getImagePath = (imageName) => {
  try {
    return require(`./../../../assets/team_logos/${imageName.toLowerCase().replace(/\s/g, '-')}.svg`);
  } catch (error) {
    return require(`./../../../assets/lineupPlayer.png`);
  }
};

// Custom function to format date as "Sun 15 Sep"
const formatDate = (date) => {
  const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  const dayName = days[date.getUTCDay()];
  const day = date.getUTCDate();
  const monthName = months[date.getUTCMonth()];

  return `${dayName} ${day} ${monthName}`;
};

const TeamPageNextMatch = () => {
  const data = useTeamDataContext();
  if (!data || !data.teamFixtures) return null;

  const { teamFixtures } = data;

  // Find the next match (first match with channel9 not null)
  const nextMatch = Object.values(teamFixtures)
    .flat()
    .find(match => match.channel9 !== null);

  if (!nextMatch) {
    return (
      <Box className="next-match-container column">
        <Box className='next-match-title-box'>
          <Typography className="subheader-text next-match-title h1-text-team-overview">Next Match</Typography>
        </Box>
        <Typography variant="body1">No upcoming match</Typography>
      </Box>
    );
  }

  const { team_1, team_2, datetime, match_id } = nextMatch;
  const matchDate = new Date(datetime);

  // Custom date formatting
  const formattedDate = formatDate(matchDate);

  const formattedTime = matchDate.toLocaleTimeString('en-GB', {
    hour: 'numeric',
    minute: '2-digit',
    hour12: true,
    timeZone: 'UTC',
  });

  // Route to the prematch page
  const route = `/pre?matchid=${match_id}`;

  return (
    <Box
      className="next-match-container column"
      onClick={() => window.location.href = route} // Redirect to prematch page
      style={{ cursor: 'pointer' }} // Change cursor to pointer to indicate clickable
    >
      <Box className='next-match-title-box'>
        <Typography className="subheader-text h1-text-team-overview">Next Match</Typography>
      </Box>
      <Box className="next-match-content">
        {/* Team 1 */}
        <Box className="next-match-team">
          <img src={getImagePath(team_1.name)} alt={team_1.name} className="next-match-team-logo" />
          <Typography variant="body1" className="next-match-team-name">{team_1.name}</Typography>
        </Box>

        {/* Match Time */}
        <Box className="next-match-time">
          <Typography variant="h5" className="next-match-time-text">{formattedTime}</Typography>
          <Typography variant="body2" className="next-match-day">{formattedDate}</Typography>
        </Box>

        {/* Team 2 */}
        <Box className="next-match-team">
          <img src={getImagePath(team_2.name)} alt={team_2.name} className="next-match-team-logo" />
          <Typography variant="body1" className="next-match-team-name">{team_2.name}</Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default TeamPageNextMatch;
