// CustomTabs.js
import React from 'react';
import './CustomTabs.css';

const CustomTabs = ({ tabs, activeTab, onTabClick }) => {
  return (
    <div className="custom-tabs">
      {tabs.map((tab, index) => (
        <button
          key={index}
          className={`custom-tab ${activeTab === index ? 'active' : ''}`}
          onClick={() => onTabClick(index)}
        >
          {tab}
        </button>
      ))}
    </div>
  );
};

export default CustomTabs;
