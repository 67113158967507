import React, { createContext, useContext } from 'react';

const TeamColorsContext = createContext();

export const useTeamColors = () => useContext(TeamColorsContext);

export const TeamColorsProvider = ({ children }) => {
  const teamColors = {
    "teamColours": [
      {"team_id": 1, "team_name_short": "Roosters", "colour": "#132951"},
      {"team_id": 2, "team_name_short": "Sea Eagles", "colour": "#76174d"},
      {"team_id": 3, "team_name_short": "Rabbitohs", "colour": "#169850"},
      {"team_id": 4, "team_name_short": "Panthers", "colour": "#383838"},
      {"team_id": 5, "team_name_short": "Cowboys", "colour": "#090b51"},
      {"team_id": 6, "team_name_short": "Storm", "colour": "#62058a"},
      {"team_id": 7, "team_name_short": "Bulldogs", "colour": "#1f5ac7"},
      {"team_id": 8, "team_name_short": "Broncos", "colour": "#3e0315"},
      {"team_id": 9, "team_name_short": "Warriors", "colour": "#141414"},
      {"team_id": 10, "team_name_short": "Eels", "colour": "#ebc321"},
      {"team_id": 11, "team_name_short": "Dragons", "colour": "#f13123"},
      {"team_id": 12, "team_name_short": "Knights", "colour": "#110d95"},
      {"team_id": 13, "team_name_short": "Wests Tigers", "colour": "#fb7005"},
      {"team_id": 14, "team_name_short": "Titans", "colour": "#10b6e3"},
      {"team_id": 15, "team_name_short": "Raiders", "colour": "#26d337"},
      {"team_id": 16, "team_name_short": "Sharks", "colour": "#0d9ac1"},
      {"team_id": 17, "team_name_short": "Dolphins", "colour": "#980a0a"}
    ]
  };

  return (
    <TeamColorsContext.Provider value={teamColors}>
      {children}
    </TeamColorsContext.Provider>
  );
};