// hooks/useTeamData.js
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import config from '../config';

const fetchTeamData = async ({ queryKey }) => {
  const [, teamId] = queryKey;
  const response = await axios.get(`${config.backendUrl}/team_info?teamid=${teamId}`);
  return response.data;
};

export const useTeamData = (teamId) => {
  return useQuery({
    queryKey: ['teamData', teamId],
    queryFn: fetchTeamData,
    staleTime: 24 * 60 * 60 * 1000,
  });
};