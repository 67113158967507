import React from "react";
import { Container, CssBaseline, Box, Typography, Divider } from "@mui/material";
import Header from "../../components/SharedComponents/Header/Header";
import Footer from "../../components/SharedComponents/Footer/Footer";
import "./AllTeamsPage.css";

// Function to get image path for team logos
const getImagePath = (imageName) => {
  try {
    return require(`./../../assets/team_logos/${imageName.toLowerCase().replace(/\s/g, '-')}.svg`);
  } catch (error) {
    return require(`./../../assets/lineupPlayer.png`);
  }
};

// Function to capitalise the first letter of each word
const capitalizeTeamName = (teamName) => {
  return teamName
    .replace('-', ' ') // Replace hyphens with spaces
    .split(' ') // Split the name into words
    .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize each word
    .join(' '); // Join the words back together
};

const AllTeamsPage = () => {
  // Team data with corresponding IDs
  const teamNameToId = {
    "roosters": 1,
    "sea-eagles": 2,
    "rabbitohs": 3,
    "panthers": 4,
    "cowboys": 5,
    "storm": 6,
    "bulldogs": 7,
    "broncos": 8,
    "warriors": 9,
    "eels": 10,
    "dragons": 11,
    "knights": 12,
    "wests-tigers": 13,
    "titans": 14,
    "raiders": 15,
    "sharks": 16,
    "dolphins": 17
  };

  return (
    <div className="all-teams-page-root">
      <div className="scrollable-content">
        <Container component="main" className="all-teams-main-container main-container">
          <CssBaseline />
          <Header />
          <Box className="all-teams-container main-content-box">
            <Box className="all-teams-banner">
              <Typography className="all-teams-banner-title">All Teams</Typography>
            </Box>
            <Box className="all-teams-teams-box">
              {Object.keys(teamNameToId).map((teamName, index) => (
                <React.Fragment key={teamName}>
                  <Box
                    className="all-teams-row"
                    component="a"
                    href={`/teams/${teamName}`}
                  >
                    <img
                      src={getImagePath(teamName)}
                      alt={teamName}
                      className="all-teams-logo"
                    />
                    <Typography className="all-teams-name-text">
                      {capitalizeTeamName(teamName)}
                    </Typography>
                  </Box>
                  {index < Object.keys(teamNameToId).length - 1 && (
                    <Divider className="all-teams-divider" />
                  )}
                </React.Fragment>
              ))}
            </Box>
          </Box>
        </Container>
        <Footer />
      </div>
    </div>
  );
};

export default AllTeamsPage;
