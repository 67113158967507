import React from 'react';
import { Box, Button, Divider, IconButton, MenuItem, Select, Typography } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import FilterListIcon from '@mui/icons-material/FilterList';
import './MatchesTableSearchBar.css';

const MatchesTableSearchBar = ({ 
    selectedSeason, 
    onSeasonChange, 
    selectedRound, 
    onRoundChange, 
    selectedTeam,
    onTeamChange,
    teamsList,
    totalRounds 
}) => {
  const handleSeasonChange = (event) => {
    onSeasonChange(event.target.value);
  };

  const handleRoundChange = (event) => {
    onRoundChange(event.target.value);
  };

  const handleTeamChange = (event) => {
    onTeamChange(event.target.value);
  };

  const handlePreviousRound = () => {
    if (selectedRound > 1) {
      onRoundChange(selectedRound - 1);
    }
  };

  const handleNextRound = () => {
    if (selectedRound < totalRounds) {
      onRoundChange(selectedRound + 1);
    }
  };

  const getRoundLabel = (roundNumber) => {
    const finalsRounds = totalRounds - 4;
    switch (roundNumber) {
      case finalsRounds + 1:
        return 'Finals Week 1';
      case finalsRounds + 2:
        return 'Semi Final';
      case finalsRounds + 3:
        return 'Preliminary Final';
      case finalsRounds + 4:
        return 'Grand Final';
      default:
        return `Round ${roundNumber}`;
    }
  };

  return (
    <Box className="search-bar-container">
      <Box className="matches-search-round-selection">
        <IconButton className="back-button-box backward-button-box" onClick={handlePreviousRound}>
          <ArrowBackIosIcon className="back-button-icon"/>
        </IconButton>
        <Select 
          variant="standard" 
          value={selectedRound}
          onChange={handleRoundChange}
          className="round-dropdown matches-table-round-select"
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 300,
              },
            },
          }}
        >
          {[...Array(totalRounds).keys()].map(i => (
            <MenuItem key={i + 1} value={i + 1}>{getRoundLabel(i + 1)}</MenuItem>
          ))}
        </Select>
        <IconButton className="back-button-box forward-button-box"  onClick={handleNextRound}>
          <ArrowForwardIosIcon className="back-button-icon"/>
        </IconButton>
      </Box>
      <Divider />
      <Box className="filters-container">
        <Box className="filter-dropdown tag">
          <Select 
            variant="standard" 
            value={selectedSeason} 
            onChange={handleSeasonChange}
            className="filter-dropdown-field-label matches-table-year-select"
            // style={{ border: '2px solid blue' }}
            // style={{
            //   border: '2px solid blue',
            //   borderRadius: '4px',
            //   zIndex: 100000,
            //   position: 'relative',
            //   overflow: 'hidden'
            // }}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 300,
                },
              },
            }}
          >
            {[...Array(2024 - 2013 + 1).keys()].map(i => (
              <MenuItem key={2013 + i} value={2013 + i}>{2013 + i}</MenuItem>
            ))}
          </Select>
        </Box>
        <Box className="filter-dropdown tag">
          <Select 
            variant="standard" 
            value={selectedTeam}
            onChange={handleTeamChange}
            className="filter-dropdown-field-label matches-table-team-select"
            displayEmpty
            renderValue={(value) => value === '' ? 'All' : value}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 300,
                },
              },
            }}
          >
            <MenuItem value="">All</MenuItem>
            {teamsList.map(team => (
              <MenuItem key={team} value={team}>{team}</MenuItem>
            ))}
          </Select>
        </Box>
        <Box className="filter-button tag">
          <Button variant="text" startIcon={<FilterListIcon className="filter-button-label"/>} className="matches-table-filter-button">
            <Typography className="filter-button-label">
            Filter
            </Typography>
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default MatchesTableSearchBar;