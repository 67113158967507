import React, { useState } from 'react';
import { Box, Typography, Divider } from '@mui/material';
import { useNavigate } from 'react-router-dom'; // Import useNavigate from react-router-dom
import { usePreMatchDataContext } from '../../../hooks/PreMatchDataContext';
import ShowMoreButton from '../../SharedComponents/ShowMoreButton/ShowMoreButton';
import './PastRoundsForTwoTeams.css';

const getImagePath = (imageName) => {
  try {
    return require(`./../../../assets/team_logos/${imageName.toLowerCase().replace(/\s/g, '-')}.svg`);
  } catch (error) {
    return require(`./../../../assets/lineupPlayer.png`);
  }
};

const MatchCard = ({ match }) => {
  const team1Logo = getImagePath(match.team1.name);
  const team2Logo = getImagePath(match.team2.name);
  const navigate = useNavigate(); // Use useNavigate hook

  const handleClick = () => {
    navigate(`/post?matchid=${match.matchId}`); // Navigate to the post-match page
  };

  return (
    <Box
      className="postmatch-pastrounds-match-card"
      onClick={handleClick} // Handle click event
      style={{ cursor: 'pointer' }} // Add cursor pointer to indicate clickable
    >
      <Typography variant="subtitle1" className="postmatch-pastrounds-round-info">
        {`Round ${match.round}, ${match.season}`}
      </Typography>
      <Box className="postmatch-pastrounds-match-content">
        <Box className="postmatch-pastrounds-team-column">
          <Typography className="postmatch-pastrounds-team-name">
            {match.team1.name}
          </Typography>
          <img src={team1Logo} alt={match.team1.name} className="postmatch-pastrounds-team-logo" />
        </Box>
        <Typography className="postmatch-pastrounds-score">
          {`${match.team1.score} - ${match.team2.score}`}
        </Typography>
        <Box className="postmatch-pastrounds-team-column">
          <img src={team2Logo} alt={match.team2.name} className="postmatch-pastrounds-team-logo" />
          <Typography className="postmatch-pastrounds-team-name">
            {match.team2.name}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

const PastRoundsForTwoTeams = () => {
  const [showAll, setShowAll] = useState(false);
  const data = usePreMatchDataContext();
  const { matchForm } = data;

  if (!matchForm || !matchForm.headToHead) return null;

  const handleToggleShow = () => {
    setShowAll(!showAll);
  };

  const matchesToShow = showAll ? matchForm.headToHead : matchForm.headToHead.slice(0, 15);

  return (
    <Box className="postmatch-pastrounds-container">
      {matchesToShow.map((match, index) => (
        <React.Fragment key={match.matchId}>
          <MatchCard match={match} />
          {index < matchesToShow.length - 1 && <Divider className="row-divider" />}
        </React.Fragment>
      ))}
      {matchForm.headToHead.length > 15 && (
        <ShowMoreButton 
          showAll={showAll} 
          onClick={handleToggleShow} 
          className="postmatch-pastrounds-toggle-button"
        />
      )}
    </Box>
  );
};

export default PastRoundsForTwoTeams;
