import React from 'react';
import 'semantic-ui-css/semantic.min.css'
import './App.css';
import Site from './Site';
import { BrowserRouter as Router } from 'react-router-dom';
import '@fontsource/montserrat';

function App() {
  return (
    <Router>
      <Site />
    </Router>
  );
}

export default App;