import React from "react";
import { Box, Typography, Divider, useMediaQuery } from "@mui/material";
import TeamTriesLocation from "../TeamTriesLocation/TeamTriesLocation";
import TeamsPageScoringMap from "../TeamsPageScoringMap/TeamsPageScoringMap";
import ConversionsGraph from "../ConversionsGraph/ConversionsGraph";
// import V2TeamTriesLocation from "../V2TeamTriesLocation/V2TeamTriesLocation";
import "./TeamsPageScoringMapSection.css";

const TeamsPageScoringMapSection = () => {
  const isSmallScreen = useMediaQuery("(max-width: 700px)");

  return (
    <Box className="teams-page-teams-page-scoring-map-section-section">
      <Box className="teams-page-scoring-map-section-layout">
        <Box className="teams-page-scoring-map-section-row9">
          <Box className="row">
            <Typography className="subheader-text h1-text-team-overview">
              Conversions Events
            </Typography>
          </Box>
          <ConversionsGraph />
        </Box>
        <Divider className="teams-page-scoring-section-divider" />
        {isSmallScreen ? (
          <Box className="teams-page-scoring-map-section-row">
            <TeamTriesLocation />
          </Box>
        ) : (
          <Box className="teams-page-scoring-map-section-row">
            {/* <V2TeamTriesLocation /> */}
            <TeamTriesLocation />
          </Box>
        )}

        <Divider className="teams-page-scoring-section-divider" />
        {/* <Box className="teams-page-scoring-map-section-row">
        <Box className="teams-page-scoring-map-section-left-column">
          <TeamTriesLocation />
        </Box>
        <Divider flexItem className="postmatch-matchplayerstats-vertical-divider" />
        <Box className="teams-page-scoring-map-section-right-column">
          <Typography className="h1-text h1-text-team-overview">
            Conversions Events
          </Typography>
          <ConversionsGraph/>
        </Box>
        </Box> */}
      </Box>
      <TeamsPageScoringMap />
    </Box>
  );
};

export default TeamsPageScoringMapSection;
