import React from 'react';
import { Modal, Box, Typography, Avatar, Divider, Fade } from '@mui/material';
import { usePostMatchDataContext } from '../../../hooks/PostMatchDataContext';
import './PlayerModal.css';

const PlayerModal = ({ open, handleClose, player }) => {
  const postMatchData = usePostMatchDataContext();
  const { matchStats, matchBasicInfo } = postMatchData;

  const playerStats = matchStats.playersStats.players.find(p => p.player_id === player.player_id);

  if (!playerStats) {
    return null;
  }

  const getTeamName = (teamId) => {
    if (matchBasicInfo.team1.id === teamId) return matchBasicInfo.team1.name;
    if (matchBasicInfo.team2.id === teamId) return matchBasicInfo.team2.name;
    return 'Unknown Team';
  };

  const teamName = getTeamName(playerStats.team_id);

  return (
    <Modal open={open} onClose={handleClose} closeAfterTransition>
      <Fade in={open} timeout={{ enter: 500, exit: 400 }}>
        <Box className="player-modal">
          <Box className="player-modal-header">
            <Avatar src={`/path/to/player/image/${playerStats.player_id}.jpg`} alt={`${playerStats.first_name} ${playerStats.last_name}`} className="player-modal-image" />
            <Typography variant="h6" className="player-modal-name">{`${playerStats.first_name} ${playerStats.last_name}`}</Typography>
            <Typography className="player-modal-info">{`${player.position_name} / ${teamName} / #${player.number}`}</Typography>
          </Box>
          <Divider className="player-modal-divider" />
          <Box className="player-modal-section">
            <Typography className="player-modal-subsection-title">Attack</Typography>
            <Box className="player-modal-stat-row">
              <Typography className="player-modal-stat-type">Minutes Played</Typography>
              <Typography className="player-modal-stat-value">{playerStats.mins_played || 'N/A'}</Typography>
            </Box>
            <Box className="player-modal-stat-row">
              <Typography className="player-modal-stat-type">Tries Scored</Typography>
              <Typography className="player-modal-stat-value">{playerStats.tries || 0}</Typography>
            </Box>
            <Box className="player-modal-stat-row">
              <Typography className="player-modal-stat-type">Points</Typography>
              <Typography className="player-modal-stat-value">{playerStats.points || 0}</Typography>
            </Box>
            <Box className="player-modal-stat-row">
              <Typography className="player-modal-stat-type">Fantasy Points</Typography>
              <Typography className="player-modal-stat-value">{playerStats.fantasy_points || 0}</Typography>
            </Box>
            <Box className="player-modal-stat-row">
              <Typography className="player-modal-stat-type">All Run Metres</Typography>
              <Typography className="player-modal-stat-value">{playerStats.all_run_metres || 0}</Typography>
            </Box>
          </Box>
          <Divider className="player-modal-divider" />
          <Box className="player-modal-section">
            <Typography className="player-modal-subsection-title">Defence</Typography>
            <Box className="player-modal-stat-row">
              <Typography className="player-modal-stat-type">Tackles Made</Typography>
              <Typography className="player-modal-stat-value">{playerStats.tackles_made || 0}</Typography>
            </Box>
            <Box className="player-modal-stat-row">
              <Typography className="player-modal-stat-type">Tackle Efficiency</Typography>
              <Typography className="player-modal-stat-value">{playerStats.tackle_efficiency ? `${playerStats.tackle_efficiency.toFixed(2)}%` : 'N/A'}</Typography>
            </Box>
          </Box>
          <Divider className="player-modal-divider" />
          <Box className="player-modal-section">
            <Typography className="player-modal-subsection-title">Discipline</Typography>
            <Box className="player-modal-stat-row">
              <Typography className="player-modal-stat-type">Errors</Typography>
              <Typography className="player-modal-stat-value">{playerStats.errors || 0}</Typography>
            </Box>
            <Box className="player-modal-stat-row">
              <Typography className="player-modal-stat-type">Penalties</Typography>
              <Typography className="player-modal-stat-value">{playerStats.penalties || 0}</Typography>
            </Box>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};

export default PlayerModal;