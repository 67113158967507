import React from "react";
import { Container, CssBaseline, Box, Typography, Link } from "@mui/material";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import InstagramIcon from '@mui/icons-material/Instagram';
import Header from "../../components/SharedComponents/Header/Header";
import Footer from "../../components/SharedComponents/Footer/Footer";
import "./AboutUsPage.css";

function AboutUsPage() {
  return (
    <div className="about-page-root">
      <div className="scrollable-content">
        <div className="about-us-outer-container">
          <Container component="main" className="main-container">
            <CssBaseline />
            <Header />
            <Box className="about-us-page-container main-content-box">
              <Typography className="about-us-header-text" component="h1" gutterBottom>
                <Link
                  href="https://www.instagram.com/trylineapp/"
                  target="_blank"
                  rel="noopener"
                  sx={{ display: 'flex', alignItems: 'center' }}
                >
                  <InstagramIcon sx={{ marginRight: '5px' }} /> Instagram - @trylineapp
                </Link>
              </Typography>

              <Typography className="about-us-header-text2" component="h2" gutterBottom>
                <Link
                  href="mailto:admin@tryline.com.au"
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <EmailOutlinedIcon sx={{ marginRight: "5px" }} /> admin@tryline.com.au
                </Link>
              </Typography>

              <Typography variant="h6" className="about-us-text">
                Follow our instagram for announcements on new features + match analysis!
              </Typography>


              <Typography className="about-us-header-text" component="h1" gutterBottom>
                About Us
              </Typography>
              <Typography variant="body1" className="about-us-text" paragraph>
                Tryline is developed by a team of footy enthusiasts, created as a free tool for the general public, for private use.
              </Typography>
              <Typography variant="body1" className="about-us-text" paragraph>
                Finding information about your team and matches is now easier.
              </Typography>
              <Typography variant="body1" className="about-us-text" paragraph>
                We don't believe that simple data analysis should be placed behind paywalls.
              </Typography>
              <Typography variant="body1" className="about-us-text" paragraph>
                For the average watcher of the game, you also might not have that much time to understand documents with rows of data.
              </Typography>
              <Typography variant="body1" className="about-us-text" paragraph>
                If we helped save you time, or helped you make a more informed bet, then our product worked.
              </Typography>
              <Typography variant="body1" className="about-us-gambling-text" paragraph>
                We did all the dirty work, so you can spend less time crunching numbers.
              </Typography>

              <Typography className="about-us-header-text" component="h1" gutterBottom>
                Tryline Development Roadmap
              </Typography>
              <Typography variant="body1" className="about-us-text" paragraph>
                2024 Season - General Website Improvement
              </Typography>
              <Typography variant="body1" className="about-us-text" paragraph>
                2024 Season - Preview Lineup Page Improvement - Design and data accuracy
              </Typography>
              <Typography variant="body1" className="about-us-text" paragraph>
                2024 Season - Integration of players, teams, injuries and statistics pages
              </Typography>
              <Typography variant="body1" className="about-us-text" paragraph>
                2025 Preseason - Something cool in January
              </Typography>
              <Typography variant="body1" className="about-us-text" paragraph>
                2025 Onwards - We can't publicy share our full plan, all we can say is be excited! Really excited!
              </Typography>
              <Typography variant="body1" className="about-us-gambling-text" paragraph>
                If you would like more information about our development or have suggestions, please email admin@tryline.com.au.
              </Typography>

              <Typography className="about-us-header-text" component="h1" gutterBottom>
                Privacy Policy
              </Typography>
              <Typography variant="body1" className="about-us-text" paragraph>
                Our servers automatically collect data like your IP address, browser type and usage patterns, just like every other website.
              </Typography>
              <Typography variant="body1" className="about-us-text" paragraph>
                We use this information to help us determine what we can improve on our site.
              </Typography>
              <Typography variant="body1" className="about-us-text" paragraph>
                We reserve the right to terminate your access to our site if it is determined that you are using our website with the intent to cause financial loss or harm.
              </Typography>
              <Typography variant="body1" className="about-us-text" paragraph>
                Tryline does not collect any other information about you at this time.
              </Typography>
              <Typography variant="body1" className="about-us-text" paragraph>
                We would like to specifically let you know that should login systems and advertisements be implemented on our website, the privacy policy will change and you will be notified when visiting the page.
              </Typography>
              <Typography variant="body1" className="about-us-text" paragraph>
                Tryline reserves the right to make changes to this privacy policy at any time and will notify users when visiting the site should the privacy policy change.
              </Typography>
              <Typography variant="body1" className="about-us-gambling-text" paragraph>
                If you would like more information about our privacy policy, please email admin@tryline.com.au.
              </Typography>

              <Typography className="about-us-header-text" component="h1" gutterBottom>
                Terms of Service
              </Typography>
              <Typography variant="body1" className="about-us-text" paragraph>
                Tryline is a platform developed by footy enthusiasts to provide free, accessible rugby league information and data analysis to fans. By using Tryline, you agree to the following terms:
              </Typography>

              <Typography className="about-us-section-header-tos" gutterBottom>
                Purpose and Community Engagement
              </Typography>
              <Typography variant="body1" className="about-us-text" paragraph>
                Tryline aims to foster community engagement and grow the game of rugby league by providing easy-to-understand team and match information. Users are encouraged to participate in discussions and share insights responsibly.
              </Typography>

              <Typography className="about-us-section-header-tos" gutterBottom>
                Data Usage
              </Typography>
              <Typography variant="body1" className="about-us-text" paragraph>
                Information on Tryline is sourced from publicly available data and our own analysis. While we strive for accuracy, users access and use this information at their own risk.
              </Typography>

              <Typography className="about-us-section-header-tos" gutterBottom>
                Private and Entertainment Use
              </Typography>
              <Typography variant="body1" className="about-us-text" paragraph>
                Tryline's content is intended for private, non-commercial use and entertainment purposes only. Users should not rely on this information for professional advice or decision-making.
              </Typography>

              <Typography className="about-us-section-header-tos" gutterBottom>
                Gambling Disclaimer
              </Typography>
              <Typography variant="body1" className="about-us-text" paragraph>
                Tryline strongly discourages using our data or analysis for gambling purposes. Users acknowledge that gambling carries inherent risks, and Tryline accepts no responsibility for any losses incurred.
              </Typography>

              <Typography className="about-us-section-header-tos" gutterBottom>
                User Conduct
              </Typography>
              <Typography variant="body1" className="about-us-text" paragraph>
                Users agree to engage respectfully with the community, refraining from posting offensive, unlawful, or discriminatory content. Tryline reserves the right to moderate or remove inappropriate content.
              </Typography>

              <Typography className="about-us-section-header-tos" gutterBottom>
                Data Usage and Scraping
              </Typography>
              <Typography variant="body1" className="about-us-text" paragraph>
                Tryline uses a range of publicly available data and adds value such as analysis and visualization. Use of the data, content, or any information displayed on Tryline for any purpose, including but not limited to scraping, reproduction, redistribution, or commercial purposes, without the express written consent of Tryline is strictly prohibited.
              </Typography>

              <Typography className="about-us-section-header-tos" gutterBottom>
                Third-Party Links
              </Typography>
              <Typography variant="body1" className="about-us-text" paragraph>
                Tryline only contains external links to the official NRL Website. In the future, Tryline may contain external links to reputable news sources. We are not responsible for the content or practices of these sites.
              </Typography>

              <Typography className="about-us-section-header-tos" gutterBottom>
                Disclaimer of Warranties
              </Typography>
              <Typography variant="body1" className="about-us-text" paragraph>
                Tryline provides its services "as is" without any warranties, express or implied. We do not guarantee the accuracy, completeness, or reliability of our data.
              </Typography>

              <Typography className="about-us-section-header-tos" gutterBottom>
                Limitation of Liability
              </Typography>
              <Typography variant="body1" className="about-us-text" paragraph>
                Tryline and its team are not liable for any damages or losses resulting from the use of our platform or reliance on our information.
              </Typography>

              <Typography className="about-us-section-header-tos" gutterBottom>
                Changes to Terms
              </Typography>
              <Typography variant="body1" className="about-us-text" paragraph>
                Tryline reserves the right to modify these terms at any time. Continued use of the platform constitutes acceptance of any changes.
              </Typography>

              <Typography className="about-us-section-header-tos" gutterBottom>
                Arbitration
              </Typography>
              <Typography variant="body1" className="about-us-text" paragraph>
                If you encounter any issues or disagreements with Tryline, we encourage you to reach out to us directly. We are committed to addressing and resolving disputes in good faith through informal discussions.
              </Typography>
              <Typography variant="body1" className="about-us-text" paragraph>
                By using our services, you agree to resolve any dispute with us on an individual basis through arbitration. This means that any dispute will be resolved through arbitration, and not as part of a class arbitration, class action, or any other type of consolidated or representative proceeding.
              </Typography>

              <Typography className="about-us-gambling-text" paragraph>
                By using Tryline, you acknowledge that you have read, understood, and agree to be bound by these Terms of Service.
              </Typography>
            </Box>
          </Container>
        </div>
          <Footer></Footer>
      </div>
    </div>
  );
}

export default AboutUsPage;