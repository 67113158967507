import React from 'react';
import { Container, CssBaseline } from '@mui/material';
import Header from '../../components/SharedComponents/Header/Header';
import UnderConstructionMessage from '../../components/SharedComponents/UnderConstructionMessage/UnderConstructionMessage'; // Import the UnderConstructionMessage component
import Footer from '../../components/SharedComponents/Footer/Footer';
import './PlayersPage.css'; 


function PlayersPage() {
  return (
    <div className="players-page-root">
      <div className="scrollable-content">
        <Container component="main" className="main-container">
          <CssBaseline />
          <Header />
          <UnderConstructionMessage /> {/* Render the UnderConstructionMessage */}
        </Container>
        <Footer></Footer>
      </div>
    </div>
  );
}

export default PlayersPage;
