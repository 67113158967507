import React, { useMemo, useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  ReferenceLine,
  CartesianGrid,
} from "recharts";
import { Box, Typography, useMediaQuery, Paper } from "@mui/material";
import { useTeamDataContext } from "../../../hooks/TeamDataContext"; // Import the context hook
import { useTeamColors } from "../../../hooks/TeamColorsContext"; // Import the team colors hook
import "./TeamPageTrajectoryLadder.css";

// Custom tooltip component
const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <Paper
        elevation={3}
        sx={{ padding: 2, backgroundColor: "#f5f5f5", maxWidth: 300 }}
      >
        <Typography variant="subtitle1" gutterBottom>
          Year {label}
        </Typography>
        {payload.map((entry, index) => (
          <Box
            key={`item-${index}`}
            sx={{ display: "flex", justifyContent: "space-between", mb: 1 }}
          >
            <Typography variant="body2" sx={{ color: entry.color }}>
              {entry.name}
            </Typography>
            <Typography variant="body2">{entry.value}</Typography>
          </Box>
        ))}
      </Paper>
    );
  }
  return null;
};

// Custom dot component to display positions inside circles
const CustomDot = ({
  cx,
  cy,
  value,
  isSelected,
  onClick,
  teamColor,
  isSmallScreen,
}) => {
  // Adjust the circle radius and text size based on screen size
  const circleRadius = isSmallScreen ? 10 : 15; // Smaller circle for small screens
  const textSize = isSmallScreen ? "10" : "12"; // Smaller text for small screens

  return (
    <g onClick={onClick} style={{ cursor: "pointer" }}>
      <circle
        cx={cx}
        cy={cy}
        r={circleRadius}
        fill={isSelected ? teamColor : "white"} // Highlight circle with team color when selected
        stroke="#E1E1E1" // Keep the original outline color
        strokeWidth={1}
      />
      <text
        x={cx}
        y={cy}
        dy={5}
        textAnchor="middle"
        fill={isSelected ? "white" : teamColor} // Invert text color when selected
        fontSize={textSize}
        fontWeight="bold"
      >
        {value}
      </text>
    </g>
  );
};

const TeamPageTrajectoryLadder = () => {
  const isSmallScreen = useMediaQuery("(max-width:750px)");
  const [selectedYear, setSelectedYear] = useState(null);

  const data = useTeamDataContext();
  const teamColors = useTeamColors();

  const teamLadderRanking = useMemo(
    () => data?.teamLadderRanking || [],
    [data]
  );
  const teamShortName = data?.teamBasicInfo?.team_name_short || "Team";

  const teamColor = useMemo(() => {
    const team = teamColors.teamColours.find(
      (color) => color.team_name_short === teamShortName
    );
    return team ? team.colour : "#000";
  }, [teamColors, teamShortName]);

  const handleDotClick = (year) => {
    setSelectedYear(year === selectedYear ? null : year);
  };

  if (!data || teamLadderRanking.length === 0) return null;

  return (
    <Box className="team-page-trajectory-ladder-container">
      <Box className="trajectory-chart-wrapper">
        <ResponsiveContainer width="100%" height={360}>
          <LineChart
            margin={{ top: 20, right: 20, left: 20, bottom: 20 }}
            data={teamLadderRanking}
          >
            <foreignObject x="0" y="0" width="100%" height="320">
              <div
                style={{
                  height: "100%",
                  width: "100%",
                  backgroundColor: "#f5f5f5",
                  borderRadius: "16px",
                }}
              ></div>
            </foreignObject>
            <CartesianGrid
              stroke="#f5f5f5"
              vertical={false}
              style={{ pointerEvents: "none" }}
            />
            <YAxis type="number" domain={[1, 17]} reversed hide={true} />
            <XAxis
              dataKey="year"
              type="number"
              domain={["auto", "auto"]}
              ticks={teamLadderRanking.map((d) => d.year)}
              allowDataOverflow={false}
              interval={0}
              label={{ value: "Year", position: "bottom", offset: 0, dy: 25 }}
              axisLine={{ stroke: "#E1E1E1", strokeWidth: 1 }}
              tickLine={false}
              tick={({ x, y, payload }) => {
                const displayYear = isSmallScreen
                  ? String(payload.value).slice(-2)
                  : String(payload.value);

                return (
                  <text
                    x={x}
                    y={y}
                    dy={40}
                    textAnchor="middle"
                    style={{
                      fontSize: "1.0rem",
                      fontWeight: 500,
                      fill: selectedYear === payload.value ? teamColor : "#000",
                    }}
                  >
                    {displayYear}
                  </text>
                );
              }}
            />

            <Tooltip
              content={<CustomTooltip />}
              filterNull={true}
              isAnimationActive={false}
            />
            <ReferenceLine
              y={8}
              stroke="rgba(0, 0, 0, 0.3)"
              strokeDasharray="3 3"
              label={{
                value: "Rank 8",
                position: "insideRight",
                fill: "#888",
                fontSize: 10,
              }}
            />
            <Line
              type="linear"
              dataKey="position"
              name={teamShortName}
              stroke={teamColor}
              strokeWidth={1.5}
              dot={(props) => (
                <CustomDot
                  {...props}
                  teamColor={teamColor}
                  isSelected={selectedYear === props.payload.year}
                  onClick={() => handleDotClick(props.payload.year)}
                  isSmallScreen={isSmallScreen}
                />
              )}
              activeDot={false}
            />
          </LineChart>
        </ResponsiveContainer>
      </Box>
    </Box>
  );
};

export default TeamPageTrajectoryLadder;
