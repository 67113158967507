import React from "react";
import {
  Box,
  Typography,
  IconButton,
  Paper,
  Divider,
  useMediaQuery,
} from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PersonIcon from "@mui/icons-material/Person";
import { useNavigate } from "react-router-dom";
import { usePostMatchDataContext } from "../../../hooks/PostMatchDataContext";
import "./../../MatchPageSharedComponents/MatchHeader.css";
import "./PostMatchHeader.css";
const getImagePath = (teamName) => {
  try {
    return require(`./../../../assets/team_logos/${teamName
      .toLowerCase()
      .replace(/\s/g, "-")}.svg`);
  } catch (error) {
    return require(`./../../../assets/team_logos/bye.png`);
  }
};

const PostMatchHeader = () => {
  const data = usePostMatchDataContext();
  const isSmallScreen = useMediaQuery("(max-width: 850px)");
  const navigate = useNavigate();

  if (!data || !data.matchBasicInfo) return null;

  const { matchBasicInfo } = data;
  const { team1, team2, datetime, season, round, location, referee } =
    matchBasicInfo;

  const handleBackClick = () => {
    navigate("/");
  };

  const handleTeamClick = (teamName) => {
    const teamLink = `/teams/${teamName.toLowerCase().replace(/\s/g, "-")}`;
    navigate(teamLink);
  };

  const formatRank = (position) => {
    if (position === 1) return "1st";
    if (position === 2) return "2nd";
    if (position === 3) return "3rd";
    return `${position}th`;
  };

  return (
    <Box className="match-header-container">
      <Box className="match-header-top">
        <Box className="match-header-back-section" onClick={handleBackClick}>
          <IconButton className="back-button-box match-header-back-button-box">
            <ArrowBackIosIcon className="back-button-icon" />
          </IconButton>
          <Typography className="match-header-back-text">Matches</Typography>
        </Box>
        <Typography variant="h6" className="match-header-round-info">
          Round {round}, {season}
        </Typography>
      </Box>
      <Divider className="match-header-divider" />
      <Box className="match-header-middle">
        <Box className="match-header-details">
          {/* Team 1 Details */}
          <Box 
            className="match-header-team-details"
            onClick={() => handleTeamClick(team1.name)} // Make the entire Box clickable
            style={{ cursor: "pointer" }} // Change cursor to pointer to indicate clickable
          >
            {isSmallScreen ? (
              <Box className="match-header-team-column">
                <img
                  src={getImagePath(team1.name)}
                  alt={team1.name}
                  className="match-header-team-logo"
                />
                <Typography className="match-header-team-name">
                  {team1.name}
                </Typography>
                <Typography className="match-header-team-rank">
                  {formatRank(team1.ladderPosition)}
                </Typography>
              </Box>
            ) : (
              <>
                <Box className="match-header-team">
                  <Typography
                    variant="h6"
                    className="match-header-team-name flex-end"
                  >
                    {team1.name}
                  </Typography>
                  <Typography
                    variant="body1"
                    className="match-header-team-rank flex-end left-rank"
                  >
                    {formatRank(team1.ladderPosition)}
                  </Typography>
                </Box>
                <img
                  src={getImagePath(team1.name)}
                  alt={team1.name}
                  className="match-header-team-logo flex-start"
                />
              </>
            )}
          </Box>

          {/* Match Details */}
          <Box className="match-header-match-details">
            <Typography className="match-header-match-time-or-score">
              {team1.score} - {team2.score}
            </Typography>
            <Typography
              variant="body1"
              className="match-header-ht-score-and-date"
            >
              {team1.scoreHT} HT {team2.scoreHT}
            </Typography>
          </Box>

          {/* Team 2 Details */}
          <Box 
            className="match-header-team-details"
            onClick={() => handleTeamClick(team2.name)} // Make the entire Box clickable
            style={{ cursor: "pointer" }} // Change cursor to pointer to indicate clickable
          >
            {isSmallScreen ? (
              <Box className="match-header-team-column">
                <img
                  src={getImagePath(team2.name)}
                  alt={team2.name}
                  className="match-header-team-logo"
                />
                <Typography variant="h6" className="match-header-team-name">
                  {team2.name}
                </Typography>
                <Typography variant="body1" className="match-header-team-rank">
                  {formatRank(team2.ladderPosition)}
                </Typography>
              </Box>
            ) : (
              <>
                <img
                  src={getImagePath(team2.name)}
                  alt={team2.name}
                  className="match-header-team-logo flex-start"
                />
                <Box className="match-header-team">
                  <Typography
                    variant="h6"
                    className="match-header-team-name flex-start"
                  >
                    {team2.name}
                  </Typography>
                  <Typography
                    variant="body1"
                    className="match-header-team-rank flex-start right-rank"
                  >
                    {formatRank(team2.ladderPosition)}
                  </Typography>
                </Box>
              </>
            )}
          </Box>
        </Box>
      </Box>
      <Divider className="match-header-divider" />
      <Box className="match-header-bottom">
        <Box className="match-header-match-info">
          <Paper className="match-header-tag">
            <CalendarTodayIcon className="match-header-tag-icon" />
            <Typography>
              {new Date(datetime)
                .toLocaleDateString("en-US", {
                  weekday: "long",
                  month: "long",
                  day: "numeric",
                })
                .replace(/,/g, "")}
            </Typography>
          </Paper>

          <Paper className="match-header-tag">
            <LocationOnIcon className="match-header-tag-icon" />{" "}
            <Typography>{location}</Typography>
          </Paper>
          {referee && referee !== "NaN" && (
            <Paper className="match-header-tag">
              <PersonIcon className="match-header-tag-icon" />{" "}
              <Typography>{referee}</Typography>
            </Paper>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default PostMatchHeader;
