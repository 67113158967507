import React from "react";
import { Box } from "@mui/material";
import { useTeamDataContext } from "../../../hooks/TeamDataContext";
import TeamStatsCard from "../TeamStatsCard/TeamStatsCard";
import "./TeamsPageStatsSection.css"; // Import the CSS file for styling

const TeamsPageStatsSection = () => {
  const data = useTeamDataContext();
  if (!data || !data.teamStatistics || !data.teamBasicInfo) return null;

  const { teamStatistics } = data;
  const { team_name_short } = data.teamBasicInfo;

  return (
    <Box className="teams-page-stats-section">
      {teamStatistics.map((stat) => (
        <TeamStatsCard
          key={stat.category}
          category={stat.category}
          teamName={team_name_short}
          teamValue={stat.teamValue}
          maxTeam={stat.maxTeam}
          maxTeamValue={stat.maxTeamValue}
          lowTeam={stat.lowTeam}
          lowTeamValue={stat.lowTeamValue}
        />
      ))}
    </Box>
  );
};

export default TeamsPageStatsSection;
