import React from "react";
import { Box, Skeleton } from "@mui/material";

const waveColor = 'rgba(64, 63, 63, 0.050)'; // A slightly darker wave color
const backgroundGradient = `linear-gradient(90deg, transparent, ${waveColor}, transparent, ${waveColor}, transparent)`; 
const animationDuration = '4.2s'; // Faster animation for a smoother feel

const MatchPageLoadingSkeleton = () => (
  <Box 
    className="match-page-container main-content-box"
    sx={{ display: 'flex', flexDirection: 'row', width: '100%', margin: 'var(--header-height) auto' }}
  >
    <Box 
      className="match-page-column1"
      sx={{ display: 'flex', flexDirection: 'column', flex: '0 0 65%', marginLeft: 'var(--page-container-margin-left)', marginRight: '15px' }}
    >
      <Skeleton 
        variant="rectangular" 
        width="100%" 
        height={200} 
        sx={{
          borderRadius: '16px',
          marginBottom: '15px',
          bgcolor: 'rgba(0, 0, 0, 0.05)', // Subtle background
          '&::after': {
            animationDuration: animationDuration, // Faster duration
            animationTimingFunction: 'ease-in-out', // Smoother transition
            background: backgroundGradient, // Custom gradient
          }
        }}
        animation="wave"
      />
      <Box 
        className="match-page-content"
        sx={{ backgroundColor: '#ffffff', padding: '8px 10px 10px 10px', borderRadius: '16px', boxShadow: 'none', border: 'var(--border-style)', marginBottom: '15px', marginTop: '20px' }}
      >
        <Skeleton 
          variant="rectangular" 
          width="100%" 
          height={50} 
          sx={{
            borderRadius: '16px',
            bgcolor: 'rgba(0, 0, 0, 0.05)',
            '&::after': {
              animationDuration: animationDuration,
              animationTimingFunction: 'ease-in-out',
              background: backgroundGradient,
            }
          }}
          animation="wave"
        />
        <Skeleton 
          variant="rectangular" 
          width="100%" 
          height={400} 
          sx={{
            borderRadius: '16px',
            marginTop: '20px',
            bgcolor: 'rgba(0, 0, 0, 0.05)',
            '&::after': {
              animationDuration: animationDuration,
              animationTimingFunction: 'ease-in-out',
              background: backgroundGradient,
            }
          }}
          animation="wave"
        />
      </Box>
    </Box>
    <Box 
      className="match-page-column2"
      sx={{ display: 'flex', flexDirection: 'column', flex: '0 0 18%', marginRight: 'var(--page-container-margin-right)' }}
    >
      <Skeleton 
        variant="rectangular" 
        width="100%" 
        height={200} 
        sx={{
          borderRadius: '16px',
          marginBottom: '20px',
          bgcolor: 'rgba(0, 0, 0, 0.05)',
          '&::after': {
            animationDuration: animationDuration,
            animationTimingFunction: 'ease-in-out',
            background: backgroundGradient,
          }
        }}
        animation="wave"
      />
      <Skeleton 
        variant="rectangular" 
        width="100%" 
        height={400} 
        sx={{
          borderRadius: '16px',
          bgcolor: 'rgba(0, 0, 0, 0.05)',
          '&::after': {
            animationDuration: animationDuration,
            animationTimingFunction: 'ease-in-out',
            background: backgroundGradient,
          }
        }}
        animation="wave"
      />
    </Box>
  </Box>
);

export default MatchPageLoadingSkeleton;
