import React, { useState } from 'react';
import { Box, Typography, useMediaQuery } from '@mui/material';
import CustomTabs from '../../SharedComponents/CustomTabs/CustomTabs';
import CustomDropdown from '../../SharedComponents/CustomDropdown/CustomDropdown';
import './PostMatchPlayerStats.css';
import { usePostMatchDataContext } from '../../../hooks/PostMatchDataContext';
import { COLUMNS } from '../../../constants/playerStatsColumns';

const TABS = [
  'Top Stats',
  'Scoring',
  'Running Attack',
  'Passing',
  'Defence',
  'Kicking',
  'Discipline',
];

const getImagePath = (imageName) => {
  try {
    return require(`./../../../assets/${imageName}.png`);
  } catch (error) {
    return require(`./../../../assets/lineupPlayer.png`);
  }
};

const PostMatchPlayerStats = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [sortColumn, setSortColumn] = useState(COLUMNS[TABS[0]][0].field);
  const [sortOrder, setSortOrder] = useState('desc');

  const isSmallScreen = useMediaQuery('(max-width: 850px)');
  
  const data = usePostMatchDataContext();
  
  if (!data || !data.matchStats.playersStats || !data.matchStats.playersStats.players || data.matchStats.playersStats.players.length === 0) {
    return <div>Loading...</div>;
  }
  
  const players = data.matchStats.playersStats.players;

  const columns = COLUMNS[TABS[activeTab]];
  const gridTemplateColumns = `140px repeat(${columns.length}, minmax(70px, 1fr))`;

  const handleSort = (column) => {
    if (sortColumn === column.field) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(column.field);
      setSortOrder('desc');
    }
  };

  const sortedPlayers = players.sort(
    (a, b) => {
      const valueA = parseFloat(a[sortColumn]) || 0;
      const valueB = parseFloat(b[sortColumn]) || 0;
      return sortOrder === 'asc' ? valueA - valueB : valueB - valueA;
    }
  );

  return (
    <Box className="postmatch-playerstats-container">
      <Typography className="section-title">
        Player Statistics
      </Typography>
      {isSmallScreen ? (
        <CustomDropdown tabs={TABS} activeTab={activeTab} onTabClick={(index) => { setActiveTab(index); setSortColumn(COLUMNS[TABS[index]][0].field); setSortOrder('desc'); }} />
      ) : (
        <CustomTabs tabs={TABS} activeTab={activeTab} onTabClick={(index) => { setActiveTab(index); setSortColumn(COLUMNS[TABS[index]][0].field); setSortOrder('desc'); }} />
      )}
      <Box className="postmatch-playerstats-table" style={{ gridTemplateColumns }}>
        <Box className="postmatch-playerstats-row postmatch-playerstats-header">
          <Box className="postmatch-playerstats-cell player-info">Player</Box>
          {columns.map((column, index) => (
            <Box key={index} className="postmatch-playerstats-cell" onClick={() => handleSort(column)}>
              <Typography className="postmatch-playerstats-header-title">
                {column.label} {sortColumn === column.field ? (sortOrder === 'asc' ? '↑' : '↓') : ''}
              </Typography>
            </Box>
          ))}
        </Box>
        {sortedPlayers.map((player, index) => (
          <Box key={index} className="postmatch-playerstats-row">
            <Box className="postmatch-playerstats-cell player-info">
              <img
                src={getImagePath(`${player.first_name}${player.last_name}`.replace(/\s/g, '').toLowerCase())}
                alt={`${player.first_name} ${player.last_name}`}
                className="postmatch-playerstats-player-image"
              />
              <Box className="postmatch-playerstats-player-name-box">
                <Typography className="postmatch-playerstats-player-name">
                  {player.first_name} {player.last_name}
                </Typography>
              </Box>
            </Box>
            {columns.map((column, colIndex) => (
              <Box key={colIndex} className="postmatch-playerstats-cell">
                <Typography>{player[column.field] !== null ? player[column.field] : '-'}</Typography>
              </Box>
            ))}
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default PostMatchPlayerStats;
